import React, { useState, useRef } from "react";
import { useDropzone } from "react-dropzone";
import Image from "../../Assets/images/image.png";
import Txt from "../../Assets/images/txt.png";
import Pdf from "../../Assets/images/pdf.png";
import AnyDesk from "../../Assets/images/anyDesk.png";
import Upload from "../../Assets/svg/upload.svg";
import { FileRestControllerApi } from "../../openapi";
import { apiConfig } from "../ConfigurationApi/Configuration";
import DeleteModal from "../Delete-Modal";
import toast from "react-hot-toast";
import { FileUploadProps } from "../../interfaces/pages/variedInterfaces";

interface File {
  name: string;
  progress: number;
  type: string;
  size: string;
  interval: NodeJS.Timeout | null;
  file: any;
  id: number;
}

const FileUpload: React.FC<FileUploadProps> = ({ formik }) => {
  const [files, setFiles] = useState<File[]>([]);
  const dropzoneRef = useRef<HTMLDivElement>(null);
  const [uploadProgress, setUploadProgress] = useState<{
    [id: number]: number;
  }>({});
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [index, setIndex] = useState<number | null>(null);

  const handleRemove = () => {
    if (index === null) return;
    const fileToRemove = files[index];
    const idToRemove = formik.values.files?.add[index]

    const indexOfId = formik.values.files.add.findIndex((item: any) => item === idToRemove);

    if (indexOfId === -1) return;
    // Remove the id from the "add" array
    const updatedAdd = [...formik.values.files.add];
    updatedAdd.splice(indexOfId, 1);

    // Add the id to the "delete" array
    const updatedDelete = formik.values.files?.delete ? [...formik.values.files?.delete, idToRemove] : [idToRemove];

    formik.setValues({
      ...formik.values,
      files: {
        add: updatedAdd?.length > 0 ? updatedAdd : null,
        delete: updatedDelete?.length > 0 ? updatedDelete : null,
      },
    });

    clearInterval(fileToRemove?.interval!);
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setIsModalOpen(false);
    setIndex(null);
  };

  const onDrop = async (acceptedFiles: any) => {
    const uploadedFiles = acceptedFiles.map((file: any, index: number) => {
      const fileSizeInBytes = file.size;
      const fileSizeInKB = fileSizeInBytes / 1024;
      const fileSizeInMB = fileSizeInKB / 1024;

      const size = fileSizeInMB < 1 ? `${fileSizeInKB.toFixed(2)} KB` : `${fileSizeInMB.toFixed(2)} MB`;

      return {
        name: file.name,
        progress: 0,
        type: file.type,
        size,
        file,
        id: Date.now() + index,
      };
    });
    setFiles([...files, ...uploadedFiles]);

    await uploadFiles(uploadedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
  });

  const uploadFiles = async (filesToUpload: File[]) => {
    const responses = [] as any;
    const uploadPromises = filesToUpload.map(async (fileObj: File) => {
      const { file, id } = fileObj;

      // Reset progress for the file
      setUploadProgress((prevProgress: any) => ({
        ...prevProgress,
        [id]: 0,
      }));

      const formData = new FormData();
      formData.append("file", file);
      const api = new FileRestControllerApi(apiConfig());
      let isRejected = false; // Flag to track if the promise is rejected
      try {
        await new Promise((resolve: any) => {
          let progress = 0;
          const interval = setInterval(() => {
            progress += 1;
            const cappedProgress = Math.min(progress, 100);
            setUploadProgress((prevProgress: any) => ({
              ...prevProgress,
              [id]: cappedProgress,
            }));

            if (cappedProgress === 100) {
              clearInterval(interval);
              resolve(); // Resolve the promise when progress reaches 100
            }

            if (isRejected) {
              clearInterval(interval); // Stop the interval if the promise is rejected
            }
          }, 10); // Increase the progress every 10 milliseconds for a smoother transition

          // Simulate API call completion
          api.uploadFile(file).then((response) => {
            clearInterval(interval);
            resolve(); // Resolve the promise when API call is completed
            responses.push(response.data);
          });
        });
      } catch (error: any) {
        isRejected = true; // Set the flag to true if the promise is rejected
        if (error.response.data?.exception) {
          toast.custom(
            (t) => (
              <div
                className={`${
                  t.visible ? "animate-enter" : "animate-leave"
                } max-w-md w-full bg-danger shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
              >
                <div className="flex-1 w-0 p-4">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 pt-0.5">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="50"
                        height="50"
                        viewBox="0 0 48 48"
                        className="dark:brightness-[4]"
                      >
                        <path
                          fill="#F78F8F"
                          d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
                        ></path>
                        <path
                          fill="#fff"
                          d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z"
                        ></path>
                        <path
                          fill="#fff"
                          d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z"
                        ></path>
                      </svg>
                    </div>
                    <div className="ml-3 flex-1 text-white">
                      <p>{error.response.data?.exception}</p>
                    </div>
                  </div>
                </div>
              </div>
            ),
            {
              duration: 500,
            }
          );
        }
      } finally {
        setUploadProgress((prevProgress: any) => ({
          ...prevProgress,
          [id]: isRejected ? 0 : 100, // Set progress to 0 if rejected, otherwise 100
        }));
      }
    });

    try {
      await Promise.all(uploadPromises);

      // All files uploaded successfully, you can perform additional actions here if needed.
      const filesId = responses?.map((file: any) => file.id);

      if (formik.values.files?.add?.length > 0) {
        formik.setValues((prevValues: any) => ({
          ...prevValues,
          files: {
            add: [...prevValues.files.add, ...filesId],
          },
        }));
      } else {
        formik.setValues((prevValues: any) => ({
          ...prevValues,
          files: {
            add: filesId,
          },
        }));
      }
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const getIconForFileType = (type: string) => {
    switch (type) {
      case "application/pdf":
        return <img src={Pdf} alt="image" />;
      case "text/plain":
        return <img src={Txt} alt="image" />;
      case "image/png":
      case "image/jpg":
      case "image/jpeg":
      case "image/webp":
      case "image/svg+xml":
        return <img src={Image} alt="image" />;
      case "application/x-msdownload":
        return <img src={AnyDesk} alt="image" />;
      default:
        return "📄";
    }
  };

  const getGradientColor = (progress: number) => {
    if (progress <= 99) {
      return "linear-gradient(to right, #2C69D1, #0ABCF9)";
    } else {
      return "#E64D48";
    }
  };

  const filteredFiles = files.filter((file) => file.name.toLowerCase().includes(searchTerm.toLowerCase()));

  const openModal = (index: number) => {
    setIsModalOpen(true);
    setIndex(index);
  };

  return (
    <div className="flex flex-wrap items-start gap-8 xl:flex-nowrap ">
      <div
        {...getRootProps()}
        className={`cursor-pointer outline-none flex items-center justify-center flex-col gap-6 border-2 border-primary rounded-lg bg-[#f7f7ff]  h-[385px] border-dashed dark:bg-primary-dark ${
          files?.length > 0 ? "w-full xl:w-1/2" : "w-full"
        }`}
        ref={dropzoneRef}
      >
        <input {...getInputProps()}  id="files" />
        <div className="flex items-center flex-col gap-3">
          <img src={Upload} alt="upload" width={200} />
          <h6 className="font-bold">Drop or Select file</h6>
          <p className="text-placeholder">
            Drop files here or click <span className="text-[#4B49AC]">browse</span> thorough your machine
          </p>
        </div>
      </div>
      {files?.length > 0 && (
        <div className="w-full  xl:w-1/2">
          <div className="mb-2">
            <input
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              type="search"
              placeholder="Search by name"
              className={`py-2 px-4 border border-[#C3C1DF] rounded-lg text-base text-[#7B7B7B] font-light w-full  outline-none  bg-[#FAFAFE]`}
            />
          </div>
          <div className="top-100 z-40 w-full  rounded max-h-[335px]  h-[335px] overflow-y-auto">
            <div className="flex flex-col w-full ">
              {filteredFiles.map((fileObj: any, index) => (
                <div className="cursor-pointer w-full bg-white rounded-t hover:bg-[#EDEDED]">
                  <div className="flex  items-center pb-3 mt-3 border-b border-[#E4E4E4] ">
                    <div className="text-xl">{getIconForFileType(fileObj.type)}</div>
                    <div className="w-full">
                      <div className="flex  items-center justify-between px-3">
                        <div className="flex justify-between w-full">
                          <p className="w-1/2"> {fileObj.name}</p>
                          <p className="w-1/2"> {fileObj.size}</p>
                        </div>

                        <button type="button" onClick={() => openModal(index)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width="20"
                            height="20"
                            viewBox="0 0 48 48"
                            className="dark:brightness-[4]"
                          >
                            <path d="M 38.982422 6.9707031 A 2.0002 2.0002 0 0 0 37.585938 7.5859375 L 24 21.171875 L 10.414062 7.5859375 A 2.0002 2.0002 0 0 0 8.9785156 6.9804688 A 2.0002 2.0002 0 0 0 7.5859375 10.414062 L 21.171875 24 L 7.5859375 37.585938 A 2.0002 2.0002 0 1 0 10.414062 40.414062 L 24 26.828125 L 37.585938 40.414062 A 2.0002 2.0002 0 1 0 40.414062 37.585938 L 26.828125 24 L 40.414062 10.414062 A 2.0002 2.0002 0 0 0 38.982422 6.9707031 z"></path>
                          </svg>
                        </button>
                        <DeleteModal
                          isModalOpen={isModalOpen}
                          setIsModalOpen={setIsModalOpen}
                          onDeleteItem={handleRemove}
                        />
                      </div>
                      {uploadProgress[fileObj.id] > 0 ? (
                        <div className="px-3 mt-3">
                          <div className="w-full bg-[#E5E7EB] rounded-full h-2.5 dark:bg-gray-700">
                            <div
                              className={`h-2.5 rounded-full ${uploadProgress[fileObj.id] === 100 && 'file-upload-success' }`}
                              style={{
                                width: `${uploadProgress[fileObj.id] || 0}%`,
                                background: getGradientColor(uploadProgress[fileObj.id]),
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="px-3 mt-3">
                          <div className="w-full bg-[#E5E7EB] rounded-full h-2.5 dark:bg-gray-700">
                            <div
                              className="h-2.5 rounded-full"
                              // style={{
                              //   width: `${uploadProgress[fileObj.id] || 0}%`,
                              //   background: getGradientColor(
                              //     uploadProgress[fileObj.id]
                              //   ),
                              // }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
