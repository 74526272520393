// React
import React, { useEffect, useRef, useState } from "react";
// Thrid party
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// Components
import Alerts from "../Components/Alerts";
// There
import { useTheme } from "../App";
// Data
import { menuItem, navbarData } from "../data/navbarData";
// Images
import Avatar from "../Assets/svg/avatar.svg";
import BurguerMenu from "../Assets/svg/burguer-menu.svg";
import Search from "../Assets/svg/search.svg";
import DarkLogo from "../Assets/images/logo_dark.png";
import LightLogo from "../Assets/images/logo_light.png";
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
// Types
import { MenuItemProps } from "../interfaces/pages/variedInterfaces";
import arrow from "../Assets/images/arrow.png";

interface Data {
  path: string;
  icon: string;
  active: string;
  name: string;
}

const MenuItem = ({ path, icon, active, name }: Data) => {
  const { pathname } = useLocation();
  const { id } = useParams();

  const isActive =
    pathname === path ||
    ((pathname === "/add-note" || pathname === `/update-note/${id}`) && path === "/notes") ||
    ((pathname === "/add-mna" ||
      pathname === `/add-mna/${id}` ||
      pathname === `/update-mna/${id}` ||
      pathname === `/mna/${id}`) &&
      path === "/mnas") ||
    ((pathname === "/add-company" || pathname === `/update-company/${id}`) && path === "/company") ||
    ((pathname === "/add-fundamental" ||
      pathname === `/update-fundamental/${id}` ||
      pathname === `/fundamental/${id}`) &&
      path === "/fundamental") ||
    ((pathname === "/old-add-mna" ||
      pathname === `/old-add-mna/${id}` ||
      pathname === `/old-update-mna/${id}` ||
      pathname === `/old-mna/${id}`) &&
      path === "/old-mnas");

  const getBorderColor = () => {
    return isActive ? "border-[#F3797E]" : "border-transparent";
  };

  const getTextClass = () => {
    return isActive ? "text-secondary" : "";
  };

  return (
    <li className={`border-b-[3px] py-[24px] ${getBorderColor()}`}>
      <Link to={path}>
        <div className="flex gap-[6px] items-center p-[10px]">
          <div>
            <img src={isActive ? active : icon} alt="active" />
          </div>
          <div>
            <p className={`text-[#393C51}] text-base font-normal ${getTextClass()}`}>{name}</p>
          </div>
        </div>
      </Link>
    </li>
  );
};

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState<string | undefined>("");
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const theme = useTheme();
  const username = localStorage.getItem("username");
  useEffect(() => {
    const getUsernameAsync = async () => {
      const data: string | null = localStorage.getItem("FirstLastName");
      setUserName(data as string);
    };
    getUsernameAsync();
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeMenu);
    return () => {
      document.removeEventListener("click", closeMenu);
    };
  }, []);

  return (
    <header className="sticky top-0 z-10 bg-[#FFFFFF] dark:bg-black">
      <div
        className="flex flex-wrap items-center justify-between mx-auto px-[30px]"
        style={{ boxShadow: "0px 8px 28px 0px rgba(72, 89, 102, 0.10)" }}
      >
        <div className="flex items-center gap-8">
          {/* App Logo */}
          <div className="my-2  px-[52px] border-e	dark:border-white ">
            <img src={theme.theme === "light" ? LightLogo : DarkLogo} alt="Logo" />
          </div>
          {/* Sidebar */}
          <div ref={menuRef}>
            <button type="button" className="" onClick={toggleMenu}>
              <img src={BurguerMenu} alt="butguer menu" className="h-5 dark:invert" />{" "}
            </button>
            <div
              className={`${
                isOpen ? "translate-x-0" : "-translate-x-full"
              } fixed top-[79px] py-4 flex flex-col justify-between left-0 w-[284px] h-full transition-all duration-500 transform bg-[rgb(14,111,166)] shadow-lg peer-checked:translate-x-0 
              dark:bg-[#02324D]`}
            >
              <div>
                <div className="space-y-1 pb-3 pt-2">
                  {navbarData.map((item, index) => (
                    <Link
                      key={`navbar-${item.title}-${index}`}
                      to={`/${item.pathname}`}
                      onClick={() => setIsOpen(false)}
                      className={`${
                        pathname === `/${item.pathname}`
                          ? "text-[#F2F1FD] bg-[rgb(9,89,142)] dark:text-[#F2F1FD] dark:bg-[rgb(9,89,142)]"
                          : "text-[#F2F1FD]"
                      }   block px-8 py-2 2xl:py-4 text-base font-medium hover:bg-[#09598E] transition-all duration-500 ease-out`}
                    >
                      <div className="flex gap-[6px] items-center">
                        <div>
                          <img src={item.lightIcon} alt="active" className="" />
                        </div>
                        <div>
                          <p className={`text-[#393C51}] text-base font-normal`}>{item.title}</p>
                        </div>
                        <div>
                          <img className="w-4 rotate-90 absolute right-2 mt-[-8px]" src={arrow} alt="" />
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="relative xl:block 2xl:hidden 3xl:hidden 4xl:block">
              <div className="relative  rounded-lg lg:w-[472px] flex justify-between items-center py-3 px-4 bg-b-secondary-light dark:bg-b-secondary-dark md:max-w-full">
                <input
                  type="text"
                  placeholder="Search here......"
                  className="text-sm font-normal w-full bg-transparent mr-3  outline-none placeholder:text-black dark:placeholder:text-white text-black dark:text-white"
                />
                <img
                  src={Search}
                  alt="search"
                  className="absolute top-3 lg:left-[93%] left-[85%] brightness-0	 dark:brightness-[100]"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center md:order-2  w-auto justify-end">
          <div className="mr-3 cursor-pointer" onClick={theme.toggleTheme}>
            {theme.theme === "dark" ? (
              <FontAwesomeIcon icon={faToggleOn} className="text-[#808080] text-[20px]" />
            ) : (
              <FontAwesomeIcon icon={faToggleOff} className="text-[20px]" />
            )}
          </div>
          <div className="mr-6">
            <Alerts className="w-[30px] h-[30px]" isIcon />
          </div>
          <div className="2xl:block">
            <div className="flex items-center space-x-2 md:space-x-4 lg:space-x-6">
            <div className="rounded-full p-2 border border-[#E6E6F9] bg-[#1eacff] dark:bg-primary-dark md:p-3  w-11 h-11 flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  className="brightness-200"
                >
                  <path
                    d="M17.8028 12.1358C17.7332 12.0647 17.6501 12.0081 17.5584 11.9694C17.4667 11.9307 17.3682 11.9107 17.2687 11.9104C17.1692 11.9101 17.0706 11.9297 16.9787 11.9679C16.8868 12.0062 16.8034 12.0623 16.7334 12.1331C16.6635 12.2039 16.6083 12.2878 16.5711 12.3802C16.5339 12.4725 16.5155 12.5713 16.5168 12.6708C16.5182 12.7703 16.5394 12.8686 16.5791 12.9598C16.6189 13.0511 16.6764 13.1335 16.7483 13.2023C17.3846 13.8284 17.8894 14.5754 18.2332 15.3992C18.5769 16.2231 18.7526 17.1074 18.75 18.0001C18.75 18.9173 16.1205 20.2501 12 20.2501C7.87954 20.2501 5.25003 18.9166 5.25003 17.9986C5.24748 17.1119 5.42083 16.2336 5.76006 15.4145C6.09929 14.5953 6.59765 13.8516 7.22629 13.2263C7.29792 13.1571 7.35505 13.0744 7.39436 12.9829C7.43367 12.8914 7.45436 12.7929 7.45522 12.6934C7.45609 12.5938 7.43711 12.495 7.3994 12.4028C7.36169 12.3107 7.306 12.2269 7.23558 12.1565C7.16516 12.0861 7.08142 12.0304 6.98925 11.9927C6.89708 11.955 6.79832 11.936 6.69873 11.9369C6.59915 11.9377 6.50073 11.9584 6.40923 11.9977C6.31773 12.037 6.23497 12.0942 6.16578 12.1658C5.3973 12.9302 4.78809 13.8395 4.37342 14.8409C3.95876 15.8424 3.74687 16.9162 3.75003 18.0001C3.75003 20.4361 8.00029 21.7501 12 21.7501C15.9998 21.7501 20.25 20.4361 20.25 18.0001C20.2534 16.9088 20.0386 15.828 19.6183 14.8209C19.1981 13.8139 18.5808 12.901 17.8028 12.1358Z"
                    fill="#7978E9"
                  />
                  <path
                    d="M12 12.75C13.0384 12.75 14.0534 12.4421 14.9167 11.8652C15.7801 11.2883 16.453 10.4684 16.8504 9.50909C17.2477 8.54978 17.3517 7.49418 17.1491 6.47578C16.9466 5.45738 16.4465 4.52192 15.7123 3.78769C14.9781 3.05347 14.0426 2.55345 13.0242 2.35088C12.0058 2.14831 10.9502 2.25227 9.99091 2.64963C9.0316 3.04699 8.21166 3.7199 7.63478 4.58326C7.05791 5.44662 6.75 6.46165 6.75 7.5C6.75159 8.8919 7.30522 10.2263 8.28944 11.2106C9.27367 12.1948 10.6081 12.7484 12 12.75ZM12 3.75C12.7417 3.75 13.4667 3.96994 14.0834 4.38199C14.7001 4.79405 15.1807 5.37972 15.4645 6.06494C15.7484 6.75016 15.8226 7.50416 15.6779 8.23159C15.5333 8.95902 15.1761 9.62721 14.6517 10.1517C14.1272 10.6761 13.459 11.0333 12.7316 11.1779C12.0042 11.3226 11.2502 11.2484 10.5649 10.9646C9.87971 10.6807 9.29404 10.2001 8.88199 9.58339C8.46993 8.96671 8.25 8.24168 8.25 7.5C8.25119 6.50581 8.64666 5.55267 9.34966 4.84967C10.0527 4.14666 11.0058 3.75119 12 3.75Z"
                    fill="#7978E9"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="ml-3">
            <p className="text-sm md:text-base lg:text-[20px] text-black dark:text-white font-semibold ">
              {(userName?.trim() && userName) || username || ""}
            </p>
            <button
              className="text-black dark:text-white font-normal text-xs md:text-sm lg:text-base"
              onClick={() => handleLogout()}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
