// React
import React, { useCallback, useState } from "react";
// Third party
import { useNavigate } from "react-router-dom";
// Components
import { Columns } from "../Components/Columns/Columns";
import Breadcrumbs from "../Components/Breadcrumbs/Breadcrumbs";
import Table from "../Components/Tables/Mna-Table";
// OpenAPI
import { apiConfig } from "../Components/ConfigurationApi/Configuration";
import { expiredTokenValidation } from "../api/expiredTokenValidation";
import { FundamentalRestControllerApi } from "../openapi";
import { getApiData } from "../Controller/getApiDataConfiguration";
// Helpers
import { getSortArray } from "../helpers/getSortArray";
// Types
import { Sort } from "../interfaces/pages/variedInterfaces";
// Images
import fundamentalIcon from "../Assets/svg/blue-fundamental.svg";

const FundamentalList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const fetchFundamentalList = useCallback(async (pageIndex: number, pageSize: number, sortBy: Sort[]) => {
    setLoading(true);
    try {
      const api = new FundamentalRestControllerApi(apiConfig());

      const response = await api.getFundamentals(pageIndex, pageSize, getSortArray(sortBy));
      const content = new getApiData();
      const data = await content.getSectorRestControllerApi(response);
      return { ...response.data, data };
    } catch (error) {
      expiredTokenValidation(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const columnsInstance = new Columns();

  return (
    <React.Fragment>
      <div className="p-[30px]">
        <Breadcrumbs
          breadcrumbs={["Fundamental"]}
          icon={fundamentalIcon}
          addItem={() => navigate("/add-fundamental")}
        />
        <div className="mt-[31px]">
          <Table
            columns={columnsInstance.FundamentalList(navigate)}
            fetchData={fetchFundamentalList}
            loading={loading}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default FundamentalList;
