import { Configuration } from "../../openapi";

export const apiConfig = () => {
  interface Headers {
    Authorization: string;
  }

  const configuration = new Configuration({
    basePath: process.env.REACT_APP_API_URL,
  });

  const headers: Headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  configuration.baseOptions = {
    headers: headers,
  };

  return configuration
}