import React, { useEffect, useRef, useState } from "react";
import Table from "../Components/Tables/Table";
import Breadcrumbs from "../Components/Breadcrumbs/Breadcrumbs";
import dashboardIcon from "../Assets/svg/blue-dashboard.svg";
import { DashboardRestControllerApi } from "../openapi";
import Loader from "../Components/Loader";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { apiConfig } from "../Components/ConfigurationApi/Configuration";
import { Mna, Note } from "../interfaces/pages/variedInterfaces";
import { Columns } from "../Components/Columns/Columns";
import { AxiosError } from "axios";
import { expiredTokenValidation } from "../api/expiredTokenValidation";

const Dashboard: React.FC = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState("");
  const trigger = useRef<any>();
  const dropdown = useRef<any>();
  const navigate = useNavigate();
  const config = apiConfig();
  useEffect(() => {
    fetchDashboardList();
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = ({ target }: MouseEvent) => {
    if (dropdown.current && !dropdown.current.contains(target as Node)) {
      setDropdownOpen("");
    }
  };

  const fetchDashboardList = async () => {
    setLoading(true);
    try {
      const api = new DashboardRestControllerApi(config);
      const response = await api.getDashboard({ headers: config.baseOptions.headers });
      setData(response.data);
    } catch (error) {
      expiredTokenValidation(error);
    }
    setLoading(false);
  };

  const columnsInstance = new Columns();

  return (
    <React.Fragment>
      <div className={`p-[30px] ${loading && "opacity-20"}`}>
        <Breadcrumbs breadcrumbs={["Dashboard"]} icon={dashboardIcon} />
        <div className="flex flex-col md:flex-row gap-[30px] mt-[29px]">
          {/* List Group 1 */}
          <div className=" md:w-1/2 flex flex-col">
            <div className="-m-1.5 overflow-x-auto">
              <div className="p-1.5 min-w-full inline-block align-middle">
                <div className=" border border-[#DCDBEE] rounded-lg overflow-hidden">
                  <table className="min-w-full dark:bg-table-dark bg-table-light">
                    <caption className="text-left text-[18px] font-semibold bg-primary-default  dark:bg-table-dark text-[#FFF] py-[19px] px-[29px] mb-5">
                      Top Notes
                    </caption>
                    <thead className="bg-table-sub-light dark:bg-black dark:text-white">
                      <tr>
                        <th scope="col" className="px-[29px] py-3 text-start text-primary font-semibold text-lg w-1/5">
                          Title
                        </th>
                        <th scope="col" className="px-[29px] py-3 text-start text-primary font-semibold text-lg w-1/5">
                          Date
                        </th>
                        <th scope="col" className="px-[29px] py-3 text-start text-primary font-semibold text-lg w-2/5">
                          Content
                        </th>
                        <th scope="col" className="px-[29px] py-3 text-start text-primary font-semibold text-lg w-1/5">
                          MNAs
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-[#DCDBEE]">
                      {data.notes?.map((note: Note, index: number) => (
                        <tr key={index}>
                          <td className="whitespace-nowrap px-[30px] py-[27px] text-secondary dark:text-white font-bold text-base truncate">
                            {note.title}
                          </td>
                          <td className="whitespace-nowrap px-[30px] py-[27px]  text-[#393C51]  dark:text-white  font-normal text-base">
                            {moment(note.dateCreated).format("MM/DD/YYYY hh:mm A")}
                          </td>
                          <td
                            scope="row"
                            className="px-[30px] py-[27px]  text-[#393C51]  dark:text-white  font-normal text-base truncate"
                            dangerouslySetInnerHTML={{
                              __html: note.content || "",
                            }}
                          ></td>
                          <td className="whitespace-nowrap px-[30px] py-[27px] text-secondary font-bold text-base ">
                            {note.mnas?.map((mna, index: number) => (
                              <button
                                className="cursor-pointer underline"
                                key={index}
                                onClick={() => navigate(`/mna/${mna?.id}`)}
                              >
                                {mna.name}
                              </button>
                            ))}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* List Group 2 */}
          <div className=" md:w-1/2 flex flex-col">
            <div className="-m-1.5 overflow-x-auto">
              <div className="p-1.5 min-w-full inline-block align-middle">
                <div className=" border border-[#DCDBEE] rounded-lg overflow-hidden">
                  <table className="min-w-full dark:bg-table-dark bg-table-light">
                    <caption className="text-left text-[18px] font-semibold bg-primary-default dark:bg-table-dark  text-[#FFF] py-[19px] px-[29px] mb-5">
                      Mna Quick Links
                    </caption>
                    <thead className="bg-table-sub-light dark:bg-black dark:text-white">
                      <tr>
                        <th scope="col" className="px-[29px] py-3 text-start text-primary font-semibold text-lg">
                          Mna Name
                        </th>
                        <th scope="col" className="px-[29px] py-3 text-start text-primary font-semibold text-lg">
                          Category
                        </th>
                        <th scope="col" className="px-[29px] py-3 text-start text-primary font-semibold text-lg">
                          Date Announced
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-[#DCDBEE]">
                      {data.mnas?.map((mna: Mna, index: number) => (
                        <tr key={index}>
                          <td
                            className="whitespace-nowrap px-[30px] py-[27px] text-secondary dark:text-white font-bold text-base cursor-pointer"
                            onClick={() => navigate(`/mna/${mna?.id}`)}
                          >
                            {mna.name}
                          </td>
                          <td className="whitespace-nowrap px-[30px] py-[27px]  text-[#393C51]  dark:text-white  font-normal text-base">
                            {mna.categoryName}
                          </td>
                          <td className="whitespace-nowrap px-[30px] py-[27px]  text-[#393C51]  dark:text-white  font-normal text-base">
                            {moment(mna.dateAnnounced).format("MM/DD/YYYY hh:mm A")}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div>
            <Table
              columns={columnsInstance.Dashboard(setDropdownOpen, dropdownOpen, trigger, dropdown)}
              caption={"Recent Changes"}
            />
          </div>
        </div>
      </div>
      {loading && <Loader loading={loading} />}
    </React.Fragment>
  );
};

export default Dashboard;
