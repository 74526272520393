// React
import React, { useCallback } from "react";
// Components
import { Columns } from "../Components/Columns/Columns";
import Breadcrumbs from "../Components/Breadcrumbs/Breadcrumbs";
import Table from "../Components/Tables/Mna-Table";
// OpenAPI
import { AlertRestControllerApi } from "../openapi";
import { apiConfig } from "../Components/ConfigurationApi/Configuration";
import { expiredTokenValidation } from "../api/expiredTokenValidation";
// Helpers
import { getSortArray } from "../helpers/getSortArray";
// Types
import { Sort } from "../interfaces/pages/variedInterfaces";
// Images
import alertIcon from "../Assets/svg/blue-alert.svg";

const AlertList = () => {
  const fetchAlertList = useCallback(async (pageIndex: number, pageSize: number, sortBy: Sort[]) => {
    try {
      const api = new AlertRestControllerApi(apiConfig());
      const response = await api.getAlerts(pageIndex, pageSize, getSortArray(sortBy));
      return response.data;
    } catch (error) {
      expiredTokenValidation(error);
    }
  }, []);

  const columnsInstance = new Columns();

  return (
    <React.Fragment>
      <div className="p-[30px]">
        <Breadcrumbs breadcrumbs={["Alerts"]} icon={alertIcon} />
        <div className="mt-[31px]">
          <Table columns={columnsInstance.AlertList()} fetchData={fetchAlertList} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AlertList;
