import { Steps } from "../Components/Mna-Form";

type Pages = {
  [key in Steps]: { propertyNames: string[] };
};

export function structureFormData(formData: any, activeStep: Steps) {
  const pages: Pages = {
    "0": {
      propertyNames: ["name", "dateAnnounced", "categoryId", "subCategoryId", "description", "tags"],
    },
    "1": {
      propertyNames: [
        "target",
        "tickerTarget",
        "sectorTarget",
        "incorpTarget",
        "hqTarget",
        "geoExposureTarget",
        "breakFeeTarget",
        "financialAdvisorForTarget",
        "legalCounselForTarget",
        "acquirer",
        "tickerAcquirer",
        "sectorAcquirer",
        "incorpAcquirer",
        "hqAcquirer",
        "geoExposureAcquirer",
        "breakFeeAcquirer",
        "financialAdvisorForAcquirer",
        "legalCounselForAcquirer",
      ],
    },
    "2": {
      propertyNames: ["whatMatters"],
    },
    "3": {
      propertyNames: [
        "terms",
        "size",
        "premium",
        "closingLanguage",
        "financing",
        "appraisalRights",
        "specificPerformance",
        "brokerClosingEst",
        "requiredToLitigate",
        "outDate",
      ],
    },
    "4": {
      propertyNames: ["features"],
    },
    "5": {
      propertyNames: ["links"],
    },
    "6": {
      propertyNames: ["conditions"],
    },
    // "7": {
    //   propertyNames: [
    //     "title",
    //     "mnaId",
    //     "sentiment",
    //     "noteSourceTypeId",
    //     "sourceDetail",
    //     "datePublished",
    //     "author1",
    //     "author2",
    //     "author3",
    //     "tags",
    //   ],
    // },
  };

  const activeStepId = activeStep as Steps; // Convertir a string para coincidir con las claves de las páginas
  const activePage = pages[activeStepId];

  if (!activePage) {
    throw new Error("Paso activo no válido.");
  }

  const pageData: any = {};

  activePage.propertyNames.forEach((propertyName: any) => {
    pageData[propertyName] = formData[propertyName];
  });

  return {
    data: pageData,
  };
}
