import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTable, usePagination, useSortBy, useGlobalFilter, Column } from "react-table";
import Arrow from "../../Assets/svg/blue-arrow.svg";
import Loader from "../Loader";
import { Cell, ColumnTable, HeaderGroup, RowTable } from "../../interfaces/pages/variedInterfaces";
import { expiredTokenValidation } from "../../api/expiredTokenValidation";

interface TableProps {
  columns: Column[];
  caption?: string;
  search?: any;
  loading?: boolean;
  additionalDataKey?: string; // Key for the additional data in each row
  openModal?: ((value: any) => void) | undefined;

  fetchData: (pageIndex: number, pageSize: number, sortBy: any, globalFilter: string) => Promise<any>;
}

const Table: React.FC<TableProps> = ({
  columns,
  caption,
  fetchData,
  search,
  loading,
  additionalDataKey,
  openModal,
}) => {
  const { pathname } = useLocation();
  const [data, setData] = useState<[]>([]);
  const [inputPage, setInputPage] = useState<any>("");
  const [controlledPageCount, setControlledPageCount] = useState(0);
  const [totalElement, setTotalElement] = useState(0);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    canPreviousPage,
    previousPage,
    canNextPage,
    nextPage,
    gotoPage,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualSortBy: true,
      manualPagination: true,
      manualGlobalFilter: true,
      pageCount: controlledPageCount,
      totalElement: totalElement,
    } as any,
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as any;

  const { globalFilter, pageIndex, pageSize, sortBy } = state;

  useEffect(() => {
    gotoPage(0);
    setPageSize(10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    const fetchDataAndUpdateState = async () => {
      try {
        const result = await fetchData(pageIndex, pageSize, sortBy, globalFilter);
        setData(result.content);
        setControlledPageCount(Math.ceil(result.totalPages));
        setTotalElement(Math.ceil(result.totalElements));
      } catch (error) {
        expiredTokenValidation(error);
      }
    };

    fetchDataAndUpdateState();
  }, [fetchData, pageIndex, pageSize, sortBy, globalFilter, search]);

  const handleSortToggle = (column: ColumnTable) => {
    const { canSort, toggleSortBy, isSortedDesc } = column;

    if (canSort) {
      toggleSortBy(!isSortedDesc, false);
    }
  };

  return (
    <div>
      {/* Table */}
      <div className={`flex flex-col  ${loading && "opacity-20"}`}>
        <div className="-m-1.5 overflow-x-auto">
          <div className="p-1.5 min-w-full inline-block align-middle">
            <div className="border border-[#DCDBEE] rounded-lg overflow-hidden">
              <table className="min-w-full dark:bg-table-dark bg-table-light" {...getTableProps()}>
                {caption && (
                  <caption className="text-left text-[18px] font-semibold bg-primary-default dark:bg-table-dark  text-[#FFF] py-[19px] px-[29px] mb-5">
                    {caption}
                  </caption>
                )}
                <thead>
                  {headerGroups.map((headerGroup: HeaderGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column: any, index: number) => (
                        <th
                          key={index}
                          {...column.getHeaderProps()}
                          style={{
                            width: `${column.width}%`,
                            textAlign: `${column.align}`,
                          }}
                          onClick={() => {
                            if (column.id === "dealSourceCategory" || column.id === "Links" || column.id === "id") {
                              return;
                            } else {
                              handleSortToggle(column);
                            }
                          }}
                          scope="col"
                          className={`cursor-pointer px-[29px] py-3 text-start font-semibold text-lg ${["/dashboard"].includes(pathname)
                              ? "bg-table-sub-light dark:bg-black dark:text-white text-primary py-3"
                              : "bg-primary-default dark:bg-black text-[#fff] py-[19px]"
                            }`}
                        >
                          {column.render("Header")}
                          <span>{column.isSorted ? (column.isSortedDesc ? " ↓" : " ↑") : null}</span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className={`${
                    additionalDataKey ? "" : "divide-y divide-[#DCDBEE] bg-table-light dark:bg-table-dark"
                  }`}
                  data-testid="table-body"
                >
                  {data.length > 0 && controlledPageCount >= inputPage
                    ? page.map((row: RowTable, index: number) => {
                      prepareRow(row);
                      return (
                        <React.Fragment key={index}>
                          <tr {...row.getRowProps()} key={index}>
                            {row.cells.map((cell: Cell) => (
                              <td
                                {...cell.getCellProps()}
                                className="whitespace-nowrap p-[30px]  text-[#393C51] font-normal text-base  truncate max-w-[calc(100vw-104rem)] dark:text-white"
                              >
                                {cell.render("Cell")}{" "}
                              </td>
                            ))}
                          </tr>
                          {additionalDataKey && row.original[additionalDataKey] && (
                            <tr
                              key={`additional-${index}`}
                              className={`${index === page.length - 1 ? "" : "border-b border-[#D3D3E6] "}`}
                            >
                              <td
                                colSpan={columns.length}
                                className="px-[30px]   text-[#696868] dark:text-white   font-normal text-base truncate "
                              >
                                <div
                                  className="border-t border-[#E4E4FB] py-[15px] leading-6 truncate max-w-[calc(100vw-8rem)] max-h-[135px]"
                                  dangerouslySetInnerHTML={{
                                    __html: row.original[additionalDataKey],
                                  }}
                                ></div>
                                <button
                                  type="button"
                                  onClick={() => openModal?.(row.original[additionalDataKey])}
                                  className="text-secondary pb-[26px] font-bold underline float-right dark:text-white "
                                >
                                  Read More
                                </button>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    })
                    : !loading && (
                      <tr className="text-center">
                        <td colSpan={columns?.length} className="text-primary font-bold text-base p-4">
                          No data found
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
            {/* Pagination */}
            <div className="flex flex-col md:flex-row justify-between mt-6 px-[29px]">
              <div className="mb-4 md:mb-0 md:flex md:justify-between md:items-center">
                <div className="mb-2 md:mb-0">
                  <span className="text-primary text-lg font-medium border-r border-primary pr-5">
                    Showing {data.length !== 0 && pageIndex * pageSize + 1} {data.length !== 0 && "-"}{" "}
                    {pageIndex === pageCount - 1
                      ? Math.min((pageIndex + 1) * pageSize, totalElement)
                      : Math.min((pageIndex + 1) * pageSize, totalElement)}{" "}
                    of {totalElement}
                  </span>
                </div>
                <div className="flex items-center gap-4">
                  <div className="text-primary text-sm font-normal md:ml-5">Show Entries</div>
                  <select
                    value={pageSize}
                    style={{
                      background: `url(${Arrow})`,
                      backgroundPosition: "right 0.5rem center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "1.5em 1.5em",
                    }}
                    className="dark:text-white dark:placeholder:text-white dark:bg-b-secondary-dark dark:border-white border  border-primary rounded-full px-[22px] mr-4 py-[12px] text-primary   appearance-none outline-none cursor-pointer w-[82px]"
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[10, 20, 30, 40, 50].map((size) => (
                      <option key={size} value={size}>
                        {size}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="text-secondary flex items-center mt-4 md:mt-0">
                <button onClick={() => previousPage()} disabled={!canPreviousPage} className="mr-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    className="dark:brightness-[4] brightness-0"
                  >
                    <path
                      d="M9.1233 9.87681L12.1644 6.83576L10.6438 5.31524L6.08228 9.87681L10.6438 14.4385L12.1644 12.9179L9.1233 9.87681Z"
                      fill="#F3797E"
                    />
                  </svg>
                </button>
                {Array.from({ length: pageCount }, (_, i) => {
                  const pageNumber = i + 1; // Add 1 to start from 1
                  if (pageNumber > 0) {
                    if (pageCount > 4) {
                      if (
                        (pageNumber >= pageIndex && pageNumber <= pageIndex + 2) ||
                        pageNumber === 1 ||
                        pageNumber === pageCount
                      ) {
                        return (
                          <button
                            key={i}
                            onClick={() => gotoPage(pageNumber - 1)} // Subtract 1 when calling gotoPage
                            className={`px-2  text-secondary ${pageNumber === pageIndex + 1 ? "border-b-2" : ""}`}
                          >
                            <span>{pageNumber}</span>
                          </button>
                        );
                      } else if (
                        (pageIndex >= 3 && pageNumber === 2) ||
                        (pageIndex < pageCount - 2 && pageNumber === pageCount - 1)
                      ) {
                        // Render ellipsis for 2nd and 2nd-to-last page when not on the edge
                        return (
                          <span key={`ellipsis${i}`} className="p-2">
                            ...
                          </span>
                        );
                      }
                    } else {
                      // Render pagination for 3 or fewer pages
                      return (
                        <button
                          key={i}
                          onClick={() => gotoPage(pageNumber - 1)} // Subtract 1 when calling gotoPage
                          className={`px-2  text-secondary  ${pageNumber === pageIndex + 1 ? "border-b-2" : ""} `}
                        >
                          <span>{pageNumber}</span>
                        </button>
                      );
                    }
                  }
                  return null;
                })}

                <div className="flex items-center">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      gotoPage(Number(inputPage) - 1);
                      // setInputPage("");
                    }}
                  >
                    <button className="hidden md:inline-block" type="submit">
                      Go{" "}
                    </button>
                    <input
                      type="text"
                      className="border border-[#EDEDF7] bg-[#ffffff] rounded-md mx-2 max-w-[50px] outline-none px-2 dark:bg-primary-dark"
                      value={inputPage}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        setInputPage(inputValue);
                      }}
                    />
                  </form>
                </div>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                  <svg
                    className="dark:brightness-[4] brightness-0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                  >
                    <path
                      d="M9.5537 9.87681L6.51257 6.83576L8.03319 5.31524L12.5947 9.87681L8.03319 14.4385L6.51257 12.9179L9.5537 9.87681Z"
                      fill="#F3797E"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader loading={loading} />}
    </div>
  );
};

export default Table;
