import React, { useEffect, useState } from "react";
import Breadcrumbs from "../Components/Breadcrumbs/Breadcrumbs";
import Keyboard from "../Assets/svg/keyboard2.svg";
import Minus from "../Assets/svg/minus.svg";
import Plus from "../Assets/svg/pink-plus.svg";
import {
  ConditionCategoryRestControllerApi,
  ConditionStatusRestControllerApi,
  MnaCategoryRestControllerApi,
  MnaRestControllerApi,
  MnaSubCategoryRestControllerApi,
  FeatureControllerApi,
  LinkCategoryRestControllerApi,
} from "../openapi";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import InputField from "../Components/Common-inputs";
import toast from "react-hot-toast";
import Loader from "../Components/Loader";
import { formatAndValidateDate } from "../utils";
import AsyncSelect from "../Components/Async-Select";
import { apiConfig } from "../Components/ConfigurationApi/Configuration";
import { getApiData } from "../Controller/getApiDataConfiguration";
import FileUpload from "../Components/File-Upload";
import { expiredTokenValidation } from "../api/expiredTokenValidation";

interface FormProps {
  name: string;
  categoryId: null;
  subCategoryId: null;
  description: string;
  acquirer: string;
  target: string;
  tickerAcquirer: string;
  tickerTarget: string;
  sectorAcquirer: string;
  sectorTarget: string;
  incorpAcquirer: string;
  incorpTarget: string;
  hqAcquirer: string;
  hqTarget: string;
  geoExposureAcquirer: string;
  geoExposureTarget: string;
  financialAdvisorForAcquirer: string;
  financialAdvisorForTarget: string;
  legalCounselForAcquirer: string;
  legalCounselForTarget: string;
  whatMatters: [{ note: string; mnaId: number }];
  premium: string;
  terms: string;
  tags: [];
  files: null;
  dateAnnounced: Date | null;
  dateClosing: Date | null;
  closingLanguage: string;
  brokerClosingEst: string;
  financing: string;
  breakFeeAcquirer: string;
  breakFeeTarget: string;
  outDates: [
    {
      dateOut: null;
      comment: string;
      mnaId: number;
    }
  ];
  requiredToLitigate: string;
  appraisalRights: string;
  specificPerformance: string;
  links: [
    {
      linkCategoryId: string;
      link: string;
      mnaId: number;
    }
  ];
  features: [
    {
      name: string;
      value: string;
      mnaId: number;
    }
  ];
  conditions: [
    {
      statusId: string;
      conditionCategoryId: string;
      commentary: string;
      filed: null;
      expectedConfirmed: null;
      mnaId: number;
      categoryOtherComment: string;
    }
  ];
}

// Define initial form values
const initialValues: FormProps = {
  name: "",
  categoryId: null,
  subCategoryId: null,
  description: "",
  acquirer: "",
  target: "",
  tickerAcquirer: "",
  tickerTarget: "",
  sectorAcquirer: "",
  sectorTarget: "",
  incorpAcquirer: "",
  incorpTarget: "",
  hqAcquirer: "",
  hqTarget: "",
  geoExposureAcquirer: "",
  geoExposureTarget: "",
  financialAdvisorForAcquirer: "",
  financialAdvisorForTarget: "",
  legalCounselForAcquirer: "",
  legalCounselForTarget: "",
  whatMatters: [{ note: "", mnaId: -1 }],
  premium: "",
  terms: "",
  tags: [],
  files: null,
  dateAnnounced: null,
  dateClosing: null,
  closingLanguage: "",
  brokerClosingEst: "",
  financing: "",
  breakFeeAcquirer: "",
  breakFeeTarget: "",
  outDates: [
    {
      dateOut: null,
      comment: "",
      mnaId: -1,
    },
  ],
  requiredToLitigate: "",
  appraisalRights: "",
  specificPerformance: "",
  links: [
    {
      linkCategoryId: "",
      link: "",
      mnaId: -1,
    },
  ],
  features: [
    {
      name: "",
      value: "",
      mnaId: -1,
    },
  ],
  conditions: [
    {
      statusId: "",
      conditionCategoryId: "",
      commentary: "",
      filed: null,
      expectedConfirmed: null,
      categoryOtherComment: "",
      mnaId: -1,
    },
  ],
};

const OldAddEditMna = () => {
  const [serverError, setServerError] = useState<any>(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [tagLoading, setTagLoading] = useState(false);
  const [mnaCategoryList, setMnaCategoryList] = useState<any>([]);
  const [mnaSubCategoryList, setMnaSubCategoryList] = useState<any>([]);
  const [linkCategoryList, setLinkCategoryList] = useState<any>([]);
  const [conditionCategoryList, setConditionCategoryList] = useState<any>([]);
  const [conditionStatusList, setConditionStatusList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [featuresLoading, setFeaturesLoading] = useState(false);
  const [changedValues, setChangedValues] = useState<any>({});

  const handleAddEntry = () => {
    formik.setValues({
      ...formik.values,
      whatMatters: [...formik.values.whatMatters, { note: "", mnaId: -1 }],
    });
  };

  const handleAddOutDates = () => {
    formik.setValues({
      ...formik.values,
      outDates: [
        ...formik.values.outDates,
        {
          dateOut: null,
          comment: "",
          mnaId: -1,
        },
      ],
    });
  };

  const handleAddLinks = () => {
    formik.setValues({
      ...formik.values,
      links: [
        ...formik.values.links,
        {
          linkCategoryId: "",
          link: "",
          mnaId: -1,
        },
      ],
    });
  };

  const handleAddFeatures = () => {
    formik.setValues({
      ...formik.values,
      features: [
        ...formik.values.features,
        {
          name: "",
          value: "",
          mnaId: -1,
        },
      ],
    });
  };

  const handleAddConditions = () => {
    formik.setValues({
      ...formik.values,
      conditions: [
        ...formik.values.conditions,
        {
          statusId: "",
          conditionCategoryId: "",
          commentary: "",
          filed: null,
          expectedConfirmed: null,
          mnaId: -1,
        },
      ],
    });
  };

  const handleRemoveEntry = (indexToRemove: number) => {
    formik.setValues({
      ...formik.values,
      whatMatters: formik.values.whatMatters.filter(
        (_: any, index: number) => index !== indexToRemove
      ),
    });
  };

  const handleRemoveOutDates = (indexToRemove: number) => {
    formik.setValues({
      ...formik.values,
      outDates: formik.values.outDates.filter(
        (_: any, index: number) => index !== indexToRemove
      ),
    });
  };

  const handleRemoveCondition = (indexToRemove: number) => {
    formik.setValues({
      ...formik.values,
      conditions: formik.values.conditions.filter(
        (_: any, index: number) => index !== indexToRemove
      ),
    });
  };

  const handleRemoveLink = (indexToRemove: number) => {
    formik.setValues({
      ...formik.values,
      links: formik.values.links.filter(
        (_: any, index: number) => index !== indexToRemove
      ),
    });
  };

  const handleRemoveFeature = (indexToRemove: number) => {
    formik.setValues({
      ...formik.values,
      features: formik.values.features.filter(
        (_: any, index: number) => index !== indexToRemove
      ),
    });
  };

  // Fetch note source type options on component mount
  useEffect(() => {
    fetchAllList();
  }, []);

  // Function to fetch note source type options
  const fetchAllList = async () => {
    setLoading(true);
    try {
      const mnaCategory = new MnaCategoryRestControllerApi(apiConfig());
      const mnaSubCategory = new MnaSubCategoryRestControllerApi(apiConfig());
      const linkCategory = new LinkCategoryRestControllerApi(apiConfig());
      const conditionCategory = new ConditionCategoryRestControllerApi(
        apiConfig()
      );
      const conditionStatus = new ConditionStatusRestControllerApi(apiConfig());

      const response1 = await mnaCategory.getMnaCategories(
        undefined,
        0,
        20,
        undefined
      );
      const response2 = await mnaSubCategory.getMnaSubCategories(
        undefined,
        0,
        20,
        undefined
      );
      const response3 = await linkCategory.getLinkCategories(
        undefined,
        0,
        20,
        undefined
      );
      const response4 = await conditionCategory.getConditionCategories(
        undefined,
        0,
        20,
        undefined
      );
      const response5 = await conditionStatus.getConditionStatuses(
        undefined,
        0,
        20,
        undefined
      );

      setConditionCategoryList(
        response4.data.content?.map((item: any) => {
          return { id: item.id, name: item.name };
        })
      );
      setConditionStatusList(
        response5.data.content?.map((item: any) => {
          return { id: item.id, status: item.status };
        })
      );
      setMnaCategoryList(
        response1.data.content?.map((item: any) => {
          return { id: item.id, name: item.name };
        })
      );
      setMnaSubCategoryList(
        response2.data.content?.map((item: any) => {
          return { id: item.id, name: item.name };
        })
      );
      setLinkCategoryList(
        response3.data.content?.map((item: any) => {
          return { id: item.id, name: item.name };
        })
      );
    } catch (error) {
      expiredTokenValidation(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!id) return;
    fetchMnaData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchMnaData = async () => {
    const api = new MnaRestControllerApi(apiConfig());
    const response = await api.getMnaById(Number(id));
    const featuresOptions = await fetchFeatures();

    if (response.data) {
      const data: any = response.data;

      data.tags =
      data.tags?.map((tag: any) => {
        return { value: tag.id, label: tag.tag };
      }) || null;
      
      data.dateAnnounced = formatAndValidateDate(data?.dateAnnounced) ? new Date(data?.dateAnnounced) : null;

      data.dateClosing = formatAndValidateDate(data?.dateClosing) ? new Date(data?.dateClosing) : null;

      data.financing = formatAndValidateDate(data.financing);

      data.brokerClosingEst = formatAndValidateDate(data?.brokerClosingEst);

      delete data.size;
      delete data.dateCreated;
      delete data.dateModified;

      if (data.mnaOutDates?.length) {
        data.outDates = data.mnaOutDates?.map((obj: any) => {
          if (obj?.dateOut) {
            obj.dateOut = formatAndValidateDate(obj.dateOut);
          }
          return obj;
        });
      } else {
        data.outDates = [
          {
            dateOut: null,
            comment: "",
            mnaId: -1,
          },
        ];
      }

      if (data.conditions?.length) {
        data.conditions = data.conditions.map((obj: any) => {
          if (isNaN(obj?.field)) {
            obj.filed = formatAndValidateDate(obj?.filed);
          }
          if (obj?.expectedConfirmed) {
            obj.expectedConfirmed = formatAndValidateDate(obj?.expectedConfirmed);
          }
          delete obj.lastModifiedBy;
          delete obj.dateCreated;
          delete obj.dateModified;
          delete obj.createdBy;
          return obj;
        });
      } else {
        data.conditions = [
          {
            statusId: "",
            conditionCategoryId: "",
            commentary: "",
            filed: null,
            expectedConfirmed: null,
            mnaId: -1,
          },
        ];
      }

      if (!data.whatMatters?.length) {
        data.whatMatters = [{ mnaId: -1, note: "" }];
      }

      if (!data.links?.length) {
        data.links = [
          {
            linkCategoryId: "",
            link: "",
            mnaId: -1,
          },
        ];
      }

      if (data.features?.length > 0) {
        data.features = data.features.map((element: any) => ({
          ...element,
          name: featuresOptions.find((feature) => feature.value === element.featureId),
        }));
      } else {
        data.features = [
          {
            name: "",
            value: "",
            mnaId: -1,
          },
        ];
      }

      delete data.mnaOutDates;
      delete data.outDate;

      formik.setValues(data);
      setChangedValues(data);
    }
  };

  // Formik hook for form management
  const formik: any = useFormik({
    initialValues,
    onSubmit: async (values: any, { setSubmitting, setErrors }) => {
      const data: any = { ...values, version: 0 };
      if (data.whatMatters.length > 0) {
        const whatMatters = data.whatMatters.reduce(
          (result: any, obj: any, index: number) => {
            if (obj.id) {
              Object.assign(obj, { mnaId: -1 });
              const changedObj: any = changedValues.whatMatters.find((item: any) => item.id === obj.id);
              const allValuesAreNullOrEmpty = Object.keys(changedObj)?.every(
                (key) => key === "mnaId" || obj[key] === changedObj[key]
              );
              if (!allValuesAreNullOrEmpty) {
                result.edit = result.edit || {};
                result.edit = {
                  ...result.edit,
                  [obj.id]: obj,
                };
              }
            } else {
              const allValuesAreNullOrEmpty = Object.keys(obj).every(
                (key) => key === "mnaId" || obj[key] === null || obj[key] === ""
              );
              if (!allValuesAreNullOrEmpty) {
                result.add = result.add || [];
                result.add = [...result.add, obj];
              }
            }

            return result;
          },
          { add: null, edit: null, delete: null }
        );

        changedValues.whatMatters?.forEach((item: any) => {
          const deletedObj = data.whatMatters?.map((obj: any) => {
            if (obj?.id === item.id) {
              return obj.id;
            }
          });
          if (!deletedObj.includes(item.id)) {
            whatMatters.delete = whatMatters.delete || [];
            whatMatters.delete = [...whatMatters.delete, item.id];
          }
        });

        if (id) {
          data.whatMatters = whatMatters;
        } else {
          delete whatMatters.edit;
          delete whatMatters.delete;
          data.whatMatters = whatMatters?.add?.length > 0 ? whatMatters : null;
        }
      }

      if (data.links.length > 0) {
        const links = data.links.reduce(
          (result: any, obj: any, index: number) => {
            if (obj.id) {
              Object.assign(obj, { mnaId: -1 });
              const changedObj: any = changedValues.links.find((item: any) => item.id === obj.id);
              delete changedObj.category;
              const allValuesAreNullOrEmpty = Object.keys(changedObj)?.every(
                (key) => key === "mnaId" || obj[key] == changedObj[key]
              );
              if (!allValuesAreNullOrEmpty) {
                result.edit = result.edit || {};
                result.edit = {
                  ...result.edit,
                  [obj.id]: obj,
                };
              }
            } else {
              const allValuesAreNullOrEmpty = Object.keys(obj).every(
                (key) => key === "mnaId" || obj[key] === null || obj[key] === ""
              );
              if (!allValuesAreNullOrEmpty) {
                result.add = result.add || [];
                result.add = [...result.add, obj];
              }
            }

            return result;
          },
          { add: null, edit: null, delete: null }
        );

        changedValues.links?.forEach((item: any) => {
          const deletedObj = data.links?.map((obj: any) => {
            if (obj?.id === item.id) {
              return obj.id;
            }
          });
          if (!deletedObj.includes(item.id)) {
            links.delete = links.delete || [];
            links.delete = [...links.delete, item.id];
          }
        });

        if (id) {
          data.links = links;
        } else {
          delete links.edit;
          delete links.delete;
          data.links = links?.add?.length > 0 ? links : null;
        }
      }

      if (data.features.length > 0) {
        const features = data.features.reduce(
          (result: any, obj: any, index: number) => {
            if (obj.id) {
              Object.assign(obj, { mnaId: -1 });
              const changedObj: any = changedValues.features.find((item: any) => item.id === obj.id);
              delete changedObj.category;
              const allValuesAreNullOrEmpty = Object.keys(changedObj)?.every(
                (key) => key === "mnaId" || obj[key] == changedObj[key]
              );
              if (!allValuesAreNullOrEmpty) {
                result.edit = result.edit || {};
                result.edit = {
                  ...result.edit,
                  [obj.id]: {
                    version: 0,
                    featureId: obj.name?.value,
                    mnaId: id,
                    value: obj.value,
                  },
                };
              }
            } else {
              const allValuesAreNullOrEmpty = Object.keys(obj).every(
                (key) => key === "mnaId" || obj[key] === null || obj[key] === ""
              );
              if (!allValuesAreNullOrEmpty) {
                result.add = result.add || [];
                result.add = [
                  ...result.add,
                  {
                    version: 0,
                    featureId: obj.name?.value,
                    mnaId: obj.mnaId,
                    value: obj.value,
                  },
                ];
              }
            }

            return result;
          },
          { add: null, edit: null, delete: null }
        );

        changedValues.features?.forEach((item: any) => {
          const deletedObj = data.features?.map((obj: any) => {
            if (obj?.id === item.id) {
              return obj.id;
            }
          });
          if (!deletedObj.includes(item.id)) {
            features.delete = features.delete || [];
            features.delete = [...features.delete, item.id];
          }
        });

        if (id) {
          data.features = features;
        } else {
          delete features.edit;
          delete features.delete;
          data.features = features?.add?.length > 0 ? features : null;
        }
      }

      if (data.conditions.length > 0) {
        const conditions = data.conditions.reduce(
          (result: any, obj: any, index: number) => {
            if (Number(obj.conditionCategoryId) !== 15) {
              delete obj.categoryOtherComment;
            }
            if (obj.id) {
              Object.assign(obj, { mnaId: -1 });
              const changedObj: any = changedValues.conditions.find((item: any) => item.id === obj.id);

              const allValuesAreNullOrEmpty = Object.keys(changedObj)?.every(
                (key) => key === "mnaId" || obj[key] == changedObj[key]
              );

              if (!allValuesAreNullOrEmpty) {
                result.edit = result.edit || {};
                result.edit = {
                  ...result.edit,
                  [obj.id]: obj,
                };
              }
            } else {
              const allValuesAreNullOrEmpty = Object.keys(obj).every(
                (key) => key === "mnaId" || obj[key] === null || obj[key] === ""
              );
              if (!allValuesAreNullOrEmpty) {
                result.add = result.add || [];
                result.add = [...result.add, obj];
              }
            }

            return result;
          },
          { add: null, edit: null, delete: null }
        );

        changedValues.conditions?.forEach((item: any) => {
          const deletedObj = data.conditions?.map((obj: any) => {
            if (obj?.id === item.id) {
              return obj.id;
            }
          });
          if (!deletedObj.includes(item.id)) {
            conditions.delete = conditions.delete || [];
            conditions.delete = [...conditions.delete, item.id];
          }
        });

        if (id) {
          data.conditions = conditions;
        } else {
          delete conditions.edit;
          delete conditions.delete;
          data.conditions = conditions?.add?.length > 0 ? conditions : null;
        }
      }

      if (data.outDates.length > 0) {
        const outDates = data.outDates.reduce(
          (result: any, obj: any, index: number) => {
            if (obj.id) {
              Object.assign(obj, { mnaId: -1 });
              const changedObj: any = changedValues.outDates.find((item: any) => item.id === obj.id);
              const allValuesAreNullOrEmpty = Object.keys(changedObj)?.every(
                (key) => key === "mnaId" || obj[key] == changedObj[key]
              );
              if (!allValuesAreNullOrEmpty) {
                result.edit = result.edit || {};
                result.edit = {
                  ...result.edit,
                  [obj.id]: obj,
                };
              }
            } else {
              const allValuesAreNullOrEmpty = Object.keys(obj).every(
                (key) => key === "mnaId" || obj[key] === null || obj[key] === ""
              );
              if (!allValuesAreNullOrEmpty) {
                result.add = result.add || [];
                result.add = [...result.add, obj];
              }
            }

            return result;
          },
          { add: null, edit: null, delete: null }
        );

        changedValues.outDates?.forEach((item: any) => {
          const deletedObj = data.outDates?.map((obj: any) => {
            if (obj?.id === item.id) {
              return obj.id;
            }
          });
          if (!deletedObj.includes(item.id)) {
            outDates.delete = outDates.delete || [];
            outDates.delete = [...outDates.delete, item.id];
          }
        });

        if (id) {
          data.outDates = outDates;
        } else {
          delete outDates.edit;
          delete outDates.delete;
          data.outDates = outDates?.add?.length > 0 ? outDates : null;
        }
      }

      if (data.tags?.length > 0) {
        const tags = data.tags.reduce(
          (result: any, obj: any) => {
            if (!changedValues.tags?.includes(obj)) {
              result.add = result.add || [];
              result.add = [...result.add, { tag: obj.label }];
            }
            return result;
          },
          { add: null, delete: null }
        );

        changedValues.tags?.forEach((item: any) => {
          const deletedObj = data.tags?.map((obj: any) => {
            if (obj?.value === item.value) {
              return obj.value;
            }
          });
          if (!deletedObj.includes(item.value)) {
            tags.delete = tags.delete || [];
            tags.delete = [...tags.delete, item.value];
          }
        });
        if (id) {
          data.tags = tags;
        } else {
          delete tags.delete;
          data.tags = tags?.add?.length > 0 ? tags : null;
        }
      } else {
        const tags: any = { add: null, delete: [] };
        tags.delete = changedValues.tags?.map((item: any) => item.value);
        data.tags = id ? tags : null;
      }

      try {
        const api = new MnaRestControllerApi(apiConfig());

        id ? await api.updateMna(Number(id), data) : await api.createMna(data);

        navigate("/old-mnas");
        toast.custom(
          (t) => (
            <div
              className={`${
                t.visible ? "animate-enter" : "animate-leave"
              } max-w-md w-full bg-success shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
            >
              <div className="flex-1 w-0 p-4">
                <div className="flex items-center">
                  <div className="flex-shrink-0 pt-0.5">
                    <svg
                      className="dark:brightness-[4]"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="50"
                      height="50"
                      viewBox="0 0 48 48"
                    >
                      <path
                        fill="#c8e6c9"
                        d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
                      ></path>
                      <path
                        fill="#4caf50"
                        d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"
                      ></path>
                    </svg>
                  </div>
                  <div className="ml-3 flex-1 text-white">
                    <p>{`Record ${id ? "update" : "insert"} successfully`}</p>
                  </div>
                </div>
              </div>
            </div>
          ),
          {
            duration: 500,
          }
        );
      } catch (error: any) {
        if (error.response) {
          if (error.response.data?.exception && !error.response.data?.fieldErrors.length) {
            toast.custom(
              (t) => (
                <div
                  className={`${
                    t.visible ? "animate-enter" : "animate-leave"
                  } max-w-md w-full bg-danger shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                >
                  <div className="flex-1 w-0 p-4">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 pt-0.5">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="50"
                          height="50"
                          viewBox="0 0 48 48"
                          className="dark:brightness-[4]"
                        >
                          <path
                            fill="#F78F8F"
                            d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
                          ></path>
                          <path
                            fill="#fff"
                            d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z"
                          ></path>
                          <path
                            fill="#fff"
                            d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z"
                          ></path>
                        </svg>
                      </div>
                      <div className="ml-3 flex-1 text-white">
                        <p>{error.response.data?.exception}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ),
              {
                duration: 500,
              }
            );
          }
          if (error.response.data?.fieldErrors.length) {
            const errorObject: any = error.response.data?.fieldErrors.reduce(
              (accumulator1: any, error: any) => {
                if (error.field.includes("[")) {
                  const parts = error.field.split(/\.|\[|\]/);
                  const name = parts[0];
                  const index = parts[2];
                  const keyName = parts[4];

                  const fieldName = values[name];
                  const obj = fieldName.findIndex((item: any) => item.id === Number(index));

                  if (obj !== -1) {
                    const value = formik.values[name][obj][keyName];

                    if (value === "" || value === null) {
                      accumulator1 = {
                        ...accumulator1,
                        [`${name}[${obj}].${keyName}`]: error.defaultMsg,
                      };
                    }
                  } else {
                    const indicesWithoutId = fieldName
                      .map((obj: any, index: any) => (obj.hasOwnProperty("id") ? -1 : index))
                      .filter((index: any) => index !== -1);

                    indicesWithoutId.forEach((item: any) => {
                      const obj = formik.values[name][item];
                      const isValuesEmpty = Object.keys(obj).every(
                        (key) => key === "mnaId" || obj[key] === "" || obj[key] === null
                      );

                      if (!isValuesEmpty && (obj[keyName] === "" || obj[keyName] === null)) {
                        accumulator1 = {
                          ...accumulator1,
                          [`${name}[${item}].${keyName}`]: error.defaultMsg,
                        };
                      }
                    });
                  }
                }
                accumulator1[error.field] = error.defaultMsg;

                return accumulator1;
              },
              {} as any // Set initial value for accumulator
            );

            setServerError(errorObject);
            setErrors(errorObject);
          }
        } else {
          console.error("Error submitting form:", error.message);
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  const companyInfo = [
    { label: "Name", input1Name: "target", input2Name: "acquirer" },
    {
      label: "Ticker",
      input1Name: "tickerTarget",
      input2Name: "tickerAcquirer",
    },
    {
      label: "Sector",
      input1Name: "sectorTarget",
      input2Name: "sectorAcquirer",
    },
    {
      label: "Incorporation",
      input1Name: "incorpTarget",
      input2Name: "incorpAcquirer",
    },
    { label: "Headquarters", input1Name: "hqTarget", input2Name: "hqAcquirer" },
    {
      label: "Geographic Exposure",
      input1Name: "geoExposureTarget",
      input2Name: "geoExposureAcquirer",
    },
    {
      label: "Financial Advisors",
      input1Name: "financialAdvisorForTarget",
      input2Name: "financialAdvisorForAcquirer",
    },
    {
      label: "Legal Counsel",
      input1Name: "legalCounselForTarget",
      input2Name: "legalCounselForAcquirer",
    },
  ];

  const handleUploadCallBack = async (responses: any) => {};

  const fetchTags = async (inputValue: string): Promise<any[]> => {
    // Simulate an asynchronous call with a delay
    setTagLoading(true);
    try {
      const api = new getApiData();
      setTagLoading(false);
      return api.getTagRestContollerApi(inputValue);
    } catch (error) {
      console.error("Error fetching options:", error);
      setTagLoading(false);
      return [];
    }
  };

  const fetchFeatures = async (inputValue?: string): Promise<any[]> => {
    // Simulate an asynchronous call with a delay
    setFeaturesLoading(true);
    try {
      const api = new getApiData();
      setFeaturesLoading(false);
      return api.getFeatureControllerApi();
    } catch (error) {
      console.error("Error fetching options:", error);
      setFeaturesLoading(false);
      return [];
    }
  };

  const createFeatureName = async (inputValue: string, indexToUpdate: number) => {
    // Simulate an asynchronous call with a delay
    setFeaturesLoading(true);
    try {
      const api = new FeatureControllerApi(apiConfig());
      const response = await api.createFeature({
        version: 0,
        name: inputValue,
      });
      const createdFeatureName = response.data;
      formik.setValues({
        ...formik.values,
        features: formik.values.features.map((value: any, index: number) => {
          if (index === indexToUpdate) {
            return {
              ...value,
              name: {
                value: createdFeatureName.id,
                label: createdFeatureName.name,
              },
            };
          } else return value;
        }),
      });
      setFeaturesLoading(false);
    } catch (error) {
      console.error("Error Creating feature name:", error);
      setFeaturesLoading(false);
      return [];
    }
  };

  return (
    <React.Fragment>
      <div className={`p-[30px] ${loading && "opacity-20"}`}>
        {/* Breadcrumbs component */}
        <Breadcrumbs breadcrumbs={[id ? "Update Old MNA" : "Add New Old MNA"]} />

        <div className="my-[30px]">
          <form onSubmit={formik.handleSubmit}>
            <div className="border border-[#E4E3ED] rounded-lg">
              <div className="px-[23px] py-[30px] border-b border-b-[#E5E4FB]">
                <h2 className="text-[#000] dark:text-white  text-lg font-bold">Basic Info</h2>
              </div>
              <div className="px-[23px] py-[30px]">
                <div className="flex flex-col gap-[30px] xl:flex-row">
                  {/* Name */}

                  <div className="w-full xl:w-2/4">
                    <InputField
                      label="Name"
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Enter here..."
                      serverError={serverError || ""}
                      formik={formik}
                      showIcon
                      required={true}
                    />
                  </div>

                  {/* Category */}
                  <div className="w-full xl:w-1/4">
                    <InputField
                      label="Category"
                      id="categoryId"
                      name="categoryId"
                      type="select"
                      placeholder="Category"
                      optionList={mnaCategoryList}
                      labelKey="name"
                      valueKey="id"
                      serverError={serverError || ""}
                      formik={formik}
                      showIcon
                      required={true}
                    />
                  </div>

                  {/* Sub-Category */}
                  <div className="w-full xl:w-1/4">
                    <InputField
                      label="Sub-Category"
                      id="subCategoryId"
                      name="subCategoryId"
                      type="select"
                      optionList={mnaSubCategoryList}
                      labelKey="name"
                      serverError={serverError || ""}
                      valueKey="id"
                      placeholder="Sub-Category"
                      formik={formik}
                      showIcon
                      last={true}
                    />
                  </div>
                </div>

                {/* Description */}
                <div className="w-full mt-[30px] ">
                  <InputField
                    label="Description"
                    id="description"
                    name="description"
                    type="textarea"
                    rows={4}
                    placeholder="Enter here..."
                    formik={formik}
                    serverError={serverError || ""}
                    showIcon
                    last={true}
                  />
                </div>

                <div className="w-full mt-[30px]">
                  <AsyncSelect
                    label="Tags"
                    name="tags"
                    id="tags"
                    placeholder="Tags"
                    fetchOptions={fetchTags}
                    formik={formik}
                    loading={tagLoading}
                    error={""}
                    isMulti
                    last
                    noOptionsMessage="No tag found"
                    bgColor="#fff"
                    tags
                  />
                </div>
              </div>
            </div>
            <div className="border border-[#E4E3ED] rounded-lg mt-[30px] dark:bg-table-dark">
              <div className="px-[23px] py-[30px] border-b border-b-[#E5E4FB]">
                <h2 className="text-[#000] dark:text-white  text-lg font-bold">Company Information</h2>
              </div>
              <div className="px-[23px] py-[30px]">
                <div className="border border-[#E4E3ED] rounded-lg">
                  <table className="w-full">
                    <thead>
                      <tr className="border-b border-[#E5E4FB] bg-[#4B49AC] dark:bg-black text-[#fff] text-lg font-semibold text-left">
                        <th className="rounded-tl-md w-[385px] px-[30px] py-[19px]">Topic</th>
                        <th className="w-[585px] px-[30px] py-[19px]">Target</th>
                        <th className="rounded-tr-md w-[585px] px-[30px] py-[19px]">Acquirer</th>
                      </tr>
                    </thead>
                    <tbody>
                      {companyInfo.map((item: any, index: number) => (
                        <tr
                          className={`${index === companyInfo.length - 1 ? "" : "border-b border-[#E5E4FB]"}`}
                          key={index}
                        >
                          <td className="px-[30px] py-[19px]  text-[#393C51]  dark:text-white  text-base font-medium">
                            <div className="flex justify-between items-center">
                              <label
                                htmlFor={item.label}
                                className="text-base  text-[#393C51]  dark:text-white  font-medium mb-2 flex gap-1"
                              >
                                {item.label}
                              </label>

                              <div className="tooltip cursor-pointer">
                                <div
                                  className={`bg-[#fff]  bottom-9 tooltiptext  text-[#393C51]  dark:text-white dark:bg-primary-dark transition-all  text-sm  z-50  w-[328px] px-[14px] pt-[12px] pb-[17px] rounded-[10px] border  border-[#4185EE] after:border-transparent after:border-t-[#4185EE]  before:top-[99%]`}
                                >
                                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                  exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                                </div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="19"
                                  viewBox="0 0 18 19"
                                  fill="none"
                                  className="cursor-pointer dark:brightness-[4]"
                                >
                                  <path
                                    d="M9 0.5C4.02571 0.5 0 4.52525 0 9.5C0 14.4742 4.02525 18.5 9 18.5C13.9743 18.5 18 14.4747 18 9.5C18 4.52571 13.9747 0.5 9 0.5ZM9 17.2442C4.72985 17.2442 1.25582 13.7702 1.25582 9.5C1.25582 5.22982 4.72985 1.75582 9 1.75582C13.2702 1.75582 16.7442 5.22982 16.7442 9.5C16.7442 13.7702 13.2702 17.2442 9 17.2442Z"
                                    fill="#1E87F0"
                                  />
                                  <path
                                    d="M8.73354 11.8881C8.23597 11.8881 7.83319 12.3027 7.83319 12.8002C7.83319 13.286 8.22413 13.7125 8.73354 13.7125C9.24295 13.7125 9.6457 13.286 9.6457 12.8002C9.6457 12.3027 9.23107 11.8881 8.73354 11.8881ZM8.88752 4.98145C7.28819 4.98145 6.55371 5.92922 6.55371 6.56893C6.55371 7.03095 6.94465 7.24421 7.2645 7.24421C7.90424 7.24421 7.64363 6.33197 8.85198 6.33197C9.44429 6.33197 9.9182 6.59262 9.9182 7.13758C9.9182 7.77728 9.25477 8.14452 8.86383 8.47622C8.52025 8.77234 8.07011 9.2581 8.07011 10.2769C8.07011 10.8929 8.23597 11.0706 8.72166 11.0706C9.30212 11.0706 9.4206 10.81 9.4206 10.5849C9.4206 9.96889 9.43245 9.61349 10.084 9.10408C10.4039 8.85531 11.4108 8.04971 11.4108 6.93613C11.4108 5.82256 10.4039 4.98145 8.88752 4.98145Z"
                                    fill="#1E87F0"
                                  />
                                </svg>{" "}
                              </div>
                            </div>
                          </td>
                          <td className="px-[30px] py-[19px]   text-[#393C51]  dark:text-white  text-base font-medium">
                            <div className="relative">
                              <input
                                id={item.input1Name}
                                type="text"
                                name={item.input1Name}
                                value={formik.values[item.input1Name]}
                                placeholder="Enter here..."
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={`dark:text-white dark:placeholder:text-white dark:border-white dark:bg-primary-dark py-[18px] px-[30px] border border-[#C3C1DF] rounded-full text-base text-[#7B7B7B] font-light w-full bg-[#FAFAFE] outline-none `}
                              />
                              <span className="absolute right-7 top-5">
                                <img src={Keyboard} alt="keyboard" />
                              </span>
                            </div>
                          </td>
                          <td className="px-[30px] py-[19px]  text-[#393C51]  dark:text-white  text-base font-medium">
                            <div className="relative">
                              <input
                                id={item.input2Name}
                                type="text"
                                name={item.input2Name}
                                value={formik.values[item.input2Name]}
                                placeholder="Enter here..."
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={`dark:text-white dark:placeholder:text-white dark:border-white dark:bg-primary-dark py-[18px] px-[30px] border border-[#C3C1DF] rounded-full text-base text-[#7B7B7B] font-light w-full bg-[#FAFAFE] outline-none `}
                              />
                              <span className="absolute right-7 top-5">
                                <img src={Keyboard} alt="keyboard" />
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="border border-[#E4E3ED] rounded-lg  mt-[30px]">
              <div className="px-[23px] py-[30px] border-b border-b-[#E5E4FB]">
                <h2 className="text-[#000] dark:text-white  text-lg font-bold">Company Descriptions</h2>
              </div>
              <div className="px-[23px] py-[30px]">
                <div className="flex flex-col gap-[30px] xl:flex-row">
                  {/* Description */}
                  <div className="w-full">
                    <InputField
                      label="Description"
                      id="reviewed"
                      name="reviewed"
                      type="textarea"
                      rows={4}
                      placeholder="Enter here..."
                      formik={formik}
                      serverError={serverError || ""}
                      showIcon
                      last={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="border border-[#E4E3ED] rounded-lg  mt-[30px]">
              <div className="px-[23px] py-[30px] border-b border-b-[#E5E4FB]">
                <h2 className="text-[#000] dark:text-white  text-lg font-bold">What Matters</h2>
              </div>
              <div className="px-[23px] py-[30px]">
                <div>
                  {/* What Matters */}
                  {formik.values.whatMatters?.map((entry: any, index: number) => (
                    <div key={index} className="w-full mb-6">
                      <div>
                        <InputField
                          label=""
                          id={`whatMatters[${index}].note`}
                          name={`whatMatters[${index}].note`}
                          type="textarea"
                          placeholder="Enter here..."
                          formik={formik}
                          serverError={serverError || ""}
                          rows={4}
                          isDynamic
                          last={true}
                          showIcon
                          value={entry.note}
                        />
                        <div>
                          {index > 0 && (
                            <button
                              onClick={() => handleRemoveEntry(index)}
                              type="button"
                              className="text-secondary text-base font-medium flex items-center gap-[6px]"
                            >
                              <img src={Minus} alt="minus" /> Remove
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="flex justify-center items-center mt-[30px]">
                    <button
                      type="button"
                      onClick={() => {
                        handleAddEntry();
                      }}
                      className="text-secondary text-lg py-4  font-bold flex items-center justify-center  border border-[#C3C1DF] w-full bg-[#FAFAFE] dark:bg-button-dark dark:border-white dark:text-white"
                    >
                      <img src={Plus} alt="plus" className="dark:brightness-200" /> Add new
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="border border-[#E4E3ED] rounded-lg mt-[30px]">
              <div className="px-[23px] py-[30px] border-b border-b-[#E5E4FB]">
                <h2 className="text-[#000] dark:text-white  text-lg font-bold">MNA Basics</h2>
              </div>
              <div className="px-[23px] py-[30px]">
                <div className="flex flex-col gap-[30px] xl:flex-row">
                  {/* Terms */}
                  <div className="w-full xl:w-1/3">
                    <InputField
                      label="Terms"
                      id="terms"
                      name="terms"
                      type="text"
                      placeholder="Enter here..."
                      formik={formik}
                      serverError={serverError || ""}
                      showIcon
                    />
                  </div>

                  {/* Mna Value */}
                  <div className="w-full xl:w-1/3">
                    <InputField
                      label="MNA Value"
                      id="size"
                      name="size"
                      type="text"
                      placeholder="Enter here..."
                      formik={formik}
                      serverError={serverError || ""}
                      showIcon
                    />
                  </div>

                  {/* Premium */}
                  <div className="w-full xl:w-1/3">
                    <InputField
                      label="Premium"
                      id="premium"
                      name="premium"
                      type="text"
                      placeholder="Enter here..."
                      formik={formik}
                      serverError={serverError || ""}
                      showIcon
                      last={true}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-[30px] xl:flex-row mt-[30px]">
                  {/* Terms */}
                  <div className="w-full xl:w-1/3">
                    <InputField
                      label="Announced Date"
                      id="dateAnnounced"
                      name="dateAnnounced"
                      type="date"
                      placeholder="Select date"
                      serverError={serverError || ""}
                      formik={formik}
                      showIcon
                      required={true}
                    />
                  </div>

                  {/* Mna Value */}
                  <div className="w-full xl:w-1/3">
                    <InputField
                      label="Closing Date"
                      id="dateClosing"
                      name="dateClosing"
                      type="date"
                      placeholder="Select date"
                      serverError={serverError || ""}
                      formik={formik}
                      showIcon
                    />
                  </div>

                  {/* Premium */}
                  <div className="w-full xl:w-1/3">
                    <InputField
                      label="Closing Language"
                      id="closingLanguage"
                      name="closingLanguage"
                      type="text"
                      placeholder="Enter here..."
                      formik={formik}
                      serverError={serverError || ""}
                      showIcon
                      last={true}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-[30px] xl:flex-row mt-[30px]">
                  {/* Terms */}
                  <div className="w-full xl:w-1/2">
                    <InputField
                      label="Broker Closing Estimate"
                      id="brokerClosingEst"
                      name="brokerClosingEst"
                      type="date"
                      placeholder="Select date"
                      formik={formik}
                      serverError={serverError || ""}
                      showIcon
                    />
                  </div>

                  {/* Mna Value */}
                  <div className="w-full xl:w-1/2">
                    <InputField
                      label="Financing"
                      id="financing"
                      name="financing"
                      type="date"
                      serverError={serverError || ""}
                      placeholder="Select date"
                      formik={formik}
                      showIcon
                      last={true}
                    />
                  </div>
                </div>

                {/* Break Fee */}
                <div className="w-full mt-[30px]">
                  <InputField
                    label="Break Fee"
                    id="breakFeeAcquirer"
                    name="breakFeeAcquirer"
                    serverError={serverError || ""}
                    type="textarea"
                    placeholder="Enter here..."
                    formik={formik}
                    showIcon
                    rows={1}
                    last={true}
                  />
                </div>

                {/* Break Fee */}
                <div className="w-full mt-[30px]">
                  <InputField
                    label="Reverse Break Fee"
                    id="breakFeeTarget"
                    name="breakFeeTarget"
                    type="textarea"
                    placeholder="Enter here..."
                    formik={formik}
                    serverError={serverError || ""}
                    showIcon
                    rows={1}
                    last={true}
                  />
                </div>
                <div className="mt-[30px]">
                  {formik.values.outDates?.map((outDate: any, index: number) => (
                    <div
                      key={index}
                      className={`flex flex-col gap-[30px] xl:flex-row items-center ${
                        index === formik.values.outDates?.length - 1 ? " " : "mb-[30px]"
                      }`}
                    >
                      <div className="w-full xl:w-1/3">
                        <InputField
                          label="Out Date"
                          id={`outDates[${index}].dateOut`}
                          name={`outDates${index}.dateOut`}
                          type="date"
                          placeholder="Select date"
                          serverError={serverError || ""}
                          formik={formik}
                          showIcon
                          isDynamic
                          value={outDate.dateOut}
                        />
                      </div>
                      <div className="w-full xl:w-1/3">
                        <InputField
                          label="Comment"
                          id={`outDates[${index}].comment`}
                          name={`outDates[${index}].comment`}
                          type="text"
                          placeholder="Enter here..."
                          serverError={serverError || ""}
                          formik={formik}
                          showIcon
                          isDynamic
                          value={outDate.comment}
                        />
                      </div>
                      <div className="w-full xl:w-1/3">
                        {index > 0 ? (
                          <button
                            onClick={() => handleRemoveOutDates(index)}
                            type="button"
                            className="text-secondary text-base font-medium flex items-center gap-[6px]"
                          >
                            <img src={Minus} alt="minus" /> Remove
                          </button>
                        ) : (
                          <div className="flex justify-center items-center mt-[30px]">
                            <button
                              type="button"
                              onClick={handleAddOutDates}
                              className="text-secondary text-lg py-4  font-bold flex items-center justify-center  border border-[#C3C1DF] w-full bg-[#FAFAFE] dark:bg-button-dark dark:border-white dark:text-white"
                            >
                              <img src={Plus} alt="plus" className="dark:brightness-200" /> Add new
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex flex-col gap-[30px] xl:flex-row mt-[30px]">
                  <div className="w-full xl:w-1/3">
                    <InputField
                      label="Required to Litigate"
                      id="requiredToLitigate"
                      name="requiredToLitigate"
                      type="text"
                      placeholder="Enter here..."
                      formik={formik}
                      serverError={serverError || ""}
                      showIcon
                    />
                  </div>
                  <div className="w-full xl:w-1/3">
                    <InputField
                      label="Tax"
                      id="tax"
                      name="tax"
                      type="text"
                      placeholder="Enter here..."
                      formik={formik}
                      showIcon
                      serverError={serverError || ""}
                    />
                  </div>
                  <div className="w-full xl:w-1/3">
                    <InputField
                      label="Proxy Solicitors"
                      id="proxySolicitors"
                      name="proxySolicitors"
                      type="text"
                      placeholder="Enter here..."
                      formik={formik}
                      showIcon
                      serverError={serverError || ""}
                      last={true}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-[30px] xl:flex-row mt-[30px]">
                  <div className="w-full xl:w-1/3">
                    <InputField
                      label="Information Agent"
                      id="informationAgent"
                      name="informationAgent"
                      type="text"
                      placeholder="Enter here..."
                      formik={formik}
                      serverError={serverError || ""}
                      showIcon
                    />
                  </div>
                  <div className="w-full xl:w-1/3">
                    <InputField
                      label="ADR Depositary"
                      id="ADRDepositary"
                      name="ADRDepositary"
                      type="text"
                      placeholder="Enter here..."
                      formik={formik}
                      serverError={serverError || ""}
                      showIcon
                    />
                  </div>
                  <div className="w-full xl:w-1/3">
                    <InputField
                      label="Guaranteed Delivery"
                      id="guaranteedDelivery"
                      name="guaranteedDelivery"
                      type="text"
                      placeholder="Enter here..."
                      formik={formik}
                      serverError={serverError || ""}
                      showIcon
                      last={true}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-[30px] xl:flex-row mt-[30px]">
                  <div className="w-full xl:w-1/2">
                    <InputField
                      label="Appraisal Right"
                      id="appraisalRights"
                      name="appraisalRights"
                      type="text"
                      placeholder="Enter here..."
                      formik={formik}
                      serverError={serverError || ""}
                      showIcon
                    />
                  </div>
                  <div className="w-full xl:w-1/2">
                    <InputField
                      label="Specific Performance"
                      id="specificPerformance"
                      name="specificPerformance"
                      type="text"
                      placeholder="Enter here..."
                      formik={formik}
                      serverError={serverError || ""}
                      showIcon
                      last={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="border border-[#E4E3ED] rounded-lg  mt-[30px]">
              <div className="px-[23px] py-[30px] border-b border-b-[#E5E4FB]">
                <h2 className="text-[#000] dark:text-white  text-lg font-bold">Features</h2>
              </div>
              <div className="px-[23px] py-[30px]">
                <div>
                  {formik.values.features?.map((feature: any, index: number) => (
                    <div
                      key={index}
                      className={`flex flex-col gap-[30px] xl:flex-row items-center ${
                        index === formik.values.features?.length - 1 ? " " : "mb-[30px]"
                      }`}
                    >
                      <div className="w-full xl:w-1/3">
                        <AsyncSelect
                          label="Feature Name"
                          id={`features[${index}].name`}
                          name={`features[${index}].name`}
                          placeholder="Feature Name"
                          fetchOptions={fetchFeatures}
                          value={feature?.name}
                          formik={formik}
                          loading={featuresLoading}
                          error={serverError || ""}
                          noOptionsMessage="No features found"
                          onkeydown={(e) => {
                            if (e.keyCode === 13) {
                              e.preventDefault();
                              createFeatureName(e?.target?.value, index);
                            }
                          }}
                        />
                      </div>
                      <div className="w-full xl:w-1/3">
                        <InputField
                          label="Feature Value"
                          id={`features[${index}].value`}
                          name={`features[${index}].value`}
                          type="text"
                          placeholder="Enter here..."
                          formik={formik}
                          serverError={serverError}
                          showIcon
                          isDynamic={true}
                          value={feature?.value}
                        />
                      </div>
                      <div className="w-full xl:w-1/3">
                        {index > 0 ? (
                          <button
                            onClick={() => handleRemoveFeature(index)}
                            type="button"
                            className="text-secondary text-base font-medium flex items-center gap-[6px]"
                          >
                            <img src={Minus} alt="minus" /> Remove
                          </button>
                        ) : (
                          <div className="flex justify-center items-center mt-[30px]">
                            <button
                              type="button"
                              onClick={handleAddFeatures}
                              className="text-secondary text-lg py-4  font-bold flex items-center justify-center  border border-[#C3C1DF] w-full bg-[#FAFAFE] dark:bg-button-dark dark:border-white dark:text-white"
                            >
                              <img src={Plus} alt="plus" className="dark:brightness-200" /> Add new
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="border border-[#E4E3ED] rounded-lg  mt-[30px]">
              <div className="px-[23px] py-[30px] border-b border-b-[#E5E4FB]">
                <h2 className="text-[#000] dark:text-white  text-lg font-bold">Links for Merger Documents</h2>
              </div>
              <div className="px-[23px] py-[30px]">
                <div>
                  {formik.values.links?.map((link: any, index: number) => (
                    <div
                      key={index}
                      className={`flex flex-col gap-[30px] xl:flex-row items-center ${
                        index === formik.values.links?.length - 1 ? " " : "mb-[30px]"
                      }`}
                    >
                      <div className="w-full xl:w-1/3">
                        <InputField
                          label="Category"
                          id={`links[${index}].linkCategoryId`}
                          name={`links[${index}].linkCategoryId`}
                          type="select"
                          placeholder="Select"
                          serverError={serverError || ""}
                          formik={formik}
                          showIcon
                          optionList={linkCategoryList}
                          labelKey="name"
                          valueKey="id"
                          isDynamic={true}
                          value={link.linkCategoryId}
                        />
                      </div>
                      <div className="w-full xl:w-1/3">
                        <InputField
                          label="URL"
                          id={`links[${index}].link`}
                          name={`links[${index}].link`}
                          type="text"
                          placeholder="Enter here..."
                          formik={formik}
                          serverError={serverError}
                          showIcon
                          isDynamic={true}
                          value={link?.link}
                        />
                      </div>
                      <div className="w-full xl:w-1/3">
                        {index > 0 ? (
                          <button
                            onClick={() => handleRemoveLink(index)}
                            type="button"
                            className="text-secondary text-base font-medium flex items-center gap-[6px]"
                          >
                            <img src={Minus} alt="minus" /> Remove
                          </button>
                        ) : (
                          <div className="flex justify-center items-center mt-[30px]">
                            <button
                              type="button"
                              onClick={handleAddLinks}
                              className="text-secondary text-lg py-4  font-bold flex items-center justify-center  border border-[#C3C1DF] w-full bg-[#FAFAFE] dark:bg-button-dark dark:border-white dark:text-white"
                            >
                              <img src={Plus} alt="plus" className="dark:brightness-200" /> Add new
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="border border-[#E4E3ED] rounded-lg  mt-[30px]">
              <div className="px-[23px] py-[30px] border-b border-b-[#E5E4FB]">
                <h2 className="text-[#000] dark:text-white  text-lg font-bold">Conditions</h2>
              </div>
              <div className="px-[23px] py-[30px]">
                <div>
                  {formik.values.conditions?.map((condition: any, index: number) => (
                    <div
                      key={index}
                      className={` ${index === formik.values.conditions?.length - 1 ? " " : "mb-[30px]"}`}
                    >
                      <div className="flex flex-col gap-[30px] xl:flex-row items-center">
                        <div className="w-full xl:w-1/3">
                          <InputField
                            label="Condition"
                            id={`conditions[${index}].conditionCategoryId`}
                            name={`conditions[${index}].conditionCategoryId`}
                            type="select"
                            placeholder="Select"
                            serverError={serverError || ""}
                            formik={formik}
                            showIcon
                            optionList={conditionCategoryList}
                            labelKey="name"
                            valueKey="id"
                            isDynamic={true}
                            value={condition.conditionCategoryId}
                            showAdditionalComment
                          />
                        </div>
                        {Number(condition.conditionCategoryId) === 15 && (
                          <div className="w-full xl:w-1/3">
                            <InputField
                              label="Condition Comment"
                              id={`conditions[${index}].categoryOtherComment`}
                              name={`conditions[${index}].categoryOtherComment`}
                              type="text"
                              placeholder="Comment"
                              serverError={serverError || ""}
                              formik={formik}
                              showIcon
                              isDynamic={true}
                              value={condition.categoryOtherComment}
                            />
                          </div>
                        )}
                        <div className="w-full xl:w-1/3">
                          <InputField
                            label="Filed"
                            id={`conditions[${index}].filed`}
                            name={`conditions[${index}].filed`}
                            type="date"
                            placeholder="Select date"
                            formik={formik}
                            serverError={serverError || ""}
                            showIcon
                            isDynamic={true}
                            value={condition.filed}
                          />
                        </div>
                        <div className="w-full xl:w-1/3">
                          <InputField
                            label="Expected /Confirmed"
                            id={`conditions[${index}].expectedConfirmed`}
                            name={`conditions[${index}].expectedConfirmed`}
                            type="date"
                            last={true}
                            placeholder="Select date"
                            serverError={serverError || ""}
                            formik={formik}
                            showIcon
                            isDynamic={true}
                            value={condition.expectedConfirmed}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col gap-[30px] xl:flex-row items-center mt-[30px]">
                        <div className="w-full xl:w-1/3">
                          <InputField
                            label="Status"
                            id={`conditions[${index}].statusId`}
                            name={`conditions[${index}].statusId`}
                            type="select"
                            placeholder="Select"
                            serverError={serverError || ""}
                            formik={formik}
                            showIcon
                            optionList={conditionStatusList}
                            labelKey="status"
                            valueKey="id"
                            isDynamic={true}
                            value={condition.statusId}
                          />
                        </div>
                        <div className="w-full xl:w-1/3">
                          <InputField
                            label="Commentary"
                            id={`conditions[${index}].commentary`}
                            name={`conditions[${index}].commentary`}
                            type="text"
                            placeholder="Enter here..."
                            formik={formik}
                            serverError={serverError || ""}
                            showIcon
                            isDynamic={true}
                            value={condition?.commentary}
                          />
                        </div>
                        <div className="w-full xl:w-1/3">
                          {index > 0 ? (
                            <button
                              onClick={() => handleRemoveCondition(index)}
                              type="button"
                              className="text-secondary text-base font-medium flex items-center gap-[6px]"
                            >
                              <img src={Minus} alt="minus" /> Remove
                            </button>
                          ) : (
                            <div className="flex justify-center items-center mt-[30px]">
                              <button
                                type="button"
                                onClick={handleAddConditions}
                                className="text-secondary text-lg py-4  font-bold flex items-center justify-center  border border-[#C3C1DF] w-full bg-[#FAFAFE] dark:bg-button-dark dark:border-white dark:text-white"
                              >
                                <img src={Plus} alt="plus" className="dark:brightness-200" /> Add new
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="border border-[#E4E3ED] rounded-lg  mt-[30px]">
              <div className="px-[23px] py-[30px] border-b border-b-[#E5E4FB]">
                <h2 className="text-[#000] dark:text-white  text-lg font-bold">File</h2>
              </div>
              <div className="px-[23px] py-[30px]">
                <FileUpload formik={formik} />
              </div>
            </div>
            <div className="flex justify-end gap-[30px] mt-[80px]">
              <div>
                <button
                  type="button"
                  className="py-[18px] border border-secondary text-secondary text-base font-medium rounded-full px-[116px]"
                  onClick={() => navigate("/old-mnas")}
                >
                  Cancel
                </button>
              </div>
              <div>
                <button
                  type="submit"
                  className="py-[18px] border border-secondary text-[#fff] bg-primary-default text-base font-medium rounded-full px-[103px]"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {loading && <Loader loading={loading} />}
    </React.Fragment>
  );
};

export default OldAddEditMna;
