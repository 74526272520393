// Components
import AsyncSelect from "../../Async-Select";
import InputField from "../../Common-inputs";
import DeleteTooltip from "../../Delete-Tooltip";
// Types
import { StepBase } from "..";
import { useState } from "react";
import { FeatureControllerApi } from "../../../openapi";
import { apiConfig } from "../../ConfigurationApi/Configuration";

interface StepFourProps extends StepBase {
  Minus: string;
  Plus: string;
}

export default function StepFour(props: StepFourProps) {
  const {
    association,
    deleteID,
    deleteModal,
    formik,
    handleRemoveAssociation,
    Minus,
    Plus,
    serverError,
    setAssociation,
    setDeleteID,
    setDeleteModal,
  } = props;

  const handleAddOutDates = () => {
    formik.setValues({
      ...formik.values,
      outDates: [
        ...formik.values.outDates,
        {
          dateOut: null,
          comment: "",
          mnaId: -1,
        },
      ],
    });
  };

  return (
    <>
      {/* MNA Basics */}
      <div className="border border-[#E4E3ED] rounded-lg dark:bg-table-dark bg-table-light">
        <div className="px-[23px] py-[30px]">
          <div className="flex flex-col gap-[30px] xl:flex-row">
            {/* Terms */}
            <div className="w-full xl:w-1/3">
              <InputField
                label="Terms"
                id="terms"
                name="terms"
                type="text"
                placeholder="Enter here..."
                formik={formik}
                serverError={serverError || ""}
                showIcon
              />
            </div>

            {/* Mna Value */}
            <div className="w-full xl:w-1/3">
              <InputField
                label="MNA Value"
                id="size"
                name="size"
                type="text"
                placeholder="Enter here..."
                formik={formik}
                serverError={serverError || ""}
                showIcon
              />
            </div>

            {/* Premium */}
            <div className="w-full xl:w-1/3">
              <InputField
                label="Premium"
                id="premium"
                name="premium"
                type="text"
                placeholder="Enter here..."
                formik={formik}
                serverError={serverError || ""}
                showIcon
                last={true}
              />
            </div>
          </div>
          <div className="flex flex-col gap-[30px] xl:flex-row mt-[30px]">
            {/* Terms */}
            {/* <div className="w-full xl:w-1/3">
              <InputField
                label="Announced Date"
                id="dateAnnounced"
                name="dateAnnounced"
                type="date"
                placeholder="Select date"
                serverError={serverError || ""}
                formik={formik}
                showIcon
                required={true}
              />
            </div> */}

            {/* Mna Value */}
            {/* <div className="w-full xl:w-1/3">
              <InputField
                label="Closing Date"
                id="dateClosing"
                name="dateClosing"
                type="date"
                placeholder="Select date"
                serverError={serverError || ""}
                formik={formik}
                showIcon
              />
            </div> */}

            {/* Premium */}
            <div className="w-full xl:w-full">
              <InputField
                label="Closing Language"
                id="closingLanguage"
                name="closingLanguage"
                type="text"
                placeholder="Enter here..."
                formik={formik}
                serverError={serverError || ""}
                showIcon
                last={true}
              />
            </div>
          </div>
          <div className="flex flex-col gap-[30px] xl:flex-row mt-[30px]">
            {/* Terms */}
            <div className="w-full xl:w-1/2">
              <InputField
                label="Broker Closing Estimate"
                id="brokerClosingEst"
                name="brokerClosingEst"
                type="date"
                placeholder="Select date"
                formik={formik}
                serverError={serverError || ""}
                showIcon
              />
            </div>

            {/* Mna Value */}
            <div className="w-full xl:w-1/2">
              <InputField
                label="Financing"
                id="financing"
                name="financing"
                type="date"
                serverError={serverError || ""}
                placeholder="Select date"
                formik={formik}
                showIcon
                last={true}
              />
            </div>
          </div>

          {/* Break Fee */}
          {/* <div className="w-full mt-[30px]">
            <InputField
              label="Break Fee"
              id="breakFeeAcquirer"
              name="breakFeeAcquirer"
              serverError={serverError || ""}
              type="textarea"
              placeholder="Enter here..."
              formik={formik}
              showIcon
              rows={1}
              last={true}
            />
          </div> */}

          {/* Break Fee */}
          {/* <div className="w-full mt-[30px]">
            <InputField
              label="Reverse Break Fee"
              id="breakFeeTarget"
              name="breakFeeTarget"
              type="textarea"
              placeholder="Enter here..."
              formik={formik}
              serverError={serverError || ""}
              showIcon
              rows={1}
              last={true}
            /> */}
          {/* </div> */}
          <div className="mt-[30px]">
            <div className={`flex flex-col gap-[30px] xl:flex-row items-center`}></div>
            {formik.values.outDates?.map((outDate: any, index: number) => (
              <div
                key={index}
                className={`flex flex-col gap-[30px] xl:flex-row items-center  relative${
                  index === formik.values.outDates?.length - 1 ? " " : "mb-[30px]"
                }`}
              >
                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Out Date"
                    id={`outDates[${index}].dateOut`}
                    name={`outDates${index}.dateOut`}
                    type="date"
                    placeholder="Select date"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    isDynamic
                    value={outDate.dateOut}
                  />
                </div>
                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Comment"
                    id={`outDates[${index}].comment`}
                    name={`outDates[${index}].comment`}
                    type="text"
                    placeholder="Enter here..."
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    isDynamic
                    value={outDate.comment}
                  />
                </div>
                <div className="w-full xl:w-1/3 relative">
                  {index > 0 ? (
                    <>
                      <button
                        onClick={() => {
                          setDeleteID(index);
                          setAssociation("outDates");
                          setDeleteModal(true);
                        }}
                        type="button"
                        className="text-secondary text-base font-medium flex items-center gap-[6px]"
                      >
                        <img src={Minus} alt="minus" /> Remove
                      </button>
                      {deleteID === index && association === "outDates" && (
                        <DeleteTooltip
                          className="bottom-10"
                          tooltip={deleteModal}
                          setTooltip={setDeleteModal}
                          onDeleteItem={handleRemoveAssociation}
                          top
                        />
                      )}
                    </>
                  ) : (
                    <div className="flex justify-center items-center mt-[30px]">
                      <button
                        type="button"
                        onClick={handleAddOutDates}
                        className="text-secondary text-lg py-4  font-bold flex items-center justify-center  border border-[#C3C1DF] w-full bg-[#FAFAFE] dark:bg-button-dark dark:text-white"
                      >
                        <img src={Plus} alt="plus" className="dark:brightness-200" /> Add new
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-[30px] xl:flex-row mt-[30px]">
            <div className="w-full xl:w-full">
              <InputField
                label="Required to Litigate"
                id="requiredToLitigate"
                name="requiredToLitigate"
                type="text"
                placeholder="Enter here..."
                formik={formik}
                serverError={serverError || ""}
                showIcon
              />
            </div>
            {/* <div className="w-full xl:w-1/3">
              <InputField
                label="Tax"
                id="tax"
                name="tax"
                type="text"
                placeholder="Enter here..."
                formik={formik}
                showIcon
                serverError={serverError || ""}
              />
            </div> */}
            {/* <div className="w-full xl:w-1/3">
              <InputField
                label="Proxy Solicitors"
                id="proxySolicitors"
                name="proxySolicitors"
                type="text"
                placeholder="Enter here..."
                formik={formik}
                showIcon
                serverError={serverError || ""}
                last={true}
              />
            </div> */}
            {/* </div> */}
            {/* <div className="flex flex-col gap-[30px] xl:flex-row mt-[30px]">
            <div className="w-full xl:w-1/3">
              <InputField
                label="Information Agent"
                id="informationAgent"
                name="informationAgent"
                type="text"
                placeholder="Enter here..."
                formik={formik}
                serverError={serverError || ""}
                showIcon
              />
            </div> */}
            {/* <div className="w-full xl:w-1/3">
              <InputField
                label="ADR Depositary"
                id="ADRDepositary"
                name="ADRDepositary"
                type="text"
                placeholder="Enter here..."
                formik={formik}
                serverError={serverError || ""}
                showIcon
              />
            </div> */}
            {/* <div className="w-full xl:w-1/3">
              <InputField
                label="Guaranteed Delivery"
                id="guaranteedDelivery"
                name="guaranteedDelivery"
                type="text"
                placeholder="Enter here..."
                formik={formik}
                serverError={serverError || ""}
                showIcon
                last={true}
              />
            </div> */}
          </div>
          <div className="flex flex-col gap-[30px] xl:flex-row mt-[30px]">
            <div className="w-full xl:w-1/2">
              <InputField
                label="Appraisal Right"
                id="appraisalRights"
                name="appraisalRights"
                type="text"
                placeholder="Enter here..."
                formik={formik}
                serverError={serverError || ""}
                showIcon
              />
            </div>
            <div className="w-full xl:w-1/2">
              <InputField
                label="Specific Performance"
                id="specificPerformance"
                name="specificPerformance"
                type="text"
                placeholder="Enter here..."
                formik={formik}
                serverError={serverError || ""}
                showIcon
                last={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
