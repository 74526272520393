// React
import { useState } from "react";
// Components
import InputField from "../../Common-inputs";
import AsyncSelect from "../../Async-Select";
import DeleteTooltip from "../../Delete-Tooltip";
// OpenAPI
import { FeatureControllerApi } from "../../../openapi";
// Config
import { apiConfig } from "../../ConfigurationApi/Configuration";
// Types
import { StepBase } from "..";

interface StepFiveProps extends StepBase {
  Minus: string;
  linkCategoryList: any;
  Plus: string;
  fetchFeatures: (inputValue?: string) => Promise<
    | {
        value: any;
        label: any;
      }[]
    | []
  >;
}

export default function StepFive(props: StepFiveProps) {
  const {
    association,
    deleteID,
    deleteModal,
    formik,
    handleRemoveAssociation,
    Minus,
    Plus,
    fetchFeatures,
    serverError,
    setAssociation,
    setDeleteID,
    setDeleteModal,
  } = props;
  const [featuresLoading, setFeaturesLoading] = useState<boolean>(false);
  const createFeatureName = async (inputValue: string, indexToUpdate: number) => {
    // Simulate an asynchronous call with a delay
    setFeaturesLoading(true);
    try {
      const api = new FeatureControllerApi(apiConfig());
      const response = await api.createFeature({
        version: 0,
        name: inputValue,
      });
      const createdFeatureName = response.data;
      formik.setValues({
        ...formik.values,
        features: formik.values.features.map((value: any, index: number) => {
          if (index === indexToUpdate) {
            return {
              ...value,
              name: {
                value: createdFeatureName.id,
                label: createdFeatureName.name,
              },
            };
          } else return value;
        }),
      });
      setFeaturesLoading(false);
    } catch (error) {
      console.error("Error Creating feature name:", error);
      setFeaturesLoading(false);
      return [];
    }
  };

  const handleAddFeatures = () => {
    formik.setValues({
      ...formik.values,
      features: [
        ...formik.values.features,
        {
          name: "",
          value: "",
          mnaId: -1,
        },
      ],
    });
  };

  return (
    <>
      {/* Features */}
      <div className="border border-[#E4E3ED] rounded-lg  mt-[30px] dark:bg-table-dark bg-table-light">
        <div className="px-[23px] py-[30px]">
          <div>
            {formik.values.features?.map((feature: any, index: number) => (
              <div
                key={index}
                className={`flex flex-col gap-[30px] xl:flex-row items-center ${
                  index === formik.values.features?.length - 1 ? " " : "mb-[30px]"
                }`}
              >
                <div className="w-full xl:w-1/3">
                  <AsyncSelect
                    label="Feature Name"
                    id={`features[${index}].name`}
                    name={`features[${index}].name`}
                    placeholder="Feature Name"
                    fetchOptions={fetchFeatures}
                    value={feature?.name}
                    formik={formik}
                    loading={featuresLoading}
                    error={serverError || ""}
                    noOptionsMessage="No features found"
                    onkeydown={(e) => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                        createFeatureName(e?.target?.value, index);
                      }
                    }}
                  />
                </div>
                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Feature Value"
                    id={`features[${index}].value`}
                    name={`features[${index}].value`}
                    type="text"
                    placeholder="Enter here..."
                    formik={formik}
                    serverError={serverError}
                    showIcon
                    isDynamic={true}
                    value={feature?.value}
                  />
                </div>
                <div className="w-full xl:w-1/3 relative">
                  {index > 0 ? (
                    <>
                      <button
                        onClick={() => {
                          setDeleteID(index);
                          setAssociation("features");
                          setDeleteModal(true);
                        }}
                        type="button"
                        className="text-secondary text-base font-medium flex items-center gap-[6px]"
                      >
                        <img src={Minus} alt="minus" /> Remove
                      </button>
                      {deleteID === index && association === "features" && (
                        <DeleteTooltip
                          className="bottom-9"
                          tooltip={deleteModal}
                          setTooltip={setDeleteModal}
                          onDeleteItem={handleRemoveAssociation}
                          top
                        />
                      )}
                    </>
                  ) : (
                    <div className="flex justify-center items-center mt-[30px]">
                      <button
                        type="button"
                        onClick={handleAddFeatures}
                        className="text-secondary text-lg py-4  font-bold flex items-center justify-center  border border-[#C3C1DF] w-full bg-[#FAFAFE] dark:bg-button-dark dark:text-white"
                      >
                        <img src={Plus} alt="plus" className="dark:brightness-200" /> Add new
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
