// React
import React, { useState } from "react";
// Components
import AsyncSelect from "../../Async-Select";
import InputField from "../../Common-inputs";
// OpenAPI
import { TagRestContollerApi } from "../../../openapi";
// Types
import { StepBase } from "..";
import { apiConfig } from "../../ConfigurationApi/Configuration";
import { getApiData } from "../../../Controller/getApiDataConfiguration";

interface StepOneProps extends StepBase {
  mnaCategoryList: any;
  mnaSubCategoryList: any;
}

export default function StepOne(props: StepOneProps) {
  const { formik, serverError, mnaCategoryList, mnaSubCategoryList } = props;
  const [tagLoading, setMnaLoading] = useState(false);

  const fetchTags = async (inputValue: string): Promise<any[]> => {
    // Simulate an asynchronous call with a delay
    setMnaLoading(true);
    try {
      const api = new getApiData();
      setMnaLoading(false);
      return api.getTagRestContollerApi(inputValue);
    } catch (error) {
      console.error("Error fetching options:", error);
      setMnaLoading(false);
      return [];
    }
  };

  const postTag = async (tag: string) => {
    try {
      const api = new TagRestContollerApi(apiConfig());
      await api.createTag({ tag });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="border border-[#E4E3ED] rounded-lg dark:bg-table-dark bg-table-light">
      <div className="px-[23px] py-[30px]">
        <div className="flex flex-col gap-[30px] xl:flex-row">
          {/* Name */}

          <div className="w-full xl:w-2/4">
            <InputField
              label="Name"
              id="name"
              name="name"
              type="text"
              placeholder="Enter here..."
              serverError={serverError || ""}
              formik={formik}
              showIcon
              required={true}
            />
          </div>

          {/* dateAnnounced */}

          <div className="w-full xl:w-2/4">
            <InputField
              label="Date Announced"
              id="dateAnnounced"
              name="dateAnnounced"
              type="date"
              placeholder="Enter here..."
              serverError={serverError || ""}
              formik={formik}
              showIcon
              required={true}
            />
          </div>

          {/* Category */}
          <div className="w-full xl:w-2/4">
            <InputField
              label="Category"
              id="categoryId"
              name="categoryId"
              type="select"
              placeholder="Category"
              optionList={mnaCategoryList || []}
              labelKey="name"
              valueKey="id"
              serverError={serverError || ""}
              formik={formik}
              showIcon
              required={true}
            />
          </div>

          {/* Sub-Category */}
          <div className="w-full xl:w-2/4">
            <InputField
              label="Sub-Category"
              id="subCategoryId"
              name="subCategoryId"
              type="select"
              optionList={mnaSubCategoryList || []}
              labelKey="name"
              serverError={serverError || ""}
              valueKey="id"
              placeholder="Sub-Category"
              formik={formik}
              showIcon
              last={true}
            />
          </div>
        </div>

        {/* Description */}
        <div className="w-full mt-[30px] ">
          <InputField
            label="Description"
            id="description"
            name="description"
            type="textarea"
            rows={4}
            placeholder="Enter here..."
            formik={formik}
            serverError={serverError || ""}
            showIcon
            last={true}
          />
        </div>

        <div className="w-full mt-[30px]">
          <AsyncSelect
            label="Tags"
            name="tags"
            id="tags"
            placeholder="Tags"
            fetchOptions={fetchTags}
            formik={formik}
            loading={tagLoading}
            error={serverError || ""}
            onkeydown={(e) => {
              if (e.code === "Enter") {
                postTag(e.target.value);
              }
            }}
            isMulti
            last
            noOptionsMessage="No tag found"
            tags
          />
        </div>
      </div>
    </div>
  );
}
