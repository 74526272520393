import { UserProfileRestControllerApi } from "../openapi/api";
import { apiConfig } from "../Components/ConfigurationApi/Configuration";
import { AxiosResponse } from "axios";

export const getUsernameAsync = async () => {
  const api = new UserProfileRestControllerApi(apiConfig());
  const response: AxiosResponse = await api.whoAmI();
  console.log(response);
  const name = `${response?.data.profile?.nameFirst || ""} ${response?.data.profile?.nameLast || ""}`;

  localStorage.setItem("FirstLastName", name);
};
