// OpenApi
import {
  ConditionCategoryRestControllerApi,
  ConditionStatusRestControllerApi,
  Configuration,
  LinkCategoryRestControllerApi,
  MnaCategoryRestControllerApi,
  MnaRestControllerApi,
  MnaSubCategoryRestControllerApi,
} from "../../../openapi";
// Utils
import { formatAndValidateDate } from "../../../utils";

// Props
type FetchAllListProps = {
  setLoading: (value: React.SetStateAction<boolean>) => void;
  setConditionCategoryList: (value: any) => void;
  setConditionStatusList: (value: any) => void;
  setMnaCategoryList: (value: any) => void;
  setMnaSubCategoryList: (value: any) => void;
  setLinkCategoryList: (value: any) => void;
};

type FetchMnaDataProps = {
  id: string | undefined;
  fetchFeatures: (inputValue?: string) => Promise<
    | {
        value: any;
        label: any;
      }[]
    | []
  >;
  getFetchCompanyList?: () => Promise<void>
  formik: any;
  setChangedValues: React.Dispatch<any>;
};

// Api Config
const configuration = new Configuration({
  basePath: process.env.REACT_APP_API_URL,
});

let headers: any = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

configuration.baseOptions = {
  headers: headers,
};

// Function to fetch note source type options
const callFetchAllList = async (props: FetchAllListProps) => {
  const {
    setConditionCategoryList,
    setConditionStatusList,
    setLinkCategoryList,
    setLoading,
    setMnaCategoryList,
    setMnaSubCategoryList,
  } = props;
  setLoading(true);

  if (configuration?.baseOptions?.headers?.Authorization?.includes("null")) {
    headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    configuration.baseOptions = {
      headers: headers,
    };
  }
  try {
    const mnaCategory = new MnaCategoryRestControllerApi(configuration);
    const mnaSubCategory = new MnaSubCategoryRestControllerApi(configuration);
    const linkCategory = new LinkCategoryRestControllerApi(configuration);
    const conditionCategory = new ConditionCategoryRestControllerApi(configuration);
    const conditionStatus = new ConditionStatusRestControllerApi(configuration);

    const response1 = await mnaCategory.getMnaCategories("", 0, 20, undefined);
    const response2 = await mnaSubCategory.getMnaSubCategories("", 0, 20, undefined);
    const response3 = await linkCategory.getLinkCategories("", 0, 20, undefined);
    const response4 = await conditionCategory.getConditionCategories("", 0, 20, undefined);
    const response5 = await conditionStatus.getConditionStatuses("", 0, 20, undefined);

    setConditionCategoryList(
      response4.data.content?.map((item: any) => {
        return { id: item.id, name: item.name };
      })
    );
    setConditionStatusList(
      response5.data.content?.map((item: any) => {
        return { id: item.id, status: item.status };
      })
    );
    setMnaCategoryList(
      response1.data.content?.map((item: any) => {
        return { id: item.id, name: item.name };
      })
    );
    setMnaSubCategoryList(
      response2.data.content?.map((item: any) => {
        return { id: item.id, name: item.name };
      })
    );
    setLinkCategoryList(
      response3.data.content?.map((item: any) => {
        return { id: item.id, name: item.name };
      })
    );
  } catch (error) {
    console.error(error);
  }
  setLoading(false);
};

// fetchMnaData
const fetchMnaData = async (props: FetchMnaDataProps) => {
  const { fetchFeatures, getFetchCompanyList, id, formik, setChangedValues } = props;
  const api = new MnaRestControllerApi(configuration);
  const response = await api.getMnaById(Number(id));

  const featuresOptions = await fetchFeatures();
  // const CompanysOptions = await getFetchCompanyList();
  
  if (response.data) {
    const data: any = response.data;
    console.log(data)

    data.tags =
      data.tags?.map((tag: any) => {
        return { value: tag.id, label: tag.tag };
      }) || null;

    data.dateAnnounced = formatAndValidateDate(data?.dateAnnounced) ? new Date(data?.dateAnnounced) : null;

    data.dateClosing = formatAndValidateDate(data?.dateClosing) ? new Date(data?.dateClosing) : null;

    data.financing = formatAndValidateDate(data.financing);

    data.brokerClosingEst = formatAndValidateDate(data?.brokerClosingEst);

    data.categoryId = data?.categoryId;
    delete data.category;

    data.subCategoryId = data?.subCategoryId;
    delete data.subCategory;

    delete data.size;
    delete data.dateCreated;
    delete data.dateModified;

    if (data.mnaOutDates?.length) {
      data.outDates = data.mnaOutDates?.map((obj: any) => {
        if (obj?.dateOut) {
          obj.dateOut = formatAndValidateDate(obj.dateOut);
        }
        return obj;
      });
    } else {
      data.outDates = [
        {
          dateOut: null,
          comment: "",
          mnaId: -1,
        },
      ];
    }

    if (data.conditions?.length) {
      data.conditions = data.conditions.map((obj: any) => {
        if (isNaN(obj?.field)) {
          obj.filed = formatAndValidateDate(obj?.filed);
        }
        if (obj?.expectedConfirmed) {
          obj.expectedConfirmed = formatAndValidateDate(obj?.expectedConfirmed);
        }
        delete obj.lastModifiedBy;
        delete obj.dateCreated;
        delete obj.dateModified;
        delete obj.createdBy;
        return obj;
      });
    } else {
      data.conditions = [
        {
          statusId: "",
          conditionCategoryId: "",
          commentary: "",
          filed: null,
          expectedConfirmed: null,
          mnaId: -1,
        },
      ];
    }

    if (!data.whatMatters?.length) {
      data.whatMatters = [{ mnaId: -1, note: "" }];
    }

    if (!data.links?.length) {
      data.links = [
        {
          linkCategoryId: "",
          link: "",
          mnaId: -1,
        },
      ];
    }

    if (data.features?.length > 0) {
      data.features = data.features.map((element: any) => ({
        ...element,
        name: featuresOptions.find((feature) => feature.value === element.featureId),
      }));
    } else {
      data.features = [
        {
          name: "",
          value: "",
          mnaId: -1,
        },
      ];
    }

    if (!data.tags?.length) {
      data.tags = [];
    }

    delete data.mnaOutDates;
    delete data.outDate;

    formik.setValues(data);
    setChangedValues(data);
  }
};

export { callFetchAllList, fetchMnaData };
