// React
import React, { useCallback, useEffect, useState } from "react";
// Third party
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
// OpenAPI
import { apiConfig } from "../Components/ConfigurationApi/Configuration";
import { CompanyRestControllerApi, MultiStepRestControllerApi, NoteSourceTypeRestControllerApi } from "../openapi";
import { getApiData } from "../Controller/getApiDataConfiguration";
// Components
import { callFetchAllList, fetchMnaData } from "../Components/Mna-Form/helpers";
import Loader from "../Components/Loader";
import MnaForm, { Steps } from "../Components/Mna-Form";
// Helpers
import { processArrayData, processArrayDataGeneral } from "../helpers/processArrayData";
import { structureFormData } from "../helpers/structureFormData";
// Types
import { FormProps } from "../interfaces/pages/AddEditMna";
// Images
import Keyboard from "../Assets/svg/keyboard2.svg";
import Minus from "../Assets/svg/minus.svg";
import Plus from "../Assets/svg/pink-plus.svg";
import { expiredTokenValidation } from "../api/expiredTokenValidation";
import { Sort } from "../interfaces/pages/variedInterfaces";
import { getSortArray } from "../helpers/getSortArray";

// Define initial form values
const initialValues: FormProps = {
  name: "",
  categoryId: null,
  subCategoryId: null,
  description: "",
  acquirer: "",
  target: "",
  tickerAcquirer: "",
  tickerTarget: "",
  sectorAcquirer: "",
  sectorTarget: "",
  incorpAcquirer: "",
  incorpTarget: "",
  hqAcquirer: "",
  hqTarget: "",
  geoExposureAcquirer: "",
  geoExposureTarget: "",
  financialAdvisorForAcquirer: "",
  financialAdvisorForTarget: "",
  legalCounselForAcquirer: "",
  legalCounselForTarget: "",
  whatMatters: [{ note: "", mnaId: -1 }],
  premium: "",
  terms: "",
  tags: [],
  files: null,
  dateAnnounced: null,
  dateClosing: null,
  closingLanguage: "",
  brokerClosingEst: "",
  financing: "",
  breakFeeAcquirer: "",
  breakFeeTarget: "",
  outDates: [
    {
      dateOut: null,
      comment: "",
      mnaId: -1,
    },
  ],
  requiredToLitigate: "",
  appraisalRights: "",
  specificPerformance: "",
  links: [
    {
      linkCategoryId: "",
      link: "",
      mnaId: -1,
    },
  ],
  features: [
    {
      name: "",
      value: "",
      mnaId: -1,
    },
  ],
  conditions: [
    {
      statusId: "",
      conditionCategoryId: "",
      commentary: "",
      filed: null,
      expectedConfirmed: null,
      categoryOtherComment: "",
      mnaId: -1,
    },
  ],
};

const AddEditMna = () => {
  const [serverError, setServerError] = useState<any>(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [mnaCategoryList, setMnaCategoryList] = useState<any>([]);
  const [mnaSubCategoryList, setMnaSubCategoryList] = useState<any>([]);
  const [linkCategoryList, setLinkCategoryList] = useState<any>([]);
  const [conditionCategoryList, setConditionCategoryList] = useState<any>([]);
  const [conditionStatusList, setConditionStatusList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [changedValues, setChangedValues] = useState<any>({});
  const [featuresLoading, setFeaturesLoading] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<Steps>(0);
  const [formId, setFormId] = useState<string | number | undefined>();
  const [errorsByPage, setErrorsByPage] = useState({ [activeStep]: {} });
  let formIdResponse: string | number | undefined;
  const [deleteID, setDeleteID] = useState<number>();
  const [deleteModal, setDeleteModal] = useState(false);
  const [association, setAssociation] = useState("");
  const [sourceType, setSourceType] = useState<any>([]);

  // Fetch note source type options on component mount
  useEffect(() => {
    fetchAllList();
    fetchNoteSourceType();
  }, []);

  // Function to fetch note source type options
  const fetchAllList = async () => {
    callFetchAllList({
      setConditionCategoryList,
      setConditionStatusList,
      setLinkCategoryList,
      setLoading,
      setMnaCategoryList,
      setMnaSubCategoryList,
    });
  };

  const fetchNoteSourceType = async () => {
    try {
      const api = new NoteSourceTypeRestControllerApi(apiConfig());
      const response = await api.getNoteSourceType();
      setSourceType(response.data.content);
    } catch (error) {
      expiredTokenValidation(error);
    }
  };

  const fetchFeatures = async (
    inputValue?: string
  ): Promise<
    | {
      value: any;
      label: any;
    }[]
    | []
  > => {
    // Simulate an asynchronous call with a delay
    setFeaturesLoading(true);
    try {
      const api = new getApiData();
      setFeaturesLoading(false);
      return api.getFeatureControllerApi();
    } catch (error) {
      console.error("Error fetching options:", error);
      setFeaturesLoading(false);
      expiredTokenValidation(error);

      return [];
    }
  };

  const [search, setSearch] = useState("");
  const [companyList, setCompanyList] = useState<any>([]);

  useEffect(() => {
    if (!id) return;
    fetchMnaData({
      fetchFeatures,
      formik,
      id,
      setChangedValues,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  async function onSubmitForm() {
    const data: any = { ...formik.values, version: 0 };

    if (data.whatMatters.length > 0) {
      const whatMatters = processArrayDataGeneral(data, changedValues, "whatMatters", id);
      if (id) {
        data.whatMatters = whatMatters;
      } else {
        delete whatMatters.edit;
        delete whatMatters.delete;
        data.whatMatters = whatMatters?.add?.length > 0 ? whatMatters : null;
      }
    } else {
      const whatMatters: any = { add: null, delete: [], edit: null };
      whatMatters.delete = changedValues.whatMatters?.map((item: any) => item.id);
      data.whatMatters = id ? whatMatters : null;
    }

    if (data.links.length > 0) {
      const links = processArrayDataGeneral(data, changedValues, "links", id);

      if (id) {
        data.links = links;
      } else {
        delete links.edit;
        delete links.delete;
        data.links = links?.add?.length > 0 ? links : null;
      }

      if (data.features.length > 0) {
        const features = processArrayData(data, changedValues, "features", id);

        if (id) {
          data.features = features;
        } else {
          delete features.edit;
          delete features.delete;
          data.features = features?.add?.length > 0 ? features : null;
        }
      }
    } else {
      const features: any = { add: null, delete: [], edit: null };
      features.delete = changedValues.features?.map((item: any) => item.id);
      data.features = id ? features : null;
    }

    if (data.conditions.length > 0) {
      const conditions = processArrayDataGeneral(data, changedValues, "conditions", id);

      if (id) {
        data.conditions = conditions;
      } else {
        delete conditions.edit;
        delete conditions.delete;
        data.conditions = conditions?.add?.length > 0 ? conditions : null;
      }
    } else {
      const conditions: any = { add: null, delete: [], edit: null };
      conditions.delete = changedValues.conditions?.map((item: any) => item.id);
      data.conditions = id ? conditions : null;
    }

    if (data.outDates.length > 0) {
      const outDates = processArrayDataGeneral(data, changedValues, "outDates", id);

      if (id) {
        data.outDates = outDates;
      } else {
        delete outDates.edit;
        delete outDates.delete;
        data.outDates = outDates?.add?.length > 0 ? outDates : null;
      }
    } else {
      const outDates: any = { add: null, delete: [], edit: null };
      outDates.delete = changedValues.outDates?.map((item: any) => item.id);
      data.outDates = id ? outDates : null;
    }

    if (data.tags?.length > 0) {
      const tags = data.tags.reduce(
        (result: any, obj: any) => {
          if (!changedValues.tags?.includes(obj)) {
            result.add = result.add || [];
            result.add = [...result.add, { tag: obj.label }];
          }
          return result;
        },
        { add: null, delete: null }
      );

      changedValues.tags?.forEach((item: any) => {
        const deletedObj = data.tags?.map((obj: any) => {
          if (obj?.value === item.value) {
            return obj.value;
          }
        });
        if (!deletedObj.includes(item.value)) {
          tags.delete = tags.delete || [];
          tags.delete = [...tags.delete, item.value];
        }
      });
      if (id) {
        data.tags = tags;
      } else {
        delete tags.delete;
        data.tags = tags?.add?.length > 0 ? tags : null;
      }
    } else {
      const tags: any = { add: null, delete: [] };
      tags.delete = changedValues.tags?.map((item: any) => item.value);
      data.tags = id ? tags : null;
    }
    try {
      // API call
      const formDataForActiveStep = structureFormData(data, activeStep);
      const api = new MultiStepRestControllerApi(apiConfig());
      let body = formId ? { formId, formJson: formDataForActiveStep.data } : { formJson: formDataForActiveStep.data };
      if (id) {
        const response = await api.updateMultiStepFormGetObject("mnas", Number(id), activeStep + 1, body);
        formIdResponse = response?.data?.formId;
        setFormId(response?.data?.formId);
      } else {
        const response = await api.createMultiStepFormGetObject("mnas", activeStep + 1,
          body,
        );
        const formId = response.data.formId;
        formIdResponse = formId;
        setFormId(formId);
      }
      // setActiveStep((prev) => (prev + 1) as Steps);
      setErrorsByPage((prev) => {
        return { ...prev, [activeStep]: {} };
      });

      return true;
    } catch (error: any) {
      if (error.response) {
        if (error.response.data?.exception && !error.response.data?.fieldErrors?.length) {
          // toast.custom((t) => <CustomErrorModal visible={t.visible} error={error} />, {
          //   duration: 500,
          // });
        }
        if (error.response.data?.fieldErrors?.length) {
          const errorObject: any = error.response.data?.fieldErrors.reduce(
            (accumulator1: any, error: any) => {
              if (error.field.includes("[")) {
                const parts = error.field.split(/\.|\[|\]/);
                const name = parts[0];
                const index = parts[2];
                const keyName = parts[4];

                const fieldName = formik.values[name];
                const obj = fieldName.findIndex((item: any) => item.id === Number(index));

                if (obj !== -1) {
                  const value = formik.values[name][obj][keyName];

                  if (value === "" || value === null) {
                    accumulator1 = {
                      ...accumulator1,
                      [`${name}[${obj}].${keyName}`]: error.defaultMsg,
                    };
                  }
                } else {
                  const indicesWithoutId = fieldName
                    .map((obj: any, index: any) => (obj.hasOwnProperty("id") ? -1 : index))
                    .filter((index: any) => index !== -1);

                  indicesWithoutId.forEach((item: any) => {
                    const obj = formik.values[name][item];
                    const isValuesEmpty = Object.keys(obj).every(
                      (key) => key === "mnaId" || obj[key] === "" || obj[key] === null
                    );

                    if (!isValuesEmpty && (obj[keyName] === "" || obj[keyName] === null)) {
                      accumulator1 = {
                        ...accumulator1,
                        [`${name}[${item}].${keyName}`]: error.defaultMsg,
                      };
                    }
                  });
                }
              }
              accumulator1[error.field] = error.defaultMsg;

              return accumulator1;
            },
            {} as any // Set initial value for accumulator
          );
          setServerError(errorObject);
          formik.setErrors(errorObject);
          setErrorsByPage((prev) => {
            return { ...prev, [activeStep]: errorObject };
          });
        }
      }
      return false;
    }
  }

  // Formik hook for form management
  const formik: any = useFormik({
    initialValues,
    onSubmit: async (values: any, { setSubmitting }) => {
      // const error = { response: { data: { exception: "Invalid form, please fill missing fields" } } };
      let flag = false;

      Object.keys(errorsByPage).forEach((key) => {
        if (Object.keys(errorsByPage[key as any]).length > 0) {
          flag = true;
          return;
        }
      });

      if (flag) {
        return;
      }

      try {
        // API call
        const api = new MultiStepRestControllerApi(apiConfig());
        const response = await onSubmitForm();
        if (!response) return;

        if (id) await api.updateMultiStepFormGetId("mnas", Number(id), { formId: formId || formIdResponse });
        else await api.createMultiStepFormGetId("mnas", { formId: formId || formIdResponse });

        navigate("/mnas");
      } catch (error: any) {
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleRemoveAssociation = () => {
    formik.setValues({
      ...formik.values,
      [association]: formik.values[association].filter((_: any, index: number) => index !== Number(deleteID)),
    });
    setDeleteModal(false);
  };

  // const handleUploadCallBack = async (responses: any) => {};

  return (
    <React.Fragment>
      <div className={`${loading && "opacity-20"}`}>
        {/* Breadcrumbs component */}

        <div className="my-[10px]">
          <form onSubmit={formik.handleSubmit}>
            <MnaForm
              Keyboard={Keyboard}
              Minus={Minus}
              Plus={Plus}
              conditionCategoryList={conditionCategoryList}
              conditionStatusList={conditionStatusList}
              formik={formik}
              linkCategoryList={linkCategoryList}
              mnaCategoryList={mnaCategoryList}
              mnaSubCategoryList={mnaSubCategoryList}
              serverError={serverError}
              featuresLoading={featuresLoading}
              setFeaturesLoading={setFeaturesLoading}
              fetchFeatures={fetchFeatures}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              onSubmitForm={onSubmitForm}
              association={association}
              deleteID={deleteID}
              deleteModal={deleteModal}
              handleRemoveAssociation={handleRemoveAssociation}
              setAssociation={setAssociation}
              setDeleteID={setDeleteID}
              setDeleteModal={setDeleteModal}
              sourceType={sourceType}
            />
          </form>
        </div>
      </div>
      {loading && <Loader loading={loading} />}
    </React.Fragment>
  );
};

export default AddEditMna;