// React
import React, { useEffect, useState } from "react";
// Third party
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
// Components
import AsyncSelect from "../Components/Async-Select";
import Breadcrumbs from "../Components/Breadcrumbs/Breadcrumbs";
import FileUpload from "../Components/File-Upload";
import InputField from "../Components/Common-inputs";
import Loader from "../Components/Loader";
import TextEditor from "../Components/TextEditor";
// OpenAPI
import { apiConfig } from "../Components/ConfigurationApi/Configuration";
import { CompanyRestControllerApi, FundamentalRestControllerApi, TagRestContollerApi } from "../openapi";
import { expiredTokenValidation } from "../api/expiredTokenValidation";
import { getApiData } from "../Controller/getApiDataConfiguration";
// Types
import { ErrorFileData } from "../interfaces/pages/Errors";
import { SearchResult, SearchResultTag } from "../interfaces/pages/variedInterfaces";

// Define initial form values
const initialValues = {
  name: "",
  sectorId: "",
  ticker: "",
  incorp: "",
  hq: "",
  geoExposure: "",
  tags: [],
  whatMatters: "",
  businessDesc: "",
  companyHistory: "",
  companyBasics: "",
  driversValuation: "",
  valuationHistory: "",
  companyId: "",
  files: null,
};

const AddEditFundamental = () => {
  const [serverError, setServerError] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [sectorList, setSectorList] = useState<any>([]);
  const [tagLoading, setTagLoading] = useState(false);
  const [changedValues, setChangedValues] = useState<any>({});

  // Fetch Sector List options on component mount
  useEffect(() => {
    fetchSectorList();
  }, []);

  // Function to fetch Sector List options
  const fetchSectorList = async () => {
    try {
      const api = new getApiData();
      const response = await api.getSectorRestControllerApi(id);
      const filteredOptions = response?.data?.content!.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
      setSectorList(filteredOptions);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!id) return;
    fetchFundamentalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchFundamentalData = async () => {
    setLoading(true);
    try {
      const api = new FundamentalRestControllerApi(apiConfig());

      const response = await api.getFundamentalById(Number(id));
      if (response.data) {
        const data = response.data as any;
        data.tags =
        data.tags?.map((tag: any) => {
          return { value: tag.id, label: tag.tag };
        }) || null;
        formik.setValues(data);
        setChangedValues(data);
      }
    } catch (error) {
      expiredTokenValidation(error);
    }
    setLoading(false);
  };

  // Formik hook for form management
  const formik: any = useFormik({
    initialValues,
    onSubmit: async (values: any, { setSubmitting, setErrors }) => {
      setLoading(true);
      const data: any = {
        ...formik.values,
        version: 0,
        mnaId: values.mnaId?.value,
        companyId: values.companyId?.value,
      };

      if (data.tags?.length > 0) {
        const tags = data.tags.reduce(
          (result: any, obj: any) => {
            if (!changedValues.tags?.includes(obj)) {
              result.add = result.add || [];
              result.add = [...result.add, { tag: obj.label }];
            }
            return result;
          },
          { add: null, delete: null }
        );

        changedValues.tags?.forEach((item: any) => {
          const deletedObj = data.tags?.map((obj: any) => {
            if (obj?.value === item.value) {
              return obj.value;
            }
          });
          if (!deletedObj.includes(item.value)) {
            tags.delete = tags.delete || [];
            tags.delete = [...tags.delete, item.value];
          }
        });
        if (id) {
          data.tags = tags;
        } else {
          delete tags.delete;
          data.tags = tags?.add?.length > 0 ? tags : null;
        }
      } else {
        const tags: any = { add: null, delete: [] };
        tags.delete = changedValues.tags?.map((item: any) => item.value);
        data.tags = id ? tags : null;
      }

      try {
        // API call
        const api = new FundamentalRestControllerApi(apiConfig());
        id ? await api.updateFundamental(Number(id), data) : await api.createFundamental(data);
        navigate("/fundamental");
      } catch (error: any) {
        if (error.response) {
          if (error.response.data?.exception && !error.response.data?.fieldErrors?.length) {
            toast.custom(
              (t) => (
                <div
                  className={`${
                    t.visible ? "animate-enter" : "animate-leave"
                  } max-w-md w-full bg-danger shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                >
                  <div className="flex-1 w-0 p-4">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 pt-0.5">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="50"
                          height="50"
                          viewBox="0 0 48 48"
                          className="dark:brightness-[4]"
                        >
                          <path
                            fill="#F78F8F"
                            d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
                          ></path>
                          <path
                            fill="#fff"
                            d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z"
                          ></path>
                          <path
                            fill="#fff"
                            d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z"
                          ></path>
                        </svg>
                      </div>
                      <div className="ml-3 flex-1 text-white">
                        <p>{error.response.data?.exception}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ),
              {
                duration: 500,
              }
            );
          }

          if (error.response.data?.fieldErrors.length) {
            const errorsObject = {} as any;
            error.response.data?.fieldErrors.forEach((error: ErrorFileData) => {
              errorsObject[error.field] = error.defaultMsg;
            });
            setErrors(errorsObject);
            setServerError(errorsObject);
          }
        } else {
          // Handle other errors
          console.error("Error submitting form:", error.message);
        }
      } finally {
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  const fetchTags = async (inputValue: string): Promise<any[]> => {
    // Simulate an asynchronous call with a delay
    setTagLoading(true);
    try {
      const api = new TagRestContollerApi(apiConfig());

      const response = await api.getTags(`${inputValue}`, undefined, undefined, undefined);

      const filteredOptions = (response.data.content as SearchResultTag[]).map((item) => ({
        value: item.id,
        label: item.tag,
      }));

      setTagLoading(false);
      return filteredOptions;
    } catch (error) {
      console.error("Error fetching options:", error);
      setTagLoading(false);
      return [];
    }
  };

  const fetchCompany = async (inputValue: string): Promise<any[]> => {
    // Simulate an asynchronous call with a delay
    setTagLoading(true);
    try {
      const api = new CompanyRestControllerApi(apiConfig());
      const response = await api.getCompanies(`name:${inputValue}`, undefined, undefined, undefined);

      const filteredOptions = (response.data.content as SearchResult[]).map((item) => ({
        value: item.id,
        label: item.name,
      }));

      setTagLoading(false);
      return filteredOptions;
    } catch (error) {
      console.error("Error fetching options:", error);
      setTagLoading(false);
      return [];
    }
  };

  return (
    <React.Fragment>
      <div className={`p-[30px] ${loading && "opacity-20"} `}>
        {/* Breadcrumbs component */}
        <Breadcrumbs breadcrumbs={[id ? "Update Fundamental" : "Add New Fundamental"]} />

        <div>
          {/* Form */}
          <form onChange={() => setServerError(null)}>
            <div className="py-6">
              <div className="mb-8 flex flex-col gap-[30px] xl:flex-row">
                {/* Name */}

                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Name"
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Name"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    bgColor={"#fff"}
                  />
                </div>

                {/* Ticker */}

                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Ticker"
                    id="ticker"
                    name="ticker"
                    type="text"
                    placeholder="Ticker"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    bgColor={"#fff"}
                  />
                </div>

                {/* Sector */}
                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Sector"
                    id="sectorId"
                    name="sectorId"
                    type="select"
                    optionList={sectorList}
                    placeholder="Sector"
                    serverError={serverError || ""}
                    formik={formik}
                    labelKey="label"
                    valueKey="value"
                    showIcon
                    bgColor={"#fff"}
                    last
                  />
                </div>
              </div>

              <div className="mb-8 flex flex-col gap-[30px] xl:flex-row">
                {/* Incorporation  */}
                <div className="w-full  xl:w-1/3">
                  <InputField
                    label="Incorporation"
                    id="incorp"
                    name="incorp"
                    type="text"
                    placeholder="Incorporation"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    bgColor={"#fff"}
                  />
                </div>

                {/* Headquarters  */}

                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Headquarters"
                    id="hq"
                    name="hq"
                    type="text"
                    placeholder="Headquarters"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    bgColor={"#fff"}
                  />
                </div>

                {/* Geo Exposure  */}

                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Geo Exposure"
                    id="geoExposure"
                    name="geoExposure"
                    type="text"
                    placeholder="Geo Exposure"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    bgColor={"#fff"}
                    last
                  />
                </div>
              </div>

              <div className="w-full mb-8">
                <AsyncSelect
                  label="Company"
                  name="companyId"
                  id="companyId"
                  placeholder="Company"
                  fetchOptions={fetchCompany}
                  formik={formik}
                  loading={tagLoading}
                  error={serverError || ""}
                  noOptionsMessage="No company found"
                  last
                  bgColor="#FFF"
                />
              </div>

              <div className="mb-8">
                <div className="relative">
                  <TextEditor
                    formik={formik}
                    error={serverError?.content}
                    id="whatMatters"
                    name="whatMatters"
                    label="What Matters"
                  />
                </div>
              </div>

              <div className="mb-8">
                <div className="relative">
                  <TextEditor
                    formik={formik}
                    error={serverError?.content}
                    id="businessDesc"
                    name="businessDesc"
                    label="Business Description"
                  />
                </div>
              </div>

              <div className="mb-8">
                <div className="relative">
                  <TextEditor
                    formik={formik}
                    error={serverError?.content}
                    id="companyHistory"
                    name="companyHistory"
                    label="Company History"
                  />
                </div>
              </div>

              <div className="mb-8">
                <div className="relative">
                  <TextEditor
                    formik={formik}
                    error={serverError?.content}
                    id="companyBasics"
                    name="companyBasics"
                    label="Company Basics"
                  />
                </div>
              </div>

              <div className="mb-8">
                <div className="relative">
                  <TextEditor
                    formik={formik}
                    error={serverError?.content}
                    id="driversValuation"
                    name="driversValuation"
                    label="Drivers / Valuation"
                  />
                </div>
              </div>

              <div className="mb-8">
                <div className="relative">
                  <TextEditor
                    formik={formik}
                    error={serverError?.content}
                    id="valuationHistory"
                    name="valuationHistory"
                    label="Valuation History"
                  />
                </div>
              </div>

              {/* Tags */}

              <div className="w-full mb-8">
                <AsyncSelect
                  label="Tags"
                  name="tags"
                  id="tags"
                  placeholder="Tags"
                  fetchOptions={fetchTags}
                  formik={formik}
                  loading={tagLoading}
                  error={""}
                  isMulti
                  bgColor="#FFF"
                  last
                  noOptionsMessage="No tag found"
                  tags
                />
              </div>

              <div className="border border-[#E4E3ED] rounded-lg  mt-[30px]">
                <div className="px-[23px] py-[30px] border-b border-b-[#E5E4FB]">
                  <h2 className="text-[#000] text-lg font-bold">File</h2>
                </div>
                <div className="px-[23px] py-[30px]">
                  <FileUpload formik={formik} />
                </div>
              </div>

              <div className="flex justify-end gap-[30px] mt-[80px]">
                <div>
                  <button
                    type="button"
                    className="py-[18px] border border-secondary text-secondary text-base font-medium rounded-full px-[116px]"
                    onClick={() => navigate("/fundamental")}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    name="Save"
                    onClick={formik.handleSubmit}
                    className="py-[18px] border border-secondary text-[#fff] bg-primary-default text-base font-medium rounded-full px-[103px]"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {loading && <Loader loading={loading} />}
    </React.Fragment>
  );
};

export default AddEditFundamental;
