import React from "react";
import ReactQuill, { Quill } from "react-quill";
import { ImageActions } from "@xeger/quill-image-actions";
import { ImageFormats } from "@xeger/quill-image-formats";
import "react-quill/dist/quill.snow.css";

Quill.register("modules/imageActions", ImageActions);
Quill.register("modules/imageFormats", ImageFormats);

interface EditorProps {
  formik: any;
  error: string[];
  required?: boolean;
  id: string;
  name: string;
  label: string;
}

const colors = [
  "#000000",
  "#e60000",
  "#ff9900",
  "#ffff00",
  "#008a00",
  "#0066cc",
  "#9933ff",
  "#ffffff",
  "#facccc",
  "#ffebcc",
  "#ffffcc",
  "#cce8cc",
  "#cce0f5",
  "#ebd6ff",
  "#bbbbbb",
  "#f06666",
  "#ffc266",
  "#ffff66",
  "#66b966",
  "#66a3e0",
  "#c285ff",
  "#888888",
  "#a10000",
  "#b26b00",
  "#b2b200",
  "#006100",
  "#0047b2",
  "#6b24b2",
  "#444444",
  "#5c0000",
  "#663d00",
  "#666600",
  "#003700",
  "#002966",
  "#3d1466",
  "custom-color",
];

const TextEditor: React.FC<EditorProps> = ({
  formik,
  error,
  required,
  name,
  id,
  label,
}) => {
  var modules = {
    imageActions: {},
    imageFormats: {},
    toolbar: [
      [{ font: [] }],
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] },
      ],
      [
        {
          color: colors,
        },
        {
          background: colors,
        },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const styles = {
    fontFamily: "Times New Roman",
    fontSize: "16px",
  };

  var formats = [
    "header",
    "height",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "color",
    "background",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "align",
    "float",
  ];

  return (
    <React.Fragment>
      <div className="flex justify-between items-center">
        <div>
          <label
            htmlFor={id}
            className="text-base  text-[#393C51]  dark:text-white  font-medium mb-2 flex gap-1"
          >
            {label}
          </label>
        </div>
        <div className="tooltip2">
          <div
            className={`bg-[#fff]  bottom-9   text-[#393C51]  dark:text-white dark:bg-primary-dark  transition-all  text-sm  z-50 tooltiptext2 w-[328px] px-[14px] pt-[12px] pb-[17px] rounded-[10px] border cursor-pointer ${
              error
                ? "border-[#DB1A21] after:border-transparent after:border-t-[#DB1A21] before:top-[97%]"
                : "border-[#4185EE] after:border-transparent after:border-t-[#4185EE]  before:top-[99%]"
            }`}
          >
            {error
              ? error
              : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."}
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            className="dark:brightness-[4]"
          >
            <path
              d="M9 0.5C4.02571 0.5 0 4.52525 0 9.5C0 14.4742 4.02525 18.5 9 18.5C13.9743 18.5 18 14.4747 18 9.5C18 4.52571 13.9747 0.5 9 0.5ZM9 17.2442C4.72985 17.2442 1.25582 13.7702 1.25582 9.5C1.25582 5.22982 4.72985 1.75582 9 1.75582C13.2702 1.75582 16.7442 5.22982 16.7442 9.5C16.7442 13.7702 13.2702 17.2442 9 17.2442Z"
              fill={` ${error ? "#DB1A21" : "#1E87F0"}`}
            />
            <path
              d="M8.73354 11.8881C8.23597 11.8881 7.83319 12.3027 7.83319 12.8002C7.83319 13.286 8.22413 13.7125 8.73354 13.7125C9.24295 13.7125 9.6457 13.286 9.6457 12.8002C9.6457 12.3027 9.23107 11.8881 8.73354 11.8881ZM8.88752 4.98145C7.28819 4.98145 6.55371 5.92922 6.55371 6.56893C6.55371 7.03095 6.94465 7.24421 7.2645 7.24421C7.90424 7.24421 7.64363 6.33197 8.85198 6.33197C9.44429 6.33197 9.9182 6.59262 9.9182 7.13758C9.9182 7.77728 9.25477 8.14452 8.86383 8.47622C8.52025 8.77234 8.07011 9.2581 8.07011 10.2769C8.07011 10.8929 8.23597 11.0706 8.72166 11.0706C9.30212 11.0706 9.4206 10.81 9.4206 10.5849C9.4206 9.96889 9.43245 9.61349 10.084 9.10408C10.4039 8.85531 11.4108 8.04971 11.4108 6.93613C11.4108 5.82256 10.4039 4.98145 8.88752 4.98145Z"
              fill={` ${error ? "#DB1A21" : "#1E87F0"}`}
            />
          </svg>{" "}
        </div>
      </div>
      <ReactQuill
        theme="snow"
        className="dark:placeholder:text-white"
        id={name}
        modules={modules}
        formats={formats}
        value={formik.values[name]}
        placeholder="write your content ...."
        onChange={(value) => formik.setFieldValue(name, value)}
        style={{
          borderLeft: `1px solid ${error ? "#DB1A21" : "#C3C1DF"}`,
          borderRight: `1px solid ${error ? "#DB1A21" : "#C3C1DF"}`,
          borderBottom: `1px solid ${error ? "#DB1A21" : "#C3C1DF"}`,
          borderTop: `1px solid ${error ? "#DB1A21" : "transparent"}`,
          borderRadius: "20px",
          ...styles,
        }}
      ></ReactQuill>
    </React.Fragment>
  );
};

export default TextEditor;
