// Third party
import { Navigate, useLocation } from "react-router-dom";
// Components
import Navbar from "../Layouts/Navbar";
import VersionModal from "../Components/Version-Model";
// Theme
import { useTheme } from "../App";
// Package
import packageJson from "../../package.json";

interface PrivateAuthenticatedProps {
  children: React.ReactNode;
}

const PrivateAuthenticated: React.FC<PrivateAuthenticatedProps> = ({ children }) => {
  const location = useLocation();
  const version = packageJson.version;
  const token = localStorage.getItem("token");
  const theme = useTheme();

  if (!token) {
    localStorage.setItem("redirectUrl", location.pathname);
    return <Navigate to="/" replace={true} />;
  }

  return (
    <div className={`${theme.theme}`}>
      <div className={`overflow-hidden min-h-screen bg-primary-light dark:bg-primary-dark dark:text-white`}>
        {version !== "0.1.0" && <VersionModal />}
        <Navbar />
        <div className="relative h-[calc(100vh-98px)] overflow-y-scroll ">
          <div className="absolute top-0 w-full">{children}</div>
        </div>
      </div>
    </div>
  );
};

const PublicAuthenticated = ({ children }: any) => {
  const token = localStorage.getItem("token");
  if (token) return <Navigate to="/dashboard" />;
  return children;
};

export { PrivateAuthenticated, PublicAuthenticated };
