// Components
import InputField from "../../Common-inputs";
import DeleteTooltip from "../../Delete-Tooltip";

// Types
import { StepBase } from "..";

interface StepThreeProps extends StepBase {
  Minus: string;
  Plus: string;
}

export default function StepThree(props: StepThreeProps) {
  const {
    association,
    deleteID,
    deleteModal,
    formik,
    handleRemoveAssociation,
    Minus,
    Plus,
    serverError,
    setAssociation,
    setDeleteID,
    setDeleteModal,
  } = props;

  // Add entry
  const handleAddEntry = () => {
    formik.setValues({
      ...formik.values,
      whatMatters: [...formik.values.whatMatters, { note: "", mnaId: -1 }],
    });
  };

  return (
    <>
      {/* <div className="border border-[#E4E3ED] rounded-lg  mt-[30px]">
        <div className="px-[23px] py-[30px]">
          <div className="flex flex-col gap-[30px] xl:flex-row"> */}
      {/* Description */}
      {/* <div className="w-full">
              <InputField
                label="Description"
                id="reviewed"
                name="reviewed"
                type="textarea"
                rows={4}
                placeholder="Enter here..."
                formik={formik}
                serverError={serverError || ""}
                showIcon
                last={true}
              />
            </div> */}
      {/* </div>
        </div>
      </div> */}

      <div className="border border-[#E4E3ED] rounded-lg dark:bg-table-dark bg-table-light">
        <div className="px-[23px] py-[30px]">
          <div>
            {/* What Matters */}
            {formik.values.whatMatters?.map((entry: any, index: number) => (
              <div key={index} className="w-full mb-6">
                <div>
                  <InputField
                    label=""
                    id={`whatMatters[${index}].note`}
                    name={`whatMatters[${index}].note`}
                    type="textarea"
                    placeholder="Enter here..."
                    formik={formik}
                    serverError={serverError || ""}
                    rows={4}
                    isDynamic
                    last={true}
                    showIcon
                    value={entry.note}
                  />
                  <div className="relative">
                    {index > 0 && (
                      <>
                        <button
                          onClick={() => {
                            setDeleteID(index);
                            setAssociation("whatMatters");
                            setDeleteModal(true);
                            // handleRemoveEntry(index)
                          }}
                          type="button"
                          className="text-secondary text-base font-medium flex items-center gap-[6px]"
                        >
                          <img src={Minus} alt="minus" /> Remove
                        </button>
                        {deleteID === index &&
                          association === "whatMatters" && (
                            <DeleteTooltip
                              tooltip={deleteModal}
                              setTooltip={setDeleteModal}
                              onDeleteItem={handleRemoveAssociation}
                            />
                          )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
            <div className="flex justify-center items-center mt-[30px]">
              <button
                type="button"
                onClick={() => {
                  handleAddEntry();
                }}
                className="text-secondary text-lg py-4  font-bold flex items-center justify-center  border border-[#C3C1DF] w-full bg-[#FAFAFE] dark:bg-button-dark dark:text-white"
              >
                <img src={Plus} alt="plus" className="dark:brightness-200" /> Add new
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
