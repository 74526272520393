import { CellProps } from "react-table";
import { Data } from "../../interfaces/pages/Columns";
import moment from "moment";
import Action from "../../Assets/svg/action.svg";
import { useNavigate } from "react-router-dom";
type NavigateFunction = ReturnType<typeof useNavigate>;

export class Columns {
  OldMnaDetails(): any {
    return [
      // Define your columns here
      {
        Header: "Date Published",
        accessor: "datePublished",
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#000817] dark:text-white   text-base font-medium text-left">
              {moment(row.original?.datePublished).format("MM/DD/YYYY")}
            </p>
          );
        },
      },
      {
        Header: "Source Type",
        accessor: "sourceType",
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <button className="bg-[#7B7B7B] text-[#FFF] text-base w-full font-normal p-[4px] px-[16px] rounded-full">
              {row.original?.sourceType}
            </button>
          );
        },
      },
      {
        Header: "Source",
        accessor: "sourceDetail",
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#000817] dark:text-white   text-base font-medium text-left truncate">
              {row.original.sourceDetail}
            </p>
          );
        },
      },
      {
        Header: "Content",
        accessor: "content",
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p
              className=" text-[#000817] dark:text-white   text-base font-medium text-left truncate"
              dangerouslySetInnerHTML={{
                __html: row.original.content,
              }}
            ></p>
          );
        },
      },
      {
        Header: "Sentiment",
        accessor: "sentiment",
        align: "right",
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#000817] dark:text-white   text-base font-medium text-right">
              {row.original.sentiment}
            </p>
          );
        },
      },
    ];
  }
  NotesList(navigate: NavigateFunction): any {
    return [
      // Define your columns here
      {
        Header: "MNA",
        accessor: "mnaName",
        align: "left",
        width: 17,
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <button
              className="text-secondary font-semibold text-base capitalize underline truncate dark:text-white"
              onClick={() => navigate(`/mna/${row.original?.mnaId}`)}
            >
              {row.original?.mnaName}
            </button>
          );
        },
      },
      {
        Header: "Title",
        accessor: "title",
        width: 25,
        Cell: ({ row }: CellProps<Data>) => {
          return <p className=" text-[#696868] dark:text-white   font-bold text-base">{row.original?.title}</p>;
        },
      },
      {
        Header: "Source",
        accessor: "sourceDetail",
        width: 15,
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#696868] dark:text-white   font-normal text-base">{row.original?.sourceDetail}</p>
          );
        },
      },
      {
        Header: "Source Type",
        accessor: "sourceType",
        align: "center",
        width: 10,
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <div className="flex items-center justify-center">
              <p className="text-[#fff] font-normal text-base bg-[#7B7B7B] rounded-full py-2 px-[35px] text-center  w-[175px]">
                {row.original?.sourceType}
              </p>
            </div>
          );
        },
      },
      {
        Header: "Author",
        accessor: "author1",
        width: 18,
        align: "center",
        Cell: ({ row }: CellProps<Data>) => {
          return <p className=" text-[#696868] dark:text-white   font-normal text-base">{row.original?.author1}</p>;
        },
      },
      {
        Header: "Date Published",
        accessor: "datePublished",
        width: 0,
        Cell: ({ row }: CellProps<Data>) => {
          return <p>{moment(row.original?.datePublished).format("MM/DD/YYYY, hh:mm A")}</p>;
        },
      },
      {
        Header: "Action",
        accessor: "id",
        width: 0,
        align: "right",
        Cell: ({ row }: CellProps<Data>) => (
          <button
            className="text-secondary font-semibold text-base capitalize underline float-right"
            onClick={() => navigate(`/update-note/${row.original.id}`)}
          >
            <div className=" rounded-full  p-3 border border-[#E6E6F9] bg-button-light cursor-pointer dark:bg-button-dark ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                className="dark:brightness-[3] brightness-0"
              >
                <g clipPath="url(#clip0_1000_6286)">
                  <path
                    d="M9.88081 2.68738L1.07744 11.4914C1.03311 11.5359 1.00152 11.5914 0.985954 11.6522L0.010151 15.5688C-0.00420054 15.6269 -0.00332103 15.6877 0.0127046 15.7454C0.0287303 15.8031 0.0593605 15.8557 0.101637 15.8981C0.166572 15.9628 0.254492 15.9992 0.346172 15.9992C0.374454 15.9992 0.402627 15.9957 0.430055 15.9888L4.34662 15.0129C4.40752 14.9976 4.46311 14.966 4.50743 14.9216L13.3116 6.11812L9.88081 2.68738ZM15.4926 1.48695L14.5126 0.507024C13.8577 -0.14793 12.7162 -0.14728 12.0619 0.507024L10.8616 1.70745L14.2922 5.13806L15.4926 3.93767C15.8197 3.61065 16 3.17534 16 2.71239C16 2.24944 15.8197 1.81414 15.4926 1.48695Z"
                    fill="#4B49AC"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1000_6286">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </button>
        ),
      },
    ];
  }
  MnaList(navigate: NavigateFunction): any {
    return [
      // Define your columns here
      {
        Header: "MNA Name",
        accessor: "name",
        width: 25,
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <button
              className="text-secondary font-semibold text-base capitalize underline truncate dark:text-white"
              onClick={() => navigate(`/mna/${row.original?.id}`)}
            >
              {row.original?.name}
            </button>
          );
        },
      },
      {
        Header: "Category",
        accessor: "categoryName",
        width: 15,
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#696868] dark:text-white   font-normal text-base">{row.original?.categoryName}</p>
          );
        },
      },
      {
        Header: "Acquirer",
        accessor: "acquirer",
        width: 15,
        Cell: ({ row }: CellProps<Data>) => {
          return <p className=" text-[#696868] dark:text-white   font-normal text-base">{row.original?.acquirer}</p>;
        },
      },
      {
        Header: "Target",
        accessor: "target",
        width: 15,
        Cell: ({ row }: CellProps<Data>) => {
          return <p className=" text-[#696868] dark:text-white   font-normal text-base">{row.original?.target}</p>;
        },
      },
      {
        Header: "Date Announced",
        accessor: "dateAnnounced",
        width: 15,
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#696868] dark:text-white   font-normal text-base">
              {row.original?.dateAnnounced === null ? "" : moment(row.original?.dateAnnounced).format("MM/DD/YYYY")}
            </p>
          );
        },
      },
      {
        Header: "Date Closing",
        accessor: "dateClosing",
        width: 15,
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#696868] dark:text-white   font-normal text-base">
              {row.original?.dateClosing === null ? "" : moment(row.original?.dateClosing).format("MM/DD/YYYY")}
            </p>
          );
        },
      },
      {
        Header: "Action",
        accessor: "id",
        width: 0,
        align: "right",
        Cell: ({ row }: CellProps<Data>) => (
          <button
            className="text-secondary font-semibold text-base capitalize underline float-right"
            onClick={() => navigate(`/update-mna/${row.original.id}`)}
          >
            <div className="rounded-full  p-3 border border-[#E6E6F9] bg-button-light dark:bg-button-dark cursor-pointer">
              <svg
                className="dark:brightness-[3.5] brightness-0"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <g clipPath="url(#clip0_1000_6286)">
                  <path
                    d="M9.88081 2.68738L1.07744 11.4914C1.03311 11.5359 1.00152 11.5914 0.985954 11.6522L0.010151 15.5688C-0.00420054 15.6269 -0.00332103 15.6877 0.0127046 15.7454C0.0287303 15.8031 0.0593605 15.8557 0.101637 15.8981C0.166572 15.9628 0.254492 15.9992 0.346172 15.9992C0.374454 15.9992 0.402627 15.9957 0.430055 15.9888L4.34662 15.0129C4.40752 14.9976 4.46311 14.966 4.50743 14.9216L13.3116 6.11812L9.88081 2.68738ZM15.4926 1.48695L14.5126 0.507024C13.8577 -0.14793 12.7162 -0.14728 12.0619 0.507024L10.8616 1.70745L14.2922 5.13806L15.4926 3.93767C15.8197 3.61065 16 3.17534 16 2.71239C16 2.24944 15.8197 1.81414 15.4926 1.48695Z"
                    fill="#4B49AC"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1000_6286">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </button>
        ),
      },
    ];
  }
  MnaDetails(): any {
    return [
      // Define your columns here
      {
        Header: "Date Published",
        accessor: "datePublished",
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#000817] dark:text-white   text-base font-medium text-left">
              {moment(row.original?.datePublished).format("MM/DD/YYYY")}
            </p>
          );
        },
      },
      {
        Header: "Source Type",
        accessor: "sourceType",
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <button className="bg-[#7B7B7B] text-[#FFF] text-base w-full font-normal p-[4px] px-[16px] rounded-full">
              {row.original?.sourceType}
            </button>
          );
        },
      },
      {
        Header: "Source",
        accessor: "sourceDetail",
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#000817] dark:text-white   text-base font-medium text-left truncate">
              {row.original.sourceDetail}
            </p>
          );
        },
      },
      {
        Header: "Content",
        accessor: "content",
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p
              className=" text-[#000817] dark:text-white   text-base font-medium text-left truncate"
              dangerouslySetInnerHTML={{
                __html: row.original.content,
              }}
            ></p>
          );
        },
      },
      {
        Header: "Sentiment",
        accessor: "sentiment",
        align: "right",
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#000817] dark:text-white   text-base font-medium text-right">
              {row.original.sentiment}
            </p>
          );
        },
      },
    ];
  }
  FundamentalList(navigate: NavigateFunction): any {
    return [
      // Define your columns here
      {
        Header: "Name",
        accessor: "name",
        width: 25,
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <button
              className="text-secondary font-semibold text-base capitalize underline truncate dark:text-white"
              onClick={() => navigate(`/fundamental/${row.original?.id}`)}
            >
              {row.original?.name}
            </button>
          );
        },
      },
      {
        Header: "Ticker",
        accessor: "ticker",
        width: 15,
      },
      {
        Header: "Sector",
        accessor: "sectorId",
        width: 15,
      },
      {
        Header: "Date Modified",
        accessor: "dateModified",
        width: 15,
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#696868] dark:text-white   font-normal text-base">
              {row.original?.dateModified === null
                ? ""
                : moment(row.original?.dateModified).format("MM/DD/YYYY, hh:mm A")}
            </p>
          );
        },
      },
      {
        Header: "Action",
        accessor: "id",
        width: 0,
        align: "right",
        Cell: ({ row }: CellProps<Data>) => (
          <button
            className="text-secondary font-semibold text-base capitalize underline float-right"
            onClick={() => navigate(`/update-fundamental/${row.original.id}`)}
          >
            <div className=" rounded-full  p-3 border border-[#E6E6F9] bg-button-light cursor-pointer dark:bg-button-dark ">
              <svg
                className="dark:brightness-[3] brightness-0"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <g clipPath="url(#clip0_1000_6286)">
                  <path
                    d="M9.88081 2.68738L1.07744 11.4914C1.03311 11.5359 1.00152 11.5914 0.985954 11.6522L0.010151 15.5688C-0.00420054 15.6269 -0.00332103 15.6877 0.0127046 15.7454C0.0287303 15.8031 0.0593605 15.8557 0.101637 15.8981C0.166572 15.9628 0.254492 15.9992 0.346172 15.9992C0.374454 15.9992 0.402627 15.9957 0.430055 15.9888L4.34662 15.0129C4.40752 14.9976 4.46311 14.966 4.50743 14.9216L13.3116 6.11812L9.88081 2.68738ZM15.4926 1.48695L14.5126 0.507024C13.8577 -0.14793 12.7162 -0.14728 12.0619 0.507024L10.8616 1.70745L14.2922 5.13806L15.4926 3.93767C15.8197 3.61065 16 3.17534 16 2.71239C16 2.24944 15.8197 1.81414 15.4926 1.48695Z"
                    fill="#4B49AC"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1000_6286">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </button>
        ),
      },
    ];
  }
  FileList(handleFileDownload: any): any {
    return [
      {
        Header: "Filename",
        accessor: "filename",
        width: 40,
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <button
              className="text-secondary font-semibold text-base  underline dark:text-white"
              onClick={() => handleFileDownload(row.original?.id, row.original?.filename)}
            >
              {row.original?.filename}
            </button>
          );
        },
      },
      {
        Header: "Size",
        accessor: "size",
        width: 5,
        align: "right",
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#696868] dark:text-white   font-normal text-base text-right">{row.original?.size}</p>
          );
        },
      },
      {
        Header: "MimeType",
        accessor: "mimeType",
        width: 15,
      },
      {
        Header: "Extension",
        accessor: "extension",
        width: 15,
      },
      {
        Header: "Date Created",
        accessor: "dateCreated",
        width: 15,
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#696868] dark:text-white   font-normal text-base">
              {row.original?.dateAnnounced === null ? "-" : moment(row.original?.dateCreated).format("MM/DD/YYYY")}
            </p>
          );
        },
      },
    ];
  }
  Dashboard(setDropdownOpen: any, dropdownOpen: string, trigger: any, dropdown: any): any {
    return [
      // Define your columns here
      { Header: "Item Type", accessor: "itemType" },
      { Header: "Type", accessor: "type" },
      { Header: "Date/Time", accessor: "dateTime" },
      { Header: "User", accessor: "user" },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }: CellProps<Data>) => (
          <div className="relative flex flex-col">
            <button ref={trigger} onClick={() => setDropdownOpen(row.id)}>
              <img src={Action} alt="action" className="max-w-[14px] h-4" />
            </button>
            <div
              ref={dropdown}
              onFocus={() => setDropdownOpen(row.id)}
              onBlur={() => setDropdownOpen("")}
              className={`absolute -right-4 z-10 -top-5  rounded-md space-y-1  bg-[#fff] p-[10px]  border border-[#D3D3E6] ${
                dropdownOpen === row.id ? "block" : "hidden"
              }`}
            >
              <div>
                <div>
                  <button
                    className="text-base  text-[#393C51]  dark:text-white  font-bold  rounded-sm p-[10px]"
                    onClick={() => setDropdownOpen("")}
                  >
                    View
                  </button>
                </div>
                <div>
                  <button
                    className="text-base  text-[#393C51]  dark:text-white  font-bold  rounded-sm p-[10px]"
                    onClick={() => setDropdownOpen("")}
                  >
                    Approve
                  </button>
                </div>
              </div>
            </div>
          </div>
        ),
      },
    ];
  }
  CompanyList(navigate: NavigateFunction): any {
    return [
      // Define your columns here
      {
        Header: "Name",
        accessor: "name",
        width: 25,
      },
      {
        Header: "Ticker",
        accessor: "ticker",
        width: 15,
      },
      {
        Header: "Sector",
        accessor: "sectorId",
        width: 15,
      },
      {
        Header: "Incorporation",
        accessor: "incorp",
        width: 15,
      },
      {
        Header: "Headquarters",
        accessor: "hq",
        width: 15,
      },
      {
        Header: "Geo Exposure",
        accessor: "geoExposure",
        width: 15,
      },
      {
        Header: "Action",
        accessor: "id",
        width: 0,
        align: "right",
        Cell: ({ row }: CellProps<Data>) => (
          <button
            className="text-secondary font-semibold text-base capitalize underline float-right"
            onClick={() => navigate(`/update-company/${row.original.id}`)}
          >
            <div className=" rounded-full  p-3 border border-[#E6E6F9] bg-button-light cursor-pointer dark:bg-button-dark ">
              <svg
                className="dark:brightness-[3] brightness-0"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <g clipPath="url(#clip0_1000_6286)">
                  <path
                    d="M9.88081 2.68738L1.07744 11.4914C1.03311 11.5359 1.00152 11.5914 0.985954 11.6522L0.010151 15.5688C-0.00420054 15.6269 -0.00332103 15.6877 0.0127046 15.7454C0.0287303 15.8031 0.0593605 15.8557 0.101637 15.8981C0.166572 15.9628 0.254492 15.9992 0.346172 15.9992C0.374454 15.9992 0.402627 15.9957 0.430055 15.9888L4.34662 15.0129C4.40752 14.9976 4.46311 14.966 4.50743 14.9216L13.3116 6.11812L9.88081 2.68738ZM15.4926 1.48695L14.5126 0.507024C13.8577 -0.14793 12.7162 -0.14728 12.0619 0.507024L10.8616 1.70745L14.2922 5.13806L15.4926 3.93767C15.8197 3.61065 16 3.17534 16 2.71239C16 2.24944 15.8197 1.81414 15.4926 1.48695Z"
                    fill="#4B49AC"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1000_6286">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </button>
        ),
      },
    ];
  }
  AlertList(): any {
    return [
      // Define your columns here
      {
        Header: "Name",
        accessor: "name",
        width: 25,
      },
      {
        Header: "Type",
        accessor: "domainType",
        width: 15,
      },
      {
        Header: "Date Modified",
        accessor: "dateModified ",
        width: 15,
      },
      {
        Header: "Modified By",
        accessor: "modifiedBy",
        width: 15,
      },
      {
        Header: "Action",
        accessor: "id",
        width: 0,
        align: "right",
        Cell: () => (
          <button className="text-secondary font-semibold text-base capitalize underline float-right">
            <div className=" rounded-full  p-3 border border-[#E6E6F9] bg-b-seconday-light cursor-pointer bg-button-light dark:bg-button-dark ">
              <svg
                className="dark:brightness-[3] brightness-0"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <g clipPath="url(#clip0_1000_6286)">
                  <path
                    d="M9.88081 2.68738L1.07744 11.4914C1.03311 11.5359 1.00152 11.5914 0.985954 11.6522L0.010151 15.5688C-0.00420054 15.6269 -0.00332103 15.6877 0.0127046 15.7454C0.0287303 15.8031 0.0593605 15.8557 0.101637 15.8981C0.166572 15.9628 0.254492 15.9992 0.346172 15.9992C0.374454 15.9992 0.402627 15.9957 0.430055 15.9888L4.34662 15.0129C4.40752 14.9976 4.46311 14.966 4.50743 14.9216L13.3116 6.11812L9.88081 2.68738ZM15.4926 1.48695L14.5126 0.507024C13.8577 -0.14793 12.7162 -0.14728 12.0619 0.507024L10.8616 1.70745L14.2922 5.13806L15.4926 3.93767C15.8197 3.61065 16 3.17534 16 2.71239C16 2.24944 15.8197 1.81414 15.4926 1.48695Z"
                    fill="#4B49AC"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1000_6286">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </button>
        ),
      },
    ];
  }
  OldMnalist(navigate: NavigateFunction): any {
    return [
      // Define your columns here
      {
        Header: "MNA Name",
        accessor: "name",
        width: 25,
        Cell: ({ row }: any) => {
          return (
            <button
              className="text-secondary font-semibold text-base capitalize underline truncate dark:text-white"
              onClick={() => navigate(`/old-mna/${row.original?.id}`)}
            >
              {row.original?.name}
            </button>
          );
        },
      },
      {
        Header: "Category",
        accessor: "categoryName",
        width: 15,
        Cell: ({ row }: any) => {
          return (
            <p className=" text-[#696868] font-normal text-base dark:text-white  ">{row.original?.categoryName}</p>
          );
        },
      },
      {
        Header: "Acquirer",
        accessor: "acquirer",
        width: 15,
        Cell: ({ row }: any) => {
          return <p className=" text-[#696868] font-normal text-base dark:text-white  ">{row.original?.acquirer}</p>;
        },
      },
      {
        Header: "Target",
        accessor: "target",
        width: 15,
        Cell: ({ row }: any) => {
          return <p className=" text-[#696868] font-normal text-base dark:text-white  ">{row.original?.target}</p>;
        },
      },
      {
        Header: "Date Announced",
        accessor: "dateAnnounced",
        width: 15,
        Cell: ({ row }: any) => {
          return (
            <p className=" text-[#696868] font-normal text-base dark:text-white  ">
              {row.original?.dateAnnounced === null ? "" : moment(row.original?.dateAnnounced).format("MM/DD/YYYY")}
            </p>
          );
        },
      },
      {
        Header: "Date Closing",
        accessor: "dateClosing",
        width: 15,
        Cell: ({ row }: any) => {
          return (
            <p className=" text-[#696868] font-normal text-base dark:text-white  ">
              {row.original?.dateClosing === null ? "" : moment(row.original?.dateClosing).format("MM/DD/YYYY")}
            </p>
          );
        },
      },
      {
        Header: "Action",
        accessor: "id",
        width: 0,
        align: "right",
        Cell: ({ row }: CellProps<Data>) => (
          <button
            className="text-secondary font-semibold text-base capitalize underline float-right"
            onClick={() => navigate(`/old-update-mna/${row.original.id}`)}
          >
            <div className="rounded-full  p-3 border border-[#E6E6F9] bg-button-light dark:bg-button-dark cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                className="dark:brightness-[4] brightness-0"
              >
                <g clipPath="url(#clip0_1000_6286)">
                  <path
                    d="M9.88081 2.68738L1.07744 11.4914C1.03311 11.5359 1.00152 11.5914 0.985954 11.6522L0.010151 15.5688C-0.00420054 15.6269 -0.00332103 15.6877 0.0127046 15.7454C0.0287303 15.8031 0.0593605 15.8557 0.101637 15.8981C0.166572 15.9628 0.254492 15.9992 0.346172 15.9992C0.374454 15.9992 0.402627 15.9957 0.430055 15.9888L4.34662 15.0129C4.40752 14.9976 4.46311 14.966 4.50743 14.9216L13.3116 6.11812L9.88081 2.68738ZM15.4926 1.48695L14.5126 0.507024C13.8577 -0.14793 12.7162 -0.14728 12.0619 0.507024L10.8616 1.70745L14.2922 5.13806L15.4926 3.93767C15.8197 3.61065 16 3.17534 16 2.71239C16 2.24944 15.8197 1.81414 15.4926 1.48695Z"
                    fill="#4B49AC"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1000_6286">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </button>
        ),
      },
    ];
  }
}
