import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Search from "../../Assets/svg/notes-search.svg";

interface BreadcrumbsProps {
  breadcrumbs: string[];
  icon?: string;
  addItem?: () => void;
  updateItem?: () => void;
  onFilter?: (value: any) => void;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs, icon, addItem, updateItem, onFilter }) => {
  const { pathname } = useLocation();
  const { id } = useParams();

  return (
    <div className="bg-gray-200 flex items-center justify-between flex-wrap">
      <ol className="list-none p-0 inline-flex">
        {breadcrumbs.map((breadcrumb, index) => (
          <li key={index} className="flex items-center gap-4">
            {index === 0 && icon && <img src={icon} alt="icon" className="dark:brightness-[3.5] brightness-0" />}
            {breadcrumbs.length === 1 ? (
              <div>
                <Link to={pathname} className=" text-black dark:text-white text-[20px]">
                  Home/ {breadcrumb}
                </Link>
              </div>
            ) : (
              <div>
                {index === 0 && (
                  <span className="font-semibold text-breadcrumbs  dark:text-white text-[32px]">
                    {breadcrumb}
                    <span className="mx-2">{">"}</span>
                  </span>
                )}
                {index >= 1 && (
                  <div>
                    <span className="text-secondary text-[32px] font-semibold dark:text-white">{breadcrumb}</span>
                  </div>
                )}
              </div>
            )}
          </li>
        ))}
      </ol>

      <div className="flex justify-between items-center gap-5 flex-wrap">
        {["/notes", "/mnas", `/mna/${id}`, "/files", "/company", "/old-mnas", `/old-mna/${id}`].includes(pathname) && (
          <div className="flex items-center">
            <div className="relative  rounded-[50px] w-[312px] lg:w-[472px] flex justify-between items-center py-3 px-5  border border-dark dark:border-transparent bg-button-light dark:bg-b-secondary-dark">
              <img src={Search} alt="search" className="dark:brightness-[3.5] absolute top-3.5 left-5  brightness-0" />
              <input
                type="text"
                onChange={onFilter}
                placeholder="Search here......"
                className="dark:text-white dark:placeholder:text-white placeholder:text-dark  placeholder:font-medium text-[12px] font-normal w-full bg-transparent ml-6 mr-3  outline-none text-[#ACB4E2]"
              />
            </div>
          </div>
        )}
        {[`/mna/${id}`, `/old-mna/${id}`].includes(pathname) && (
          <div onClick={updateItem}>
            <div className=" rounded-full  p-3 border border-[#E6E6F9] bg-b-seconday-light cursor-pointer bg- dark:bg-button-dark bg-button-light">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                className="dark:brightness-[3] brightness-0"
              >
                <g clipPath="url(#clip0_1000_6286)">
                  <path
                    d="M9.88081 2.68738L1.07744 11.4914C1.03311 11.5359 1.00152 11.5914 0.985954 11.6522L0.010151 15.5688C-0.00420054 15.6269 -0.00332103 15.6877 0.0127046 15.7454C0.0287303 15.8031 0.0593605 15.8557 0.101637 15.8981C0.166572 15.9628 0.254492 15.9992 0.346172 15.9992C0.374454 15.9992 0.402627 15.9957 0.430055 15.9888L4.34662 15.0129C4.40752 14.9976 4.46311 14.966 4.50743 14.9216L13.3116 6.11812L9.88081 2.68738ZM15.4926 1.48695L14.5126 0.507024C13.8577 -0.14793 12.7162 -0.14728 12.0619 0.507024L10.8616 1.70745L14.2922 5.13806L15.4926 3.93767C15.8197 3.61065 16 3.17534 16 2.71239C16 2.24944 15.8197 1.81414 15.4926 1.48695Z"
                    fill="#4B49AC"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1000_6286">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        )}
        {["/notes", "/mnas", `/mna/${id}`, "/company", "/fundamental", "/old-mnas", `/old-mna/${id}`].includes(
          pathname
        ) && (
          <div
            onClick={addItem}
            className="rounded-full p-3 border border-[#E6E6F9] bg-b-seconday-light cursor-pointer bg-button-light dark:bg-button-dark"
          >
            <svg
              className="dark:brightness-[4] brightness-0"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clipPath="url(#clip0_89_357)">
                <path
                  d="M15.1999 7.19999H8.80004V0.799949C8.80004 0.358462 8.44158 0 7.99994 0C7.55846 0 7.19999 0.358462 7.19999 0.799949V7.19999H0.799949C0.358462 7.19999 0 7.55846 0 7.99994C0 8.44158 0.358462 8.80004 0.799949 8.80004H7.19999V15.1999C7.19999 15.6416 7.55846 16 7.99994 16C8.44158 16 8.80004 15.6416 8.80004 15.1999V8.80004H15.1999C15.6416 8.80004 16 8.44158 16 7.99994C16 7.55846 15.6416 7.19999 15.1999 7.19999Z"
                  fill="#4B49AC"
                />
              </g>
              <defs>
                <clipPath id="clip0_89_357">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        )}
        {[
          "/notes",
          "/dashboard",
          "/mnas",
          `/mna/${id}`,
          "/files",
          "/company",
          "/alerts",
          "/fundamental",
          "/old-mnas",
          `/old-mna/${id}`,
        ].includes(pathname) && (
          <div className=" rounded-full  p-3 border border-[#E6E6F9] bg-b-seconday-light cursor-pointer bg-button-light dark:bg-button-dark ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              className="dark:brightness-[4] brightness-0"
            >
              <g clipPath="url(#clip0_203_3296)">
                <path
                  d="M13.9312 6.76062C13.8808 6.75066 13.8336 6.72839 13.7938 6.69579C13.754 6.66319 13.7228 6.62127 13.7031 6.57375L13.0409 4.97687C13.0009 4.88031 13.0119 4.77 13.07 4.68313L13.7837 3.61906L12.3809 2.21625L11.3169 2.93C11.2741 2.95863 11.2249 2.97626 11.1736 2.98133C11.1224 2.9864 11.0707 2.97875 11.0231 2.95906L9.42625 2.29688C9.37873 2.27717 9.33681 2.24604 9.30421 2.20624C9.27161 2.16645 9.24934 2.11922 9.23938 2.06875L8.99187 0.8125H7.00812L6.76062 2.06875C6.75066 2.11922 6.72839 2.16645 6.69579 2.20624C6.66319 2.24604 6.62127 2.27717 6.57375 2.29688L4.97687 2.95906C4.88031 2.99906 4.77 2.98813 4.68313 2.93L3.61906 2.21625L2.21625 3.61906L2.93 4.68313C2.98813 4.77 2.99906 4.88031 2.95906 4.97687L2.29688 6.57375C2.27717 6.62127 2.24604 6.66319 2.20624 6.69579C2.16645 6.72839 2.11922 6.75066 2.06875 6.76062L0.8125 7.00812V8.99187L2.06875 9.23938C2.17125 9.25969 2.25688 9.33 2.29688 9.42625L2.95906 11.0231C2.99906 11.1197 2.98813 11.23 2.93 11.3169L2.21625 12.3809L3.61906 13.7837L4.68313 13.07C4.77 13.0119 4.88031 13.0009 4.97687 13.0409L6.57375 13.7031C6.62131 13.7229 6.66326 13.754 6.69586 13.7939C6.72846 13.8337 6.75071 13.881 6.76062 13.9316L7.00812 15.1875H8.99187L9.23938 13.9316C9.24929 13.881 9.27154 13.8337 9.30414 13.7939C9.33674 13.754 9.37869 13.7229 9.42625 13.7031L11.0231 13.0409C11.1197 13.0009 11.23 13.0119 11.3169 13.07L12.3809 13.7837L13.7837 12.3809L13.07 11.3169C13.0414 11.2741 13.0237 11.2249 13.0187 11.1736C13.0136 11.1224 13.0213 11.0707 13.0409 11.0231L13.7031 9.42625C13.7431 9.33 13.8287 9.25969 13.9312 9.23938L15.1875 8.99187V7.00812L13.9312 6.76062ZM8 11.8047C5.90219 11.8047 4.19531 10.0978 4.19531 8C4.19531 5.90219 5.90219 4.19531 8 4.19531C10.0978 4.19531 11.8047 5.90219 11.8047 8C11.8047 10.0978 10.0978 11.8047 8 11.8047Z"
                  fill="#4B49AC"
                />
              </g>
              <defs>
                <clipPath id="clip0_203_3296">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};

export default Breadcrumbs;
