// React
import React, { useCallback, useState } from "react";
// Third party
import { useNavigate } from "react-router-dom";
// Components
import { Columns } from "../Components/Columns/Columns";
import Breadcrumbs from "../Components/Breadcrumbs/Breadcrumbs";
import Table from "../Components/Tables/Mna-Table";
// OpenAPI
import { apiConfig } from "../Components/ConfigurationApi/Configuration";
import { CompanyRestControllerApi } from "../openapi";
import { expiredTokenValidation } from "../api/expiredTokenValidation";
import { getApiData } from "../Controller/getApiDataConfiguration";
// Helpers
import { getSortArray } from "../helpers/getSortArray";
// Types
import { Sort } from "../interfaces/pages/variedInterfaces";
// Images
import companyIcon from "../Assets/svg/blue-company.svg";

const CompanyList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const fetchCompanyList = useCallback(async (pageIndex: number, pageSize: number, sortBy: Sort[]) => {
    setLoading(true);
    try {
      const api = new CompanyRestControllerApi(apiConfig());

        const response = await api.getCompanies(
          search ? `name:${search}` : "",
          pageIndex,
          pageSize,
          getSortArray(sortBy),
          {}
        );
        const content = new getApiData()
        const data = content.getSectorRestControllerApi(response)
        return { ...response.data, data };
      } catch (error) {
        expiredTokenValidation(error);
      } finally {
        setLoading(false);
      }
    },
    [search]
  );

  const columnsInstance = new Columns();

  return (
    <React.Fragment>
      <div className="p-[30px]">
        <Breadcrumbs
          breadcrumbs={["Company"]}
          icon={companyIcon}
          addItem={() => navigate("/add-company")}
          onFilter={(e) => setSearch(e.target.value)}
        />
        <div className="mt-[31px]">
          <Table columns={columnsInstance.CompanyList(navigate)} fetchData={fetchCompanyList} loading={loading} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default CompanyList;
