import React from "react";

interface DeleteModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (value: any) => void;
  onDeleteItem: () => void;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  isModalOpen,
  setIsModalOpen,
  onDeleteItem,
}) => {
  return (
    <div
      className={`${
        isModalOpen ? "block opacity-100" : "hidden opacity-0"
      } fixed text-gray-500 flex items-center justify-center  top-0 left-0 z-30  h-full min-h-screen w-full  px-4 py-5   transition ease duration-300 `}
    >
      <div className="absolute inset-0 bg-[#898B8D]  transition-opacity" />
      <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-center">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-[#FAEAE9] sm:mx-0 sm:h-10 sm:w-10">
              <svg
                className="h-6 w-6 text-red-600"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#D33F48"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                />
              </svg>
            </div>
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <h3
                className="text-base font-semibold leading-6 text-gray-900"
                id="modal-title"
              >
                Are you sure you want to delete this item?
              </h3>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 flex  justify-end sm:px-6">
          <button
            type="button"
            onClick={() => setIsModalOpen(false)}
            className="px-6 py-1 border border-secondary text-secondary text-base font-medium rounded mr-4"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={onDeleteItem}
            className="px-6 py-1 border border-secondary text-[#fff] bg-secondary text-base font-medium rounded"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
