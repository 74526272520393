// React
import React, { useEffect, useState } from "react";
// Third party
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
// Components
import AsyncSelect from "../Components/Async-Select";
import Breadcrumbs from "../Components/Breadcrumbs/Breadcrumbs";
import InputField from "../Components/Common-inputs";
import Loader from "../Components/Loader";
// OpenAPI
import { apiConfig } from "../Components/ConfigurationApi/Configuration";
import { CompanyRestControllerApi } from "../openapi";
import { expiredTokenValidation } from "../api/expiredTokenValidation";
import { getApiData } from "../Controller/getApiDataConfiguration";
// Types
import { ErrorField } from "../interfaces/pages/Errors";

// Define initial form values
const initialValues = {
  name: "",
  sectorId: "",
  ticker: "",
  incorp: "",
  hq: "",
  geoExposure: "",
  tags: [],
};

const AddEditCompany = () => {
  const [serverError, setServerError] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [sectorList, setSectorList] = useState<any>([]);
  const [tagLoading, setTagLoading] = useState(false);
  const [changedValues, setChangedValues] = useState<any>({});

  // Fetch Sector List options on component mount
  useEffect(() => {
    fetchSectorList();
  }, []);

  // Function to fetch Sector List options
  const fetchSectorList = async () => {
    try {
      const api = new getApiData();
      const response = await api.getSectorRestControllerApi(id);
      const filteredOptions = response?.data?.content!.map((item: any) => ({
        id: item.id,
        name: item.name,
      }));
      setSectorList(filteredOptions);
    } catch (error) {
      expiredTokenValidation(error);
    }
  };

  useEffect(() => {
    if (!id) return;
    fetchCompanyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchCompanyData = async () => {
    setLoading(true);
    try {
      const api = new CompanyRestControllerApi(apiConfig());
      const response = await api.getCompanyById(Number(id));
      if (response.data) {
        const data = response.data as any;
        data.tags =
          data.tags?.map((tag: any) => {
            return { value: tag.id, label: tag.tag };
          }) || null;
        formik.setValues(data);
        setChangedValues(data);
      }
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        console.error("Recurso no encontrado. Verifica la URL o el ID:", error);
      } else {
        console.error("Error en la solicitud:", error.message);
      }
      setLoading(false);
      return;
    }
    setLoading(false);
  };

  // Formik hook for form management
  const formik: any = useFormik({
    initialValues,
    onSubmit: async (values: any, { setSubmitting, setErrors }) => {
      const data: any = {
        ...formik.values,
        version: 0,
        mnaId: values.mnaId?.value,
      };

      if (data.tags?.length > 0) {
        const tags = data.tags.reduce(
          (result: any, obj: any) => {
            if (!changedValues.tags?.includes(obj)) {
              result.add = result.add || [];
              result.add = [...result.add, { tag: obj.label }];
            }
            return result;
          },
          { add: null, delete: null }
        );

        changedValues.tags?.forEach((item: any) => {
          const deletedObj = data.tags?.map((obj: any) => {
            if (obj?.value === item.value) {
              return obj.value;
            }
          });
          if (!deletedObj.includes(item.value)) {
            tags.delete = tags.delete || [];
            tags.delete = [...tags.delete, item.value];
          }
        });
        if (id) {
          data.tags = tags;
        } else {
          delete tags.delete;
          data.tags = tags?.add?.length > 0 ? tags : null;
        }
      } else {
        const tags: any = { add: null, delete: [] };
        tags.delete = changedValues.tags?.map((item: any) => item.value);
        data.tags = id ? tags : null;
      }

      setLoading(true);
      try {
        // API call
        values = { ...values, ...data };
        const api = new CompanyRestControllerApi(apiConfig());
        id
          ? await api.updateCompany(Number(id), data)
          : await api.createCompany(data);
        navigate("/company");
      } catch (error: any) {
        if (error.response) {
          if (
            error.response.data?.exception &&
            !error.response.data?.fieldErrors.length
          ) {
            toast.custom(
              (t) => (
                <div
                  className={`${
                    t.visible ? "animate-enter" : "animate-leave"
                  } max-w-md w-full bg-danger shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                >
                  <div className="flex-1 w-0 p-4">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 pt-0.5">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="50"
                          height="50"
                          viewBox="0 0 48 48"
                          className="dark:brightness-[4]"
                        >
                          <path
                            fill="#F78F8F"
                            d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
                          ></path>
                          <path
                            fill="#fff"
                            d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z"
                          ></path>
                          <path
                            fill="#fff"
                            d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z"
                          ></path>
                        </svg>
                      </div>
                      <div className="ml-3 flex-1 text-white">
                        <p>{error.response.data?.exception}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ),
              {
                duration: 500,
              }
            );
          }

          if (error.response.data?.fieldErrors.length) {
            const errorsObject = {} as any;
            error.response.data?.fieldErrors.forEach((error: ErrorField) => {
              errorsObject[error.field] = error.defaultMsg;
            });
            setErrors(errorsObject);
            setServerError(errorsObject);
          }
        } else {
          // Handle other errors
          console.error("Error submitting form:", error.message);
        }
      } finally {
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  const fetchTags = async (inputValue: string): Promise<any[]> => {
    // Simulate an asynchronous call with a delay
    setTagLoading(true);
    try {
      const api = new getApiData();
      setTagLoading(false);
      return api.getTagRestContollerApi(inputValue);
    } catch (error) {
      console.error("Error fetching options:", error);
      setTagLoading(false);
      return [];
    }
  };

  return (
    <React.Fragment>
      <div className={`p-[30px] ${loading && "opacity-20"} `}>
        {/* Breadcrumbs component */}
        <Breadcrumbs
          breadcrumbs={[id ? "Update Company" : "Add New Company"]}
        />

        <div>
          {/* Form */}
          <form onChange={() => setServerError(null)}>
            <div className="py-6">
              <div className="mb-8 flex flex-col gap-[30px] xl:flex-row">
                {/* Name */}

                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Name"
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Name"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    bgColor={"#fff"}
                  />
                </div>

                {/* Ticker */}

                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Ticker"
                    id="ticker"
                    name="ticker"
                    type="text"
                    placeholder="Ticker"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    bgColor={"#fff"}
                  />
                </div>

                {/* Sector */}
                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Sector"
                    id="sectorId"
                    name="sectorId"
                    type="select"
                    optionList={sectorList}
                    placeholder="Sector"
                    serverError={serverError || ""}
                    formik={formik}
                    labelKey="name"
                    valueKey="id"
                    showIcon
                    bgColor={"#fff"}
                    last
                  />
                </div>
              </div>

              <div className="mb-8 flex flex-col gap-[30px] xl:flex-row">
                {/* Incorporation  */}
                <div className="w-full  xl:w-1/3">
                  <InputField
                    label="Incorporation"
                    id="incorp"
                    name="incorp"
                    type="text"
                    placeholder="Incorporation"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    bgColor={"#fff"}
                  />
                </div>

                {/* Headquarters  */}

                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Headquarters"
                    id="hq"
                    name="hq"
                    type="text"
                    placeholder="Headquarters"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    bgColor={"#fff"}
                  />
                </div>

                {/* Geo Exposure  */}

                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Geo Exposure"
                    id="geoExposure"
                    name="geoExposure"
                    type="text"
                    placeholder="Geo Exposure"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    bgColor={"#fff"}
                    last
                  />
                </div>
              </div>

              <div className="w-full mt-[30px]">
                <AsyncSelect
                  label="Tags"
                  name="tags"
                  id="tags"
                  placeholder="Tags"
                  fetchOptions={fetchTags}
                  formik={formik}
                  loading={tagLoading}
                  error={""}
                  isMulti
                  last
                  noOptionsMessage="No tag found"
                  bgColor="#fff"
                  tags
                />
              </div>

              <div className="flex justify-end gap-[30px] mt-[80px]">
                <div>
                  <button
                    type="button"
                    className="py-[18px] border border-secondary text-secondary text-base font-medium rounded-full px-[116px]"
                    onClick={() => navigate("/company")}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={formik.handleSubmit}
                    className="py-[18px] border border-secondary text-[#fff] bg-primary-default text-base font-medium rounded-full px-[103px]"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {loading && <Loader loading={loading} />}
    </React.Fragment>
  );
};

export default AddEditCompany;
