// React
import React, { useEffect, useState } from "react";
// Third party
import { useNavigate, useParams } from "react-router-dom";
// Components
import Breadcrumbs from "../Components/Breadcrumbs/Breadcrumbs";
import Loader from "../Components/Loader";
// OpenAPI
import { apiConfig } from "../Components/ConfigurationApi/Configuration";
import { expiredTokenValidation } from "../api/expiredTokenValidation";
import { FundamentalRestControllerApi, SectorRestControllerApi } from "../openapi";
// Images
import fundamentalIcon from "../Assets/svg/blue-fundamental.svg";

const FundamentalDetails = () => {
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetchFundamentalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchFundamentalData = async () => {
    setLoading(true);
    try {
      const api = new FundamentalRestControllerApi(apiConfig());
      const response = await api.getFundamentalById(Number(id));
      let sectorId;
      if (response.data.sectorId) {
        sectorId = await fetchSectorNameById(response.data.sectorId);
      }

      setData({ ...response?.data, sectorId } || {});
    } catch (error) {
      expiredTokenValidation(error);
    }
    setLoading(false);
  };

  const fetchSectorNameById = async (sectorId: number) => {
    try {
      const api = new SectorRestControllerApi(apiConfig());
      const response = await api.getSectorById(sectorId);
      return response.data?.name || "";
    } catch (error) {
      expiredTokenValidation(error);
    }
  };

  return (
    <React.Fragment>
      {loading && <Loader loading={loading} />}
      <div className={`p-[30px] ${loading && "opacity-20"}`}>
        <Breadcrumbs
          breadcrumbs={["Fundamental", "Fundamental Details"]}
          icon={fundamentalIcon}
          updateItem={() => navigate(`/update-fundamental/${id}`)}
        />
        <div className="px-[30px] pt-5 pb-[30px] border border-[#D4D2E6] rounded-md mt-[30px]">
          <div className="flex justify-center items-center gap-[35px]">
            <div className="border-t border-[#4B49AC] w-[95.8px]"></div>
            <div className="text-black dark:text-white text-2xl font-bold">{data?.name}</div>
            <div className="border-t border-[#4B49AC] w-[95.8px]"></div>
          </div>
          <div className="flex flex-col md:flex-row gap-[30px] mt-[29px]">
            <div className="md:w-1/2 flex flex-col">
              <div className=" overflow-x-auto">
                <div className=" min-w-full inline-block align-middle">
                  <div className="border border-[#DCDBEE] rounded-lg overflow-hidden bg-[#FAFAFE]">
                    <table className="min-w-full dark:bg-primary-dark">
                      <caption className="text-left text-[18px] font-semibold bg-primary-default dark:bg-black text-[#FFF] py-[19px] px-[29px]">
                        Company Information
                      </caption>
                      <thead className="bg-[#F1F2FA] dark:bg-table-dark">
                        <tr>
                          <th
                            scope="col"
                            className="px-[30px] py-[20px] text-sub-table-lighttext-base font-semibold"
                          ></th>
                          <th
                            scope="col"
                            className="px-[30px] py-[20px] text-sub-table-lighttext-base font-semibold text-left"
                          >
                            Value
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-[#DCDBEE]">
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868]  dark:text-white   text-base font-normal">
                            Ticker:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.ticker}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            Sector:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.sectorId}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            Incorporation:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.incorp}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            Headquarters:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.hq}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            Geo Exposure:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.geoExposure}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 bg-[#FAFAFE] dark:bg-primary-dark">
              <div className="border border-[#E4E3ED] rounded-lg h-full">
                <div className=" px-[30px] py-[19px] border-b border-[#E5E4FB] bg-primary-default dark:bg-black text-[#fff] dark:text-white text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
                  What Matters
                </div>
                <div className="bg-[#FAFAFE]]  px-[30px] py-[19px] ">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.whatMatters,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-[30px] mt-[29px]">
            <div className="md:w-1/2 bg-[#FAFAFE] dark:bg-primary-dark">
              <div className="border border-[#E4E3ED] rounded-lg h-full">
                <div className=" px-[30px] py-[19px] border-b border-[#E5E4FB] bg-primary-default dark:bg-black text-[#fff] text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
                  Business Description
                </div>
                <div className="bg-[#FAFAFE]] px-[30px] py-[19px] ">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.businessDesc,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="md:w-1/2 bg-[#FAFAFE] dark:bg-primary-dark">
              <div className="border border-[#E4E3ED] rounded-lg h-full">
                <div className=" px-[30px] py-[19px] border-b border-[#E5E4FB] bg-primary-default dark:bg-black text-[#fff] text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
                  Company History
                </div>
                <div className="bg-[#FAFAFE]] px-[30px] py-[19px] ">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.companyHistory,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-[30px] mt-[29px]">
            <div className="md:w-1/2 bg-[#FAFAFE] dark:bg-primary-dark">
              <div className="border border-[#E4E3ED] rounded-lg h-full">
                <div className=" px-[30px] py-[19px] border-b border-[#E5E4FB] dark:bg-black bg-primary-default text-[#fff] text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
                  Company Basics
                </div>
                <div className="bg-[#FAFAFE]] px-[30px] py-[19px] ">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.companyBasics,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="md:w-1/2 bg-[#FAFAFE] dark:bg-primary-dark">
              <div className="border border-[#E4E3ED] rounded-lg h-full">
                <div className=" px-[30px] py-[19px] border-b border-[#E5E4FB] bg-primary-default dark:bg-black text-[#fff] text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
                  Drivers / Valuation
                </div>
                <div className="bg-[#FAFAFE]] px-[30px] py-[19px] ">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.driversValuation,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full mt-[30px]">
            <div className="border border-[#E4E3ED] rounded-lg ">
              <div className=" px-[30px] py-[19px] border-b dark:bg-black border-[#E5E4FB] bg-primary-default text-[#fff] text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
                Valuation History
              </div>
              <div className="px-[33px] py-[25px]">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.valuationHistory,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FundamentalDetails;
