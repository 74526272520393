import React, { useCallback, useState } from "react";
import Breadcrumbs from "../Components/Breadcrumbs/Breadcrumbs";
import mnaIcon from "../Assets/svg/blue-mna.svg";
import Table from "../Components/Tables/Mna-Table";
import { useNavigate } from "react-router-dom";
import { Columns } from "../Components/Columns/Columns";
import { Sort } from "../interfaces/pages/variedInterfaces";
import { getApiData } from "../Controller/getApiDataConfiguration";
import { expiredTokenValidation } from "../api/expiredTokenValidation";

const OldMnaList = () => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const fetchMnaList = useCallback(
    async (pageIndex: number, pageSize: number, sortBy: Sort[]) => {
      setLoading(true);
      try {
        const api = new getApiData();
        return api.getMnaRestControllerApi(search, pageIndex, pageSize, sortBy);
      } catch (error) {
        expiredTokenValidation(error);
      } finally {
        setLoading(false);
      }
    },
    [search]
  );

  const columnsInstance = new Columns();

  return (
    <React.Fragment>
      <div className={`p-[30px]`}>
        <Breadcrumbs
          breadcrumbs={["Old MNAs"]}
          icon={mnaIcon}
          addItem={() => navigate("/old-add-mna")}
          onFilter={(e) => setSearch(e.target.value)}
        />
        <div className="mt-[31px]">
          <Table
            columns={columnsInstance.OldMnalist(navigate)}
            fetchData={fetchMnaList}
            search={search}
            loading={loading}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default OldMnaList;
