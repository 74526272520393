import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import layer from "../Assets/images/layour.png";
import login from "../Assets/images/login.png";
import User from "../Assets/svg/user.svg";
import EyeClose from "../Assets/svg/eyesclose.svg";
import { useNavigate } from "react-router-dom";
import { AuthenticationRestControllerApi, Configuration } from "../openapi";
import { AuthenticationResponse } from "../interfaces/pages/variedInterfaces";
import { getUsernameAsync } from "../helpers/instantiateUserData";

const validationSchema = Yup.object({
  username: Yup.string().required("Username is Required"),
  password: Yup.string().required("Password is Required"),
});

const LoginScreen: React.FC = () => {
  const [passwordType, setPasswordType] = useState("password");
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState("");
  const navigate = useNavigate();

  const handleToggle = () => {
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  const formik = useFormik({
    initialValues: { username: "", password: "" },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);

        const configuration = new Configuration({
          basePath: process.env.REACT_APP_API_URL,
        });

        const api = new AuthenticationRestControllerApi(configuration);
        const response: AuthenticationResponse = await api.authenticate(values);
        if (response.status === 200) {
          if (response.data?.accessToken) {
            localStorage.setItem("token", response.data.accessToken);
            localStorage.setItem("username", values.username);
            await getUsernameAsync();
            if (localStorage.getItem("redirectUrl")) {
              navigate(localStorage.getItem("redirectUrl") || "");
              localStorage.removeItem("redirectUrl");
            } else {
              navigate("/dashboard");
            }
          }
          setErrors("");
        }
      } catch (error: any) {
        setErrors(error.response.data.message);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <React.Fragment>
      <div className={`flex h-screen relative ${loading && "opacity-20"}`}>
        <div className="hidden md:block md:w-1/2 bg-gray-500 relative bg-[#F4F4FD]">
          <img src={layer} alt="Background" className="absolute h-screen w-full" />
          <div className="flex justify-center items-center h-full px-[68px]">
            <img src={login} alt="Background" />
          </div>
        </div>

        <div className="w-full md:w-1/2 p-8 flex items-center justify-center bg-gray-100">
          <form className="w-full max-w-md" onSubmit={formik.handleSubmit} onChange={() => setErrors("")}>
            <h2 className="text-primary text-[35px] font-bold uppercase mb-6">One68 Global capital</h2>
            <h2 className="text-[64px] text-black-2 font-semibold mb-8">Login</h2>
            <div className="mb-[30px]">
              <label className="block font-medium text-black-3 text-base">Username</label>
              <div className="relative">
                <input
                  name="username"
                  type="username"
                  className="placeholder:text-placeholder text-placeholder w-full rounded-full  bg-transparent py-[18px] px-[30px] pr-12  outline-none focus:border-primary focus-visible:shadow-none "
                  placeholder="Enter your username"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                  style={{ border: "1px solid rgba(75, 73, 172, 0.30)" }}
                />

                <span className="absolute right-7 top-5">
                  <img src={User} alt="user" />
                </span>
              </div>
              {formik.errors.username && formik.touched.username ? (
                <div className="py-1 text-sm font-medium text-danger">{formik.errors.username}</div>
              ) : null}
            </div>
            <div className="mb-[20px]">
              <label className="block font-medium text-black-3 text-base">Password</label>
              <div className="relative">
                <input
                  name="password"
                  type={passwordType}
                  placeholder="6+ Characters, 1 Capital letter"
                  className="placeholder:text-placeholder text-placeholder w-full rounded-full  bg-transparent py-[18px] px-[30px] pr-12  outline-none focus:border-primary focus-visible:shadow-none "
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  style={{ border: "1px solid rgba(75, 73, 172, 0.30)" }}
                />

                <span className="absolute right-7 top-5" onClick={handleToggle}>
                  {passwordType === "password" ? (
                    <img src={EyeClose} alt="eyeClose" />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6 dark:brightness-[4]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                      />
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                  )}
                </span>
              </div>
              {formik.errors.password && formik.touched.password ? (
                <div className="py-1 text-sm font-medium text-danger">{formik.errors.password}</div>
              ) : null}
            </div>
            {errors && <div className="text-danger text-sm font-semibold text-center mb-3">{errors}</div>}{" "}
            <div className="flex justify-between items-center mb-[50px]">
              <div>
                <label
                  htmlFor="rememberPassword"
                  className="flex cursor-pointer select-none items-center text-black-3 text-base font-light"
                >
                  <div className="relative">
                    <input
                      type="checkbox"
                      id="rememberPassword"
                      className="sr-only"
                      onChange={() => {
                        setIsChecked(!isChecked);
                      }}
                    />
                    <div
                      className={`mr-[10px] flex h-5 w-5 items-center justify-center rounded border border-primary ${
                        isChecked && " bg-gray  "
                      }`}
                    >
                      <span className={`opacity-0 ${isChecked && "!opacity-100"}`}>
                        <svg className="dark:brightness-[4]" width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                            fill="#4B49AC"
                            stroke="#4B49AC"
                            strokeWidth="0.4"
                          ></path>
                        </svg>
                      </span>
                    </div>
                  </div>
                  Remember Password
                </label>
              </div>
              <div>
                <p className="text-secondary text-base font-medium">Forgot Password?</p>
              </div>
            </div>
            <div className="mb-[40px]">
              <button
                type="submit"
                className="w-full  text-[#fff] bg-secondary py-[18px] rounded-full hover:bg-primary-default dark:bg-black"
              >
                Login
              </button>
            </div>
            <div className="text-center">
              <p className="text-base font-medium text-black-3">
                Need an account? <span className="text-secondary ">Sign up!</span>
              </p>
            </div>
          </form>
        </div>
      </div>
      {loading && (
        <div role="status" className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2">
          <svg
            aria-hidden="true"
            className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600 dark:brightness-[4]"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      )}
    </React.Fragment>
  );
};

export default LoginScreen;
