// Components
import StepContainer from "./Steps/Container";
import { StepFive, StepFour, StepOne, StepSix, StepThree, StepTwo, StepSeven, StepEight } from "./Steps";
export interface StepBase {
  formik: any;
  serverError: string;
  setDeleteID: React.Dispatch<React.SetStateAction<number | undefined>>;
  setAssociation: React.Dispatch<React.SetStateAction<string>>;
  deleteModal: boolean;
  setDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  deleteID: number | undefined;
  association: string;
  handleRemoveAssociation: () => void;
  sourceType: any;
}

export type Steps = 0 | 1 | 2 | 3 | 4 | 5 | 6;

interface StepsProps extends StepBase {
  setActiveStep: React.Dispatch<React.SetStateAction<Steps>>;
  activeStep: Steps;
  mnaCategoryList: any;
  mnaSubCategoryList: any;
  conditionCategoryList: any;
  conditionStatusList: any;
  featuresLoading: boolean;
  setFeaturesLoading: React.Dispatch<React.SetStateAction<boolean>>;
  Keyboard: string;
  Minus: string;
  Plus: string;
  linkCategoryList: any;
  fetchFeatures: (inputValue?: string) => Promise<
    | {
        value: any;
        label: any;
      }[]
    | []
  >;

  onSubmitForm(): Promise<boolean>;
}

export default function MnaForm(props: StepsProps) {
  const stepToShow = () => {
    let stepToShow: JSX.Element = <></>;
    switch (props.activeStep) {
      case 0:
        stepToShow = <StepOne {...props} />;
        break;
      case 1:
        stepToShow = <StepTwo {...props} />;
        break;
      case 2:
        stepToShow = <StepThree {...props} />;
        break;
      case 3:
        stepToShow = <StepFour {...props} />;
        break;
      case 4:
        stepToShow = <StepFive {...props} />;
        break;
      case 5:
        stepToShow = <StepSix {...props} />;
        break;
      case 6:
        stepToShow = <StepSeven {...props} />;
        break;

      // case 7:
      //   stepToShow = <StepEight {...props} />;
      //   break;

      default:
        stepToShow = <StepOne {...props} />;
        break;
    }
    return (
      <StepContainer
        activeStep={props.activeStep}
        onSubmitForm={props.onSubmitForm}
        setActiveStep={props.setActiveStep}
      >
        {stepToShow}
      </StepContainer>
    );
  };

  return <div>{stepToShow()}</div>;
}
