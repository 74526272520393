import React, { useEffect, useState } from "react";
import Breadcrumbs from "../Components/Breadcrumbs/Breadcrumbs";
import mnaIcon from "../Assets/svg/blue-mna.svg";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Loader from "../Components/Loader";
import Table from "../Components/Tables/Recent-event";
import Close from "../Assets/svg/clsoe.svg";
import { Columns } from "../Components/Columns/Columns";
import { Sort } from "../interfaces/pages/variedInterfaces";
import { getApiData } from "../Controller/getApiDataConfiguration";
import { expiredTokenValidation } from "../api/expiredTokenValidation";

const OldMnaDetails = () => {
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [content, setContent] = useState();

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetchMnaData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchFeatures = async (): Promise<any[]> => {
    try {
      const api = new getApiData();
      return api.getFeatureControllerApi();
    } catch (error) {
      expiredTokenValidation(error);
      return [];
    }
  };

  const fetchMnaData = async () => {
    setLoading(true);
    try {
      const api = new getApiData();
      const response = api.getMnaRestControllerApi2(id, fetchFeatures);
      setData(response);
    } catch (error) {
      expiredTokenValidation(error);
    }
    setLoading(false);
  };

  const columnsInstance = new Columns();

  const fetchNoteList = async (pageIndex: number, pageSize: number, sortBy: Sort[]) => {
    try {
      const getfetchNoteList = new getApiData();
      return getfetchNoteList.getNoteRestControllerApi(`mnaId:${id}`, pageIndex, pageSize, sortBy);
    } catch (error) {
      expiredTokenValidation(error);
    }
  };

  const openModal = (content: any) => {
    setContent(content);

    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <React.Fragment>
      <div className={`p-[30px] ${loading && "opacity-20"}`}>
        <Breadcrumbs
          breadcrumbs={["Old MNAs", "Old MNA Details"]}
          icon={mnaIcon}
          updateItem={() => navigate(`/old-update-mna/${id}`)}
        />
        <div className="px-[30px] pt-5 pb-[30px] border border-[#D4D2E6] rounded-md mt-[30px]">
          <div className="flex justify-center items-center gap-[35px]">
            <div className="border-t border-[#4B49AC] w-[95.8px]"></div>
            <div className="text-[#4B49AC] text-2xl font-bold">{data?.name}</div>
            <div className="border-t border-[#4B49AC] w-[95.8px]"></div>
          </div>
          <div className="flex flex-col md:flex-row gap-[30px] mt-[29px]">
            <div className="md:w-1/2 flex flex-col">
              <div className=" overflow-x-auto">
                <div className=" min-w-full inline-block align-middle">
                  <div className="border border-[#DCDBEE] rounded-lg overflow-hidden bg-[#FAFAFE]">
                    <table className="min-w-full dark:bg-primary-dark">
                      <caption className="text-left text-[18px] font-semibold bg-primary-default dark:bg-black text-[#FFF] py-[19px] px-[29px]">
                        Company Information
                      </caption>
                      <thead className="bg-[#F1F2FA] dark:bg-table-dark">
                        <tr>
                          <th
                            scope="col"
                            className="px-[30px] py-[20px] text-sub-table-lighttext-base font-semibold"
                          ></th>
                          <th
                            scope="col"
                            className="px-[30px] py-[20px] text-sub-table-lighttext-base font-semibold text-left"
                          >
                            Target
                          </th>
                          <th
                            scope="col"
                            className="px-[30px] py-[20px] text-sub-table-lighttext-base font-semibold text-left"
                          >
                            Acquirer
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-[#DCDBEE]">
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            Ticker:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.tickerTarget}
                          </td>
                          <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.tickerAcquirer}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            Name:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.target}
                          </td>
                          <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.acquirer}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            Mna Size:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.size}
                          </td>
                          <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium text-left"></td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            Sector:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.sectorTarget}
                          </td>
                          <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.sectorAcquirer}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            Incorp:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.incorpTarget}
                          </td>
                          <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.incorpAcquirer}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            HQ:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.hqTarget}
                          </td>
                          <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.hqAcquirer}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            Geographic exposure:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.geoExposureTarget}
                          </td>
                          <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.geoExposureAcquirer}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            Financial Advisor:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.financialAdvisorForTarget}
                          </td>
                          <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.financialAdvisorForAcquirer}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            Legal Counsel:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.legalCounselForTarget}
                          </td>
                          <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.legalCounselForAcquirer}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {data?.whatMatters?.length > 0 && (
              <div className="md:w-1/2 bg-[#FAFAFE]">
                <div className="border border-[#E4E3ED] rounded-lg h-full">
                  <div className=" px-[30px] py-[19px] border-b border-[#E5E4FB] bg-[#4B49AC] text-[#fff] text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
                    What Matters
                  </div>
                  <div className="bg-[#FAFAFE]]">
                    <ul className="list-disc px-[53px] py-[36px]">
                      {data?.whatMatters?.map(
                        (item: any, index: number) =>
                          item?.note && (
                            <li
                              key={`what-matters-${index}`}
                              className="mb-[28px]  text-[#000817] dark:text-white   text-base font-normal"
                            >
                              {item?.note}
                            </li>
                          )
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="w-full mt-[30px]">
            <div className="border border-[#E4E3ED] rounded-lg ">
              <div className=" px-[30px] py-[19px] border-b border-[#E5E4FB] bg-primary-default dark:bg-black text-[#fff] text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
                Company Descriptions
              </div>
              <div className="px-[33px] py-[25px]">
                <p className="mb-[25px]  text-[#000817] dark:text-white   text-base font-normal">{data?.description}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-[30px] mt-[29px]">
            <div className="md:w-1/2 flex flex-col">
              <div className=" overflow-x-auto">
                <div className=" min-w-full inline-block align-middle">
                  <div className="border border-[#DCDBEE] rounded-lg overflow-hidden">
                    <table className="min-w-full dark:bg-primary-dark">
                      <caption className="text-left text-[18px] font-semibold bg-primary-default dark:bg-black  text-[#FFF] py-[19px] px-[29px]">
                        Mna Basics
                      </caption>
                      <thead>
                        <tr>
                          <th scope="col" className="w-1/3"></th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-[#DCDBEE]">
                        <tr>
                          <td className="px-[30px] py-[21px]  text-[#696868] dark:text-white   text-base font-normal">
                            Terms:
                          </td>
                          <td className="px-[30px] py-[10 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.terms}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[21px]  text-[#696868] dark:text-white   text-base font-normal">
                            Reverse Break Fee:
                          </td>
                          <td className="px-[30px] py-[10 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.breakFeeTarget}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[21px]  text-[#696868] dark:text-white   text-base font-normal">
                            Mna value:
                          </td>
                          <td className="px-[30px] py-[10 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.size}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[21px]  text-[#696868] dark:text-white   text-base font-normal">
                            Premium:
                          </td>
                          <td className="px-[30px] py-[10 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.premium}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[21px]  text-[#696868] dark:text-white   text-base font-normal">
                            Closing Language:
                          </td>
                          <td className="px-[30px] py-[21px]  text-[#000817] dark:text-white   text-base font-medium text-left truncate max-w-[calc(100vw-80rem)]">
                            {data?.closingLanguage}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[21px]  text-[#696868] dark:text-white   text-base font-normal">
                            Reverse Break Fee:
                          </td>
                          <td className="px-[30px] py-[21px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.breakFeeTarget}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[21px]  text-[#696868] dark:text-white   text-base font-normal">
                            Out Dates:
                          </td>
                          <td
                            colSpan={2}
                            className="px-[30px] py-[21px]  text-[#000817] dark:text-white   text-base font-medium text-left"
                          >
                            {data.mnaOutDates?.map((item: any, index: number) => (
                              <div key={`out-dates${index}`} className="flex justify-between items-center">
                                <div className="py-[10px]">{moment(item?.dateOut).format("MM/DD/YYYY")}</div>
                                <div>{item?.comment}</div>
                              </div>
                            ))}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[21px]  text-[#696868] dark:text-white   text-base font-normal">
                            Required to Litigate:
                          </td>
                          <td className="px-[30px] py-[10 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.requiredToLitigate}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 flex flex-col">
              <div className="overflow-x-auto">
                <div className="min-w-full inline-block">
                  <div className="border border-[#DCDBEE] rounded-lg overflow-hidden">
                    <table className="min-w-full dark:bg-primary-dark">
                      <caption className="text-left text-[18px] font-semibold bg-primary-default dark:bg-black  text-[#FFF] py-[19px] px-[29px]">
                        Holders Top 5 Holders
                      </caption>
                      <tbody className="divide-y divide-[#DCDBEE]">
                        <tr className="flex  justify-between items-center w-full">
                          <td className="px-[27px] wh py-[10px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            8.30%
                          </td>
                          <td className="px-[27px] wh py-[10px] text-[#007B1B] text-base font-medium text-left">
                            ATVI
                          </td>
                          <td className="px-[27px] wh py-[10px] text-[#D70000] text-base font-medium text-left">
                            Vanguard
                          </td>
                          <td className="px-[27px] wh py-[10px]  text-[#696868] dark:text-white   text-xs font-normal leading-5">
                            <div> Changed by: James</div>
                            <div>Date: November 11, 2023</div>
                          </td>
                        </tr>
                        <tr className="flex  justify-between items-center w-full">
                          <td className="px-[27px] wh py-[10px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            8.30%
                          </td>
                          <td className="px-[27px] wh py-[10px] text-[#007B1B] text-base font-medium text-left">
                            ATVI
                          </td>
                          <td className="px-[27px] wh py-[10px] text-[#D70000] text-base font-medium text-left">
                            Vanguard
                          </td>
                          <td className="px-[27px] wh py-[10px]  text-[#696868] dark:text-white   text-xs font-normal leading-5">
                            <div> Changed by: James</div>
                            <div>Date: November 11, 2023</div>
                          </td>
                        </tr>
                        <tr className="flex  justify-between items-center w-full">
                          <td className="px-[27px] wh py-[10px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            8.30%
                          </td>
                          <td className="px-[27px] wh py-[10px] text-[#007B1B] text-base font-medium text-left">
                            ATVI
                          </td>
                          <td className="px-[27px] wh py-[10px] text-[#D70000] text-base font-medium text-left">
                            Vanguard
                          </td>
                          <td className="px-[27px] wh py-[10px]  text-[#696868] dark:text-white   text-xs font-normal leading-5">
                            <div> Changed by: James</div>
                            <div>Date: November 11, 2023</div>
                          </td>
                        </tr>
                        <tr className="flex  justify-between items-center w-full">
                          <td className="px-[27px] wh py-[10px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            8.30%
                          </td>
                          <td className="px-[27px] wh py-[10px] text-[#007B1B] text-base font-medium text-left">
                            ATVI
                          </td>
                          <td className="px-[27px] wh py-[10px] text-[#D70000] text-base font-medium text-left">
                            Vanguard
                          </td>
                          <td className="px-[27px] wh py-[10px]  text-[#696868] dark:text-white   text-xs font-normal leading-5">
                            <div> Changed by: James</div>
                            <div>Date: November 11, 2023</div>
                          </td>
                        </tr>
                        <tr className="flex  justify-between items-center w-full">
                          <td className="px-[27px] wh py-[10px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            8.30%
                          </td>
                          <td className="px-[27px] wh py-[10px] text-[#007B1B] text-base font-medium text-left">
                            ATVI
                          </td>
                          <td className="px-[27px] wh py-[10px] text-[#D70000] text-base font-medium text-left">
                            Vanguard
                          </td>
                          <td className="px-[27px] wh py-[10px]  text-[#696868] dark:text-white   text-xs font-normal leading-5">
                            <div> Changed by: James</div>
                            <div>Date: November 11, 2023</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="overflow-x-auto mt-[30px]">
                <div className=" min-w-full inline-block align-middle">
                  <div className="border border-[#DCDBEE] rounded-lg overflow-hidden">
                    <table className="w-full rounded-bl-lg rounded-br-lg">
                      <caption className="text-left text-[18px] font-semibold bg-primary-default dark:bg-black text-[#FFF] py-[19px] px-[29px]">
                        Contacts
                      </caption>
                      <tbody className="divide-y divide-[#DCDBEE]">
                        <tr>
                          <td className="px-[27px] py-[21px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            [ ]:
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[27px] py-[21px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            [ ]:
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[27px] py-[21px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            Info agent:
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[27px] py-[21px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            Transfer agent: Broadridge
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {data?.links?.length > 0 && (
                <div className="overflow-x-auto mt-[30px]">
                  <div className="min-w-full inline-block align-middle">
                    <div className="border border-[#DCDBEE] rounded-lg overflow-hidden">
                      <table className="w-full rounded-bl-lg rounded-br-lg">
                        <caption className="text-left text-[18px] font-semibold bg-primary-default dark:bg-black text-[#FFF] py-[19px] px-[29px]">
                          Links for Merger Documents
                        </caption>
                        <thead>
                          <tr>
                            <th scope="col" className="w-1/3"></th>
                            <th scope="col" className="w-2/3"></th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-[#DCDBEE]">
                          {data?.links?.map((item: any, index: number) => (
                            <tr key={`links-${index}`}>
                              <td className="px-[27px] py-[20px]  text-[#696868] dark:text-white   text-base font-medium text-left">
                                {item?.category}
                              </td>
                              <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium truncate max-w-[calc(100vw-72rem)]">
                                <a target="_blank" rel="noopener noreferrer" href={item.link}>
                                  {item?.link}
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="mt-[29px] overflow-x-auto">
            <div className=" min-w-full inline-block align-middle">
              <div className="border border-[#DCDBEE] rounded-lg overflow-hidden">
                <table className="min-w-full">
                  <caption className="text-left text-[18px] font-semibold bg-primary-default dark:bg-black text-[#FFF] py-[19px] px-[29px]">
                    Features
                  </caption>
                  {data?.features?.length > 0 && (
                    <thead className="border-b-2 border-[#DCDBEE]">
                      <tr>
                        <th scope="col" className="text-left py-[10px] px-[29px]">
                          Name
                        </th>
                        <th scope="col" className="text-left py-[10px] px-[29px]">
                          Value
                        </th>
                      </tr>
                    </thead>
                  )}
                  <tbody className="divide-y divide-[#DCDBEE]">
                    {data?.features?.length > 0 ? (
                      data.features?.map((value: any, index: number) => (
                        <tr key={`features-${index}`}>
                          <td className="px-[30px] py-[10px]  text-[#696868] dark:text-white   text-base font-normal">
                            {value.name}
                          </td>
                          <td className="px-[30px] py-[10px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {value?.value}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <div className="text-primary font-bold text-base p-4 text-center">No data found</div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto mt-[30px]">
            <div className="min-w-full inline-block align-middle">
              <div className="border border-[#DCDBEE] rounded-lg overflow-hidden">
                <div className="min-w-full rounded-bl-lg rounded-br-lg">
                  <div className="text-left font-semibold bg-primary-default dark:bg-black text-[#FFF] py-[19px] px-[29px]">
                    <div className="flex items-center justify-between">
                      <div className="text-xs xs:text-xs sm:text-sm md:text-base lg:text-lg">
                        Catalysts / Recent Events
                      </div>
                      <div className="flex flex-wrap items-center gap-8 md:gap-4 sm:gap-2">
                        <div className="flex flex-wrap items-center gap-2">
                          <div className="p-2 bg-[#FF00FF] rounded" />
                          <span className="text-xs sm:text-sm md:text-base lg:text-lg  leading-7 font-normal">
                            Catalysts
                          </span>
                        </div>
                        <div className="flex flex-wrap items-center gap-2">
                          <div className="p-2 bg-[#ACB9CA] rounded" />
                          <span className="text-xs sm:text-sm md:text-base lg:text-lg  leading-7 font-normal">
                            Research / broker comments
                          </span>
                        </div>
                        <div className="flex flex-wrap items-center gap-2">
                          <div className="p-2 bg-[#A9D08E] rounded" />
                          <span className="text-xs sm:text-sm md:text-base lg:text-lg  leading-7 font-normal">IR</span>
                        </div>
                        <div className="flex flex-wrap items-center gap-2">
                          <div className="p-2 bg-[#7B7B7B] rounded" />
                          <span className="text-xs sm:text-sm md:text-base lg:text-lg  leading-7 font-normal">
                            News articles
                          </span>
                        </div>
                        <div className="flex flex-wrap items-center gap-2">
                          <div className="p-2 bg-[#FFFF00] rounded" />
                          <span className="text-xs sm:text-sm md:text-base lg:text-lg  leading-7 font-normal">
                            Press releases / filings / presentations
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-[30px]">
                    <Table
                      fetchData={fetchNoteList}
                      columns={columnsInstance.OldMnaDetails()}
                      additionalDataKey="notes"
                      openModal={openModal}
                    />
                    {}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto mt-[30px]">
            <div className="min-w-full inline-block align-middle">
              <div className="border border-[#DCDBEE] rounded-lg overflow-hidden">
                <table className="min-w-full dark:bg-primary-dark rounded-bl-lg rounded-br-lg">
                  <thead className="bg-primary-default dark:bg-black">
                    <tr>
                      <th
                        scope="col"
                        className="px-[30px] py-[20px] text-[#fff] text-base font-semibold text-left
                    "
                      >
                        Condition
                      </th>
                      <th scope="col" className="px-[30px] py-[20 px] text-[#fff] text-base font-semibold text-left">
                        Filed
                      </th>
                      <th scope="col" className="px-[30px] py-[20px] text-[#fff] text-base font-semibold text-left">
                        Expected / Confirmed
                      </th>
                      <th scope="col" className="px-[30px] py-[20px] text-[#fff] text-base font-semibold text-left">
                        Status
                      </th>
                      <th
                        scope="col"
                        className="w-[300px] px-[30px] py-[20px] text-[#fff] text-base font-semibold text-left"
                      >
                        Commentary
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-[#DCDBEE]">
                    {data?.conditions?.length > 0 ? (
                      data?.conditions?.map((item: any, index: number) => (
                        <tr key={`conditions-${index}`}>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {`${item?.category?.name}  ${
                              item?.category?.name === "Other" ? `- ${item?.categoryOtherComment}` : ""
                            }`}
                          </td>
                          <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {item?.filed !== null && moment(item?.expectedConfirmed).format("MM/DD/YYYY")}
                          </td>
                          <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {item?.expectedConfirmed !== null && moment(item?.expectedConfirmed).format("MM/DD/YYYY")}
                          </td>
                          <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {item?.statusId}
                          </td>
                          <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {item?.commentary}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="text-center">
                        <td
                          colSpan={columnsInstance.OldMnaDetails?.length}
                          className="text-primary font-bold text-base p-4"
                        >
                          None
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader loading={loading} />}
      {isModalOpen && content && (
        <div
          className={`fixed top-0 left-0 z-30 flex h-full min-h-screen w-full items-center justify-center px-4 py-5  ${
            isModalOpen ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div className="absolute inset-0 transition-opacity bg-[#222222]" onClick={closeModal}></div>
          <div className="pointer-events-auto relative flex h-[680px] w-[1440px] flex-col overflow-hidden rounded-md border-none bg-[#EAE5F7] bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
            <div className="flex justify-end p-5">
              <img
                src={Close}
                alt="clsoe"
                onClick={() => closeModal()}
                className="rounded-full p-2 border border-[#E6E6F9] bg-b-seconday-light cursor-pointer"
              />
            </div>
            <h2 className="px-[50px] pt-[18px] pb-5 text-[#fff] bg-primary-default dark:bg-black font-semibold text-lg">
              Description
            </h2>
            {/*Modal body*/}
            <div className="relative overflow-y-auto py-4 px-[50px]">
              <ul className="list-disc">
                <li
                  className=" text-[#393C51]  dark:text-white  text-sm font-normal leading-5 capitalize mb-3"
                  dangerouslySetInnerHTML={{
                    __html: content || "",
                  }}
                />
              </ul>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default OldMnaDetails;
