import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import { PrivateAuthenticated, PublicAuthenticated } from "./Routes/Protected";
import { protectedRoutes, publicRoutes } from "./Routes/Route";
import { Toaster } from "react-hot-toast";
import { createContext, useContext, useState } from "react";

const ThemeContext = createContext({ theme: "light", toggleTheme: () => {} });

function App() {
  const themeMode = localStorage.getItem("theme") as "light" | "dark" | null;
  const [theme, setTheme] = useState<"light" | "dark">(themeMode || "light"); // Estado del tema

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme); // Guardar tema seleccionado en el almacenamiento local
  };
  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <Router>
        <Toaster position="bottom-center" reverseOrder={false} />
        <Routes>
          {protectedRoutes.map(({ path, element: Component }) => (
            <Route
              key={path}
              path={path}
              element={
                <PrivateAuthenticated>
                  <Component />
                </PrivateAuthenticated>
              }
            />
          ))}
          {publicRoutes.map(({ path, element: Component }) => (
            <Route
              key={path}
              path={path}
              element={
                <PublicAuthenticated>
                  <Component />
                </PublicAuthenticated>
              }
            />
          ))}
        </Routes>
      </Router>
    </ThemeContext.Provider>
  );
}
export const useTheme = () => useContext(ThemeContext);

export default App;
