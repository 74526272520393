// Components
import InputField from "../../Common-inputs";
// Data
import { companyInfo } from "../data/companyInfo";
// Types
import { StepBase } from "..";
import { useCallback, useEffect, useState } from "react";
import { CompanyRestControllerApi } from "../../../openapi";
import { apiConfig } from "../../ConfigurationApi/Configuration";
import { getSortArray } from "../../../helpers/getSortArray";
import { Sort } from "../../../interfaces/pages/variedInterfaces";
import AsyncSelect from "../../Async-Select";

interface StepTwoProps extends StepBase {
  formik: any;
  Keyboard: string;
  fetchFeatures: (inputValue?: string) => Promise<
    | {
      value: any;
      label: any;
    }[]
    | []
  >;
}

export default function StepTwo(props: StepTwoProps) {
  const [search, setSearch] = useState("");
  const [featuresLoading, setFeaturesLoading] = useState<boolean>(false);
  const [companyList, setCompanyList] = useState<any>(0);
  const { Keyboard, formik, serverError } = props;

  const fetchCompanyList = useCallback(async (pageIndex: number, pageSize: number, sortBy: Sort[]) => {
    try {
      const api = new CompanyRestControllerApi(apiConfig());

      const response = await api.getCompanies(
        search ? `name:${search}` : "",
        pageIndex,
        pageSize,
        getSortArray(sortBy),
        {},
      );
      setCompanyList(response.data.totalElements)
      return { ...response.data };
    } catch (error) {
      console.error(error);
    } finally {
    }
  },
    [search]
  );

  const getFetchCompanyList = async (inputValue: string) => {
    try {
      const response = await fetchCompanyList(0, companyList, []);
      const filteredCompanies = response?.content?.filter((item: any) =>
        item.name.toLowerCase().includes(inputValue.toLowerCase())
      );
      const companyName = filteredCompanies?.map((item: any, index: number) => ({
        value: index + 1,
        label: item.name ?? '',
      }));
      return companyName ?? [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  return (
    <>
      <div className="border border-[#E4E3ED] rounded-lg dark:bg-table-dark bg-table-light">
        <div className="px-[23px] py-[30px]">
          <div className="border border-[#E4E3ED] rounded-lg">
            <table className="w-full">
              <thead>
                <tr className="border-b border-[#E5E4FB] bg-[#4B49AC] dark:bg-black text-[#fff] text-lg font-semibold text-left">
                  <th className="rounded-tl-md w-[385px] px-[30px] py-[19px]">Topic</th>
                  <th className="w-[585px] px-[30px] py-[19px]">Target</th>
                  <th className="rounded-tr-md w-[585px] px-[30px] py-[19px]">Acquirer</th>
                </tr>
              </thead>
              <tbody>
                {companyInfo.map((item: any, index: number) => (
                  <tr className={`${index === companyInfo.length - 1 ? "" : "border-b border-[#E5E4FB]"}`} key={index}>
                    <td className="px-[30px] py-[19px]  text-[#393C51]  dark:text-white  text-base font-medium">
                      <div className="flex justify-between items-center">
                        <label htmlFor={item.label} className="text-base  text-[#393C51]  dark:text-white  font-medium mb-2 flex gap-1">
                          {item.label}
                        </label>

                        <div className="tooltip cursor-pointer">
                          <div
                            className={`bg-[#fff]  bottom-9 tooltiptext  text-[#393C51]  dark:text-white dark:bg-primary-dark  transition-all  text-sm  z-50  w-[328px] px-[14px] pt-[12px] pb-[17px] rounded-[10px] border  border-[#4185EE] after:border-transparent after:border-t-[#4185EE]  before:top-[99%]`}
                          >
                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                            ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat."
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                            className="cursor-pointer dark:brightness-[4]"
                          >
                            <path
                              d="M9 0.5C4.02571 0.5 0 4.52525 0 9.5C0 14.4742 4.02525 18.5 9 18.5C13.9743 18.5 18 14.4747 18 9.5C18 4.52571 13.9747 0.5 9 0.5ZM9 17.2442C4.72985 17.2442 1.25582 13.7702 1.25582 9.5C1.25582 5.22982 4.72985 1.75582 9 1.75582C13.2702 1.75582 16.7442 5.22982 16.7442 9.5C16.7442 13.7702 13.2702 17.2442 9 17.2442Z"
                              fill="#1E87F0"
                            />
                            <path
                              d="M8.73354 11.8881C8.23597 11.8881 7.83319 12.3027 7.83319 12.8002C7.83319 13.286 8.22413 13.7125 8.73354 13.7125C9.24295 13.7125 9.6457 13.286 9.6457 12.8002C9.6457 12.3027 9.23107 11.8881 8.73354 11.8881ZM8.88752 4.98145C7.28819 4.98145 6.55371 5.92922 6.55371 6.56893C6.55371 7.03095 6.94465 7.24421 7.2645 7.24421C7.90424 7.24421 7.64363 6.33197 8.85198 6.33197C9.44429 6.33197 9.9182 6.59262 9.9182 7.13758C9.9182 7.77728 9.25477 8.14452 8.86383 8.47622C8.52025 8.77234 8.07011 9.2581 8.07011 10.2769C8.07011 10.8929 8.23597 11.0706 8.72166 11.0706C9.30212 11.0706 9.4206 10.81 9.4206 10.5849C9.4206 9.96889 9.43245 9.61349 10.084 9.10408C10.4039 8.85531 11.4108 8.04971 11.4108 6.93613C11.4108 5.82256 10.4039 4.98145 8.88752 4.98145Z"
                              fill="#1E87F0"
                            />
                          </svg>{" "}
                        </div>
                      </div>
                    </td>
                    <td className="px-[30px] py-[19px]  text-[#393C51]  dark:text-white text-base font-medium">
                      <div className="relative">
                        {/*
                          {item.label === 'Name' ? (
                            <AsyncSelect
                              id={item.input1Name}
                              name={item.input1Name}
                              placeholder="Name company"
                              fetchOptions={getFetchCompanyList}
                              value={formik.values[item.input1Name]}
                              formik={formik}
                              loading={featuresLoading}
                              error={serverError || ""}
                              noOptionsMessage="No company found"
                              onkeydown={(e) => {
                                if (e.keyCode === 13) {
                                  e.preventDefault();
                                  getFetchCompanyList(e?.target?.value);
                                }
                              }}
                            />
                          ) : (
                            )}
                          */}
                        <>
                          <input
                            id={item.input1Name}
                            type='text'
                            name={item.input1Name}
                            value={formik.values[item.input1Name]}
                            placeholder='Enter here...'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={`py-[18px] px-[30px] border border-[#C3C1DF] rounded-full text-base text-[#7B7B7B] font-light w-full bg-[#FAFAFE] outline-none`}
                          />
                          <span className="absolute right-7 top-5">
                            <img src={Keyboard} alt="keyboard" />
                          </span>
                        </>
                      </div>
                    </td>
                    <td className="px-[30px] py-[19px]  text-[#393C51]  dark:text-white  text-base font-medium">
                      <div className="relative">
                        {/*
                        {item.label === 'Name' ? (
                          <AsyncSelect
                            id={item.input2Name}
                            name={item.input2Name}
                            placeholder="Name company"
                            fetchOptions={getFetchCompanyList}
                            value={formik.values[item.input2Name]}
                            formik={formik}
                            loading={featuresLoading}
                            error={serverError || ""}
                            noOptionsMessage="No company found"
                            onkeydown={(e) => {
                              if (e.keyCode === 13) {
                                e.preventDefault();
                                getFetchCompanyList(e?.target?.value);
                              }
                            }}
                          />
                        ) : (
                        )}
                        */}
                        <>
                          <input
                            id={item.input2Name}
                            type='text'
                            name={item.input2Name}
                            value={formik.values[item.input2Name]}
                            placeholder='Enter here...'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={`py-[18px] px-[30px] border border-[#C3C1DF] rounded-full text-base text-[#7B7B7B] font-light w-full bg-[#FAFAFE] outline-none `}
                          />
                          <span className="absolute right-7 top-5">
                            <img src={Keyboard} alt="keyboard" />
                          </span>

                        </>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* <div className="border border-[#E4E3ED] rounded-lg  mt-[30px]">
        <div className="px-[23px] py-[30px] border-b border-b-[#E5E4FB]">
          <h2 className="text-[#000] text-lg font-bold">Company Descriptions</h2>
        </div>
        <div className="px-[23px] py-[30px]">
          <div className="flex flex-col gap-[30px] xl:flex-row">
            
            <div className="w-full">
              <InputField
                label="Description"
                id="reviewed"
                name="reviewed"
                type="textarea"
                rows={4}
                placeholder="Enter here..."
                formik={formik}
                serverError={serverError || ""}
                showIcon
                last={true}
              />
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
