import AddEditMna from "../Pages/Add-Edit-mna";
import AddEditNote from "../Pages/Add-Edit-note";
import Dashboard from "../Pages/Dashboard";
import MnaDetails from "../Pages/Mna-Details";
import MnaList from "../Pages/Mna-list";
import NoteList from "../Pages/Note-list";
import Login from "../Pages/Login";
import FileList from "../Pages/File-list";
import AlertList from "../Pages/Alerts-list";
import CompanyList from "../Pages/Company-list";
import AddEditCompany from "../Pages/Add-Edit-company";
import FundamentalList from "../Pages/Fundamental-list";
import AddEditFundamental from "../Pages/Add-Edit-fundamental";
import FundamentalDetails from "../Pages/Fundamental-Details";
import OldAddEditMna from "../Pages/Old-Add-Edit-mna";
import OldMnaList from "../Pages/Old-Mna-list";
import OldMnaDetails from "../Pages/Old-Mna-Details";

interface Route {
  path: string;
  element: React.ComponentType<any>;
}

export const protectedRoutes: Route[] = [
  { path: "/dashboard", element: Dashboard },
  { path: "/real-time-entries", element: Dashboard },
  { path: "/notes", element: NoteList },
  { path: "/add-note", element: AddEditNote },
  { path: "/update-note/:id", element: AddEditNote },
  { path: "/add-mna", element: AddEditMna },
  { path: "/old-add-mna", element: OldAddEditMna },
  { path: "/update-mna/:id", element: AddEditMna },
  { path: "/old-update-mna/:id", element: OldAddEditMna },
  { path: "/add-mna/:id", element: AddEditMna },
  { path: "/mnas", element: MnaList },
  { path: "/old-mnas", element: OldMnaList },
  { path: "/mna/:id", element: MnaDetails },
  { path: "/old-mna/:id", element: OldMnaDetails },
  { path: "/regulatory-library", element: Dashboard },
  { path: "/files", element: FileList },
  { path: "/company", element: CompanyList },
  { path: "/add-company", element: AddEditCompany },
  { path: "/update-company/:id", element: AddEditCompany },
  { path: "/alerts", element: AlertList },
  { path: "/fundamental", element: FundamentalList },
  { path: "/add-fundamental", element: AddEditFundamental },
  { path: "/update-fundamental/:id", element: AddEditFundamental },
  { path: "/fundamental/:id", element: FundamentalDetails },
];

export const publicRoutes: Route[] = [{ path: "/", element: Login }];
